import {
	Box,
	
	List, ListItem, ListItemAvatar, ListItemText,
	Typography
} from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
	size:{fontSize:"12px !important"}
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
				const classes = this.props.classes;	

		return (
		

					   <List> 
					   {
						   this.props.points.map((e, index) => (
							<ListItem alignItems="flex-start" >
							<ListItemAvatar >
							<FiberManualRecordIcon fontSize="small" className={classes.size}/>
							</ListItemAvatar>
							  <ListItemText primary={
								
								<Typography  variant="subtitle1" color="textSecondary"> 
								 <Box lineHeight={1.5}>
								  
								  {e['text']}
								  
								  </Box>
								  </Typography>
								  
							} />
							</ListItem>
							))
					   }
					  </List>
			
					
	
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
