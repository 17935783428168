import { Box, Card,CardContent, Grid, Paper,Typography, ThemeProvider } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Academytheme } from "../home/index/useStyles";
import PropTypes from 'prop-types';


const styles = () => ({
  border: {
	border:"8px solid #25387C",
	padding:"20px",
	margin:"56px 0px 56px",
	borderRadius:'25px'
  }, 
  text: {
	color:"#efe5e5",
	fontFamily: ['Public Sans','sans-serif',].join(',')
  }
});

function Footer(props) {
  const { classes } = props;

	
  return (
  	<ThemeProvider theme={Academytheme}>  
		
	<footer 	
	style={{background:'linear-gradient(0deg, #25387cf0, #25387cbf) ,url("https://www.careerinternational.in/assets/cover/innner-banner-22.jpg") '
	}}>
      <Paper className={classes.root} >
		<Grid container spacing={0}  style={{backgroundColor:'transparent'}}>				
				
				<Grid item xs={4}  sm={12}  >
				<Box padding={6} lineHeight={1.5} fontWeight="100" fontSize="42px" className={classes.text} >
						Vision
				</Box>	
				</Grid>
	  </Grid>
      </Paper>
    </footer>
	
	<Grid spacing={0} container direction="row"
                    alignItems="center"
                    justify="center">				
				
				
				<Grid item xs={10} lg={4} >
				<Card className={classes.border}>
				<CardContent>
				  
				   <Typography>
				  <Box  lineHeight={1.25} color="text.primary" fontSize="28px" style={{'text-align':'center'}}>We aim to become a promising entrepreneurship one-stop platform and nurture the entrepreneurial mindset in people across the globe. We’re committed to transforming the world through innovation and collaboration.
				  </Box>
				  </Typography>
			  
				</CardContent>
				</Card>
				</Grid>
				
				
				<Grid item xs={12} lg={4} >
				<Box display="flex"
					  justifyContent="center"
					  alignItems="center" 
					   height="240px" width="100%">
					  <img src="/assets/images/homepage/images/events/vision_card.jpg" height="100%"  width="100%" alt="" style={{backgroundSize:'cover'}} />
					</Box>	
				</Grid>
				
	  </Grid>
    </ThemeProvider>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);