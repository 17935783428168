import {
	Box,
	Grid, 
	
	ThemeProvider, Typography
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";
import CommonCard from './CommonCard';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	
		let points=[
		{'text':"While using our website, we may track, collect and aggregate information indicating other things including the pages you visited while browsing our site, the order in which they were visited, time - when they were visited, which hyper links were clicked while browsing our site."}, 
{'text':"We also collect information from which other website you were directed to our website. In this process we may also collect information which may include logging into your IP address, operating systems and browser software used for our site"}, {'text':"IP address is needed for us to locate your geographical information and your internet service provider. Also, location data may be tracked for your improved experience"}, {'text':"We collect personal information when you log in to our website or register with us for creating an account, update or change information of your already made account, make subscriptions, sign up for email updates , newsletters, participate in our events, public forums, communicate through us through emails and/or participate in any content offerings for our website"}, {'text':"Your personal information may also be collected when you use Content Offering services which include participation in video quizzes, standalone quizzes, examinations and online surveys"}, {'text':"You are not allowed to use your any other personal information (excluding from what is required/asked for) on the website. Also, personal or sensitive data of other individuals, group/groups of individuals, firms, organizations also should not be shared or published on the website or mentioned in your answer sheets or assignments or surveys"}, {'text':"While availing to a few services, you are also required to share your personal information such as name, address, contact details, email address, photograph, access to webcam , and photo identification among many others,  with a third party site, related to us"}, {'text':"Many services offered by OpenGrowth are paid services. This payment may be processed through a third party payment gateway through online payment methods, debit cards, credit cards ."}  ]
		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  >

					
	
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 What information we collect and how
					 </Box>
					 </Typography> 
					
					<Typography  variant="subtitle1" color="textSecondary"> 
					<Box  marginTop={1.5} lineHeight={1.5}>
								  
						While availing to the services provided by OpenGrowth Academy, you share your personal information. You can write to us on academy_connect@opengrowth.com, if you need to change your personal information shared with us while using our website.
								  
					</Box>
					</Typography>

					<CommonCard points={points} />
			
					<Typography  variant="subtitle1" color="textSecondary"> 
					<Box  marginTop={1.5} lineHeight={1.5}>
								  
						<b style={{color:"black"}}>Note</b>: A third party service provider may collect and process your financial information along with  OpenGrowth Academy. 
								  
					</Box>
					</Typography>


		</Box>			
		</Grid>
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
