import React, { Suspense,useState,useEffect } from "react";
import { SiDiscord } from 'react-icons/si';
import { useHistory, useLocation } from 'react-router-dom';
import {Box,makeStyles} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
   media: {
      height: 0,
      paddingTop: '56.25%' // 16:9
   },
   card: {
      position: 'relative',
   },
 
}));





function DCard() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
	
  }, [])
 	

    return (
	<Box>
	
		<iframe 
		src="https://discord.com/invite/ApvXyF7fec" 
		name="" 
		height="400px" 
		width="100%" 
		title="Discord">
		</iframe>
		

	</Box>
  );
}

export default DCard;