import { Box, Card,CardContent,Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';



const useStyles = makeStyles((theme) => ({
  back:{backgroundColor:"red"}
}));

class Scroll extends Component {
	constructor(props) {
	super(props);}
		
    render()
	{
		const classes = this.props.classes;

	const ProgressCard = () => {
		
		return (
		<Box marginTop={3}>
		{/*<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={0} 
					style={{backgroundColor:'white'}}
					>
		
		<Grid item  sm={12} lg={8} >
			<Typography >
					  <Box fontWeight="bold"
					  lineHeight={1.25}  marginTop={4} fontSize="22px" 
					  display="flex" justifyContent="center" alignItems="center"
					  color="text.primary" textAlign="center" padding={2}>					
							In every country, women are creating and expanding their businesses
							through overcoming societal and financial barriers. To support this
							marshaling of women's energy and creativity, OpenGrowth Academy aims
							to honor a few victorious women entrepreneurs on our community page.
					   </Box>
			</Typography>
		</Grid>
		</Grid>*/}

		<Grid spacing={0} 
		alignItems="center"
        justify="center"
		container direction="row" style={{background:'url("https://opengrowthacademy.com/assets/images/logo/fb.jpg")',
		'background-repeat':'no-repeat','background-size':'cover'}}>
		
		
		
		<Grid item  xs={12} lg={7} >		
		<Box padding={isMobile==true?3:6}>
					<Card elevation={5}>
					<CardContent >
					<Box padding={"32px 24px"} 
					 textAlign="center" >
					  
					  <Typography >
					  <Box 
					  fontWeight="600" 
					  lineHeight={1.5}
					  fontSize="32px" 
					  color="text.primary">					
					  To all the Wonder Women out there!<br/>
					   </Box>
					   </Typography> 
                    
					
					<Typography >
					  <Box 
					  marginTop={2}
					  fontWeight="bold" lineHeight={1.25} fontSize="22px" 
					  color="text.primary">					
							An opportunity to celebrate the progress, acts
							of courage, and determination of strong
							women in their communities. There’s no limit
							we as women can achieve, you constantly
							inspire us. That’s why we took the privilege to
							celebrate you on International Women's Day.<br/><br/>
							At OpenGrowth Academy, we treasure and spotlight the
							victorious women to spread awareness of
							innovation & entrepreneurship among youths.
					   </Box>
					   </Typography>
					
					
					 </Box>
					 </CardContent>
					 </Card>
		</Box>
		</Grid>
		</Grid>
		</Box>
	)}
	
		return ( 
		<ProgressCard/>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
