
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, Grid,TextField, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";
import { api } from "../../functions/Helper";



const useStyles = theme => ({

  media: {
	'border-radius':'12px'
  },
 
 
});


class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
            loading:false,
            imgDetails:false,upload:false,img:null,
            img_msg:false,img_msg_type:false,name:null,
            webinarImg:this.props.img,isFileUpdated:false,
		
	}
    }
	
	imageHandler = (e) => {

		this.setState({imgDetails: e.target.files[0]});
		let reader = new FileReader();
			reader.onloadend = () => {
			this.setState({webinarImg: reader.result});	
		}
        this.setState({isFileUpdated:true})
		reader.readAsDataURL(e.target.files[0]);	
		this.setState({upload:false})
	}
	handleSubmit = (e) =>
	{
		e.preventDefault();

 
		if(this.state.img==null)
        {
            this.setState({
            img_msg:'Please specify folder name',
            img_msg_type:'warning',
            });
                       
        } 
 		else if(this.state.name==null)
        {
            this.setState({
            img_msg:'Please specify image name',
            img_msg_type:'warning',
            });
                       
        }                
		else if(this.state.imgDetails==false)
        {
            this.setState({
            img_msg:'Please upload an image.',
            img_msg_type:'warning',
            });
                       
        } 
        else
        {
                   

                     this.setState({loading: true});
                        
                                    const header = {}; //headers
                                     let data = new FormData();
                                     header.Accept = 'application/json';
                                    data.append( 'file',this.state.imgDetails);
                                    data.append( 'route',this.state.img);
                                    data.append( 'name',this.state.name);
                                    
                                    this.setState({upload:'Uploading starts ....'})

                                    axios.post(api+'/upload_og_image',data)
                                    .then((response) => {
                                        
                                    //console.log("called")
                                    //console.log(response.data)
                                        this.setState({isFileUpdated:true})
                                        
                                        
                                        if(response.data['type']=='success')
                                        {
                                            this.setState({upload:'Image uploaded ....'})

                                        }
                                        else
                                        {
                                           this.setState({upload:'Uploading failed ....'})
                                        }
                  
                                       this.setState({loading: false});
      
                                        this.setState({img_msg:response.data['msg']})
                                        
                        }, (error) => 
                        {
                            //console.log(error.data);
                        });
                        
          }
        
          setTimeout(() => {
                 this.setState({img_msg:false})
                 this.state.img_msg=false;

           }, 2000);
	}
	
	
	
  	render()
  	{
		

		const classes = this.props.classes;	
		//const { errors } = this.state;
	    const styles={  large :{height:'150px',width:'150px',border:'3px solid #e5e5e5'}}

		

		return(
		 <Card>
		<CardHeader title={" Update Popup Image"} />
					
        <Grid container spacing={0}
         container direction="column"
                    alignItems="center"
                    justify="center"  >
        { 	
					this.state.img_msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.img_msg}
					msg_type="success" />
					: ''	
		}	 
		
		
		<Grid  xs={12} >
        <Box padding={1}>
       
				
			<Grid container direction="column"   justifyContent="flex-start"  alignItems="center">      

			<Grid item xs={12}>
				
                <Box marginTop={2} marginBottom={2} minWidth="100vh">
					{/*<FormControl variant="outlined" fullWidth>
					<InputLabel>Upload destination</InputLabel>
					<Select name="img"
					onChange={event=>this.setState({img:event.target.value})}
					label="Image Path"
					value={this.state.img}
                    >
					{folder_list.map((e, index) => (
					<MenuItem value={e}>{e}</MenuItem>
					 ))}
					</Select>
					</FormControl>*/}
				<Box marginTop={2} marginBottom={2} minWidth="100vh">
                <TextField label="Image Path" color="primary" variant="outlined"  name="img" type="text" 
			    onChange={event=>this.setState({img:event.target.value})}
                value={this.state.img} fullWidth/>
				 </Box>
				 </Box>
                 
			</Grid>
            
			<Grid item xs={12}>
				
                <Box marginTop={2} marginBottom={2} minWidth="100vh">
                <TextField label="Image name" color="primary" variant="outlined"  name="name" type="text" 
			    onChange={event=>this.setState({name:event.target.value})}
                value={this.state.name} fullWidth/>
				 </Box>
                 
			</Grid>
            
			<Grid item xs={12}>
			<Box marginTop={3} marginBottom={3}>
			<label htmlFor="photo-upload">
			  <input accept="image/*" id="photo-upload" multiple type="file" 
			  onChange={this.imageHandler}  hidden/>
				<Avatar style={styles.large} 
				src={this.state.webinarImg}> 
			  </Avatar>
			</label>
			</Box>
			</Grid>
			
			<Grid item xs={12} align = "center" justify = "center" alignItems = "center" >
			<Box marginTop={3}  marginBottom={3}>
			<Typography variant="subtitle2" color="textSecondary">
			Allowed *.peg, *.jpg, .*png, *.gif
			Max size of 3.1MB
            </Typography>
			<Typography variant="subtitle2" color="textSecondary">
			popup image : og_popup.png
            </Typography>

				<Typography  variant="subtitle2"  color="secondary">{this.state.upload}</Typography> 
				
                <Typography  variant="subtitle2"  color="secondary">
				{this.state.imgDetails['name']}
                </Typography> 
			
			</Box>
			</Grid>
			
			</Grid>			
		</Box>
        </Grid>
        
        
        
	    <Grid item xs={12}>
								<Box display="flex" flexDirection="row-reverse"   marginBottom={4} >
								
								<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} 
								
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>Upload Image</span>}
								</Button>
								
								</Box>
	    </Grid>
								
		  
		</Grid>
        </Card>

            
            
	    );
	
  	}
}
export default withStyles(useStyles)(CourseBlock)
