import React, { Component } from 'react';
import axios from 'axios';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';

import {Avatar, Box,Card,CardContent,CardHeader,Popover,Typography,Grid} from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
//import Popover from '@idui/react-popover'


import {api,userProfile_path,successColor,secondaryColor,get_name_slug} from "../../Helper";
import {useStyles} from "../../useStyles";

let user_id=null;
let user_status=null;
if(["admin","webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	user_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	user_status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}
const animation = {
  initial: {
      opacity: 0,
  },
   
 animate: {
  opacity: 1,
 },
   
   exit: {
     opacity: 0,
     transition: { duration: 0.1 },
   },
 }


let de1=null;
let pre=null;


class MentorshipTimeline extends Component {

  constructor(props) {
    super(props);
	this.state={calendarEvents:[],de1:null,
	
		}
    }
	
	
	get_full_profile = (mentor) => {	
	
	let x=user_status=='professor'
	let name=get_name_slug(x?mentor.mentee_name:mentor.mentor_name);
	this.props.history.push({
	  pathname: '/profile/'+name,
	  state: {
			profile_mail:x?mentor.mentee_email:mentor.mentor_email,
			profile_type:x?'student':'professor',
			profile_id:x?mentor.mentee_id:mentor.mentor_uid,
			course_author_id:x?null:mentor.mentor_uid
	  },
	})
	}
	componentDidMount()
	{
		
		console.clear();	
		/*let payload={ 'year':2022, 'month':8 , 'user_id':user_id, 'user_status':user_status };
	    //console.log(payload)
		axios.post(api+'/get_calender_mentorship_data',payload)
		.then((response) => {
		
			//console.log(response.data)
			this.setState({calendarEvents:response.data},()=>{console.log(this.state.calendarEvents);})
		});	*/
			
	}

	getCalendarData=(fetchInfo)=>{
	  try {

	  let year = new Date().getFullYear();
	  let month = new Date().getMonth() + 2;

	  if (fetchInfo) {
		year = new Date(fetchInfo.start).getFullYear();
		month = new Date(fetchInfo.start).getMonth() + 2;
	  }

	  let payload={ 'year':year, 'month':month , 'user_id':user_id, 'user_status':user_status };
	    //console.log(payload)
		return axios.post(api+'/get_calender_mentorship_data',payload)
			.then(response => {
			console.log(response.data)
			return response.data
	  })
	  }		
	   catch (error) {
    //console.log(error);
  }
	}
	
	
	/*		events={
		  (fetchInfo, successCallback, failureCallback) => this.getCalendarData(fetchInfo, successCallback, failureCallback)
		}*/
  	render()
  	{
			var ta=this.state.tags_list;
		const classes = this.props.classes;	
		const dateData = [ new Date('2021/06/08'),new Date('2021/06/7')]
		const details= {overflow: "hidden",display: "-webkit-box", WebkitLineClamp: 3,WebkitBoxOrient: "vertical",height:'70px', }
		const shadow={
		border:'0px solid black',
		backgroundColor:"#3453b01a",
		fontWeight:"bold",
		color:"#253B7C"
		}

		const Popup = ({res}) => {
		return (
				
				<Card style={{width:isMobile?'auto':'340px'}} elevation={0}>
				<CardContent style={{'padding':'0px'}}>
				
				
				<Box display="flex" marginBottom={2}>
				<Box  flexGrow={1} >
				
				{/*	<Button className={res.event_status=="Upcoming"?classes.blue_menu:classes.green_menu} >
					{res.event_status}
					</Button>
				*/}
				
				<Box fontSize="12px" color={res.req_status==0?secondaryColor:successColor} fontWeight="600">{res.req_status==0?"Pending Request": res.event_status=="Attended" ?'Attended':"Upcoming"}</Box>				
				<Typography variant="subtitle1" color="primary" >
						<Box fontWeight="600" lineHeight={1.25}> {res.idea} </Box>
					</Typography>
				</Box>
				<IconButton style={{ height:"30px",width:"30px"}} onClick={()=>{this.setState({close:true})}}>
				<CloseIcon  color="grey"  style={{ fontSize:"0.85rem",float:'right',cursor: 'pointer'}} />
				</IconButton>
				</Box>
		
				<Box   onClick={()=>this.get_full_profile(res)} >
				<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item xs={12} sm={4}>
				<Avatar 
					src={user_status=='professor'?userProfile_path+res.mentee_img:userProfile_path+res.mentor_img}
					className={classes.medium}   />
				</Grid>
				<Grid item xs={12} sm={8}>
				<Typography variant="subtitle1" color="textPrimary" style={{display:"flex"}}>
						{"With"+" "}&nbsp; <Box  display="flex" fontWeight="600"> {user_status=='professor'?res.mentee_name:res.mentor_name} </Box>
					</Typography>
					
					<Typography variant="subtitle2"  color="textSecondary">{ res.date_title} </Typography>
					<Typography variant="subtitle2"  color="textSecondary">{ res.time_title} </Typography>
					
					<Box display={res.query==null?"none":"block"}>
					<Typography variant="subtitle2" color="textSecondary">
					Query : <i>&ldquo;{res.query}&rdquo;</i>
					</Typography>
					</Box>
				</Grid>
				</Grid>
				</Box>
				
				</CardContent>
				</Card>
		)}
		
		
				const  renderEventContent= (eventInfo) => {
			let res=eventInfo.event._def.extendedProps;
			let st={'border-color': 'rgb(5 19 73)','background-color': 'rgb(213 236 255)'}
			return (
			 
						
				<PopupState variant="dialog" popupId="demo-popup-popover"  >
			  {(popupState) => (
				<>
			  
			   <div className='text-transparent bg-transparent overflow-hidden p-0' 
				 {...bindTrigger(popupState)}
				style={{ cursor: 'pointer'}} >
				<Box textAlign="center" margin="10px" color="primary">{res.idea}</Box>
				</div>

				
				<Popover {...bindPopover(popupState)} 
				classes={{paper:"pop-paper"}}
				anchorOrigin={{vertical: 'bottom',horizontal: 'center',}}
			    transformOrigin={{vertical: 'top',horizontal: 'center',}} 
			    >
				
				<Popup res={res} />
				
			  </Popover>
			  </>
				  )}
			</PopupState>
			
			
			  )
			}
			
		const CalenderC = () => {
		return (

		<FullCalendar
		    forceEventDuration={true}

		displayEventTime={true}
          header={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek"
          }}
		 		height={"100vh"}

		cellColor='red'
		eventColor='#054924'
		eventTextColor='#000'
		display="inverse-background"
		eventBackgroundColor='#C7EBD7'
		plugins={[
            dayGridPlugin,
            interactionPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin
          ]}
		initialView="dayGridMonth"
		handleWindowResize={true}
		weekends={true}
			events={
		  (fetchInfo) => this.getCalendarData(fetchInfo)
		}
        eventContent={renderEventContent}
		/>
     
		)
		}
		
	
			return (
			<>
			<Card style={{'min-height':'400px'}}>
			 <CardHeader
				title={"Monthly Appointment Snapshot" }
			  />
			<CardContent>
				<CalenderC/>
			</CardContent>
			</Card>
			</>
		);
  	}
}

  
export default () => {
    const classes = useStyles();
	 const history = useHistory();
    return (
        <MentorshipTimeline history={history}  classes={classes} />
    )
}
  
  