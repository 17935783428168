import {
	Box,
	Grid, 
	Link,
	ThemeProvider, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";
import Introduction from './Introduction';
import Information from './Information';
import Refund from './Refund';
import Revision from './Revision';
import Advertisements  from './Advertisements';
import Confidentiality  from './Confidentiality';
import Use_Of_Info  from './Use_Of_Info';
import What_Info_We_Collect  from './What_Info_We_Collect';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	

		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  spacing={2}
		  style={{backgroundColor:"white"}}>

					
		<Grid item  xs={12} >
			<Typography  >
					  <Box 
					  marginTop={4}
					  lineHeight={1.25} 
					  fontWeight="600"
					  fontSize="32px" 
					  textAlign="center" padding={2}>	
							Privacy Policy
							<Box className={classes.outline}></Box>
					  </Box>
			</Typography>			
		</Grid>
				
							
		<Grid item  xs={10} >
			<Typography variant="subtitle1" color="textSecondary"  >
			<Box  lineHeight={1.5}>
			Thank you for choosing OpenGrowth Academy. We value your trust. One of our main priorities is the privacy of our visitors, clients, teachers and students. This document contains information on what data is collected and recorded by OpenGrowth Academy and how we use it. 

				<br/>
				<br/>
				
				We adhere to ethical standards in gathering, using and safeguarding any information that you submit to us while opting for a course or subscribing to our services or during employment or incorporation or collaboration of any kind. 
				

				<br/>
				<br/>
				
				The policy is not applicable for the data collected offline or through any physical source from our users or any other channel or third party other than the website.  If you have any queries, please feel free to contact us on <Link href="mailto:academy_connect@opengrowth.com" underline="hover">academy_connect@opengrowth.com</Link>.
				
				<br/>
				<br/>
				
				By using our website, you hereby consent to our Privacy Policy and agree to its terms of service.
				
				</Box>
			</Typography>			
		</Grid>
		
	
		
		<Grid item  xs={10} >	
		<Introduction/>		
		</Grid>

		
		<Grid item  xs={10} >	
		<Information/>		
		</Grid>

		
		<Grid item  xs={10} >	
		<What_Info_We_Collect />		
		</Grid>

		
		<Grid item  xs={10} >	
		<Use_Of_Info />		
		</Grid>

		
		<Grid item  xs={10} >	
		<Confidentiality />		
		</Grid>

		
		<Grid item  xs={10} >	
		<Advertisements />		
		</Grid>

		<Grid item  xs={10} >	
		<Refund/>		
		</Grid>

		
		<Grid item  xs={10} >	
		<Revision/>		
		</Grid>
		
		
		</Grid>
		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
