import {
	Box,
	Grid, 
	Link,
	ThemeProvider, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	

		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center">

					
	
		
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" marginBottom={8}>
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					Revision of terms & Grievance reporting
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" color="textSecondary"> 
					 <Box  marginTop={1.5} lineHeight={1.5}> OpenGrowth Academy may update or amend this Policy at any time, with or without advance notice. In the event there are significant changes in the way OpenGrowth Academy treats User's personally identifiable information, OpenGrowth Academy will display a notice on the Platform or send Users an email. OpenGrowth Academy's current Policy to all information that  OpenGrowth Academy has about Users and their account.<br/><br/>
					Notwithstanding the above, OpenGrowth Academy shall not be required to notify the Users of any changes made to the privacy policy. Should You have any concern or reject the changes in the privacy policy You can refuse to accept the amendments and opt for withdrawing Your Personal Information by writing to us <Link href="mailto:academy_connect@opengrowth.com" underline="hover">academy_connect@opengrowth.com</Link>.
					 </Box>
					 </Typography> 
					
					</Box>			
		</Grid>
		
		
				
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
