import {
	Box,
	Grid, 
	
	ThemeProvider, Typography
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";
import CommonCard from './CommonCard';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	
		let points=[
		{'text':"OpenGrowth Academy takes all the necessary precautions to not leak the personal information shared with us without your consent. If you need to make any changes in the information, OpenGrowth allows you to make changes and then render our services available to you. Before modification in your personal information, OpenGrowth Academy may ask for your photo identification and then act on your request."}, 
		{'text':"OpenGrowth has the right to deny any request that is contrary to the law."},{'text':"We do not guarantee that the residual copies and archives will be deleted once your services have been availed, though avoid retaining your personal information for long."},{'text':"Your personal information will be shared with third party service providers only with your consent, be it for marketing purposes of financial transactions."},{'text':"OpenGrowth Academy will use the personal information with respect to the platform."},{'text':"OpenGrowth Academy can communicate with you through calls, emails, messages and any other medium of digital communication as and when required."},{'text':"It is at the user's discretion to stop the communication, by unsubscribing to our communication services."},{'text':"For any other queries feel free to contact us on academy_connect@opengrowth.com"} ]
		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  >

					
	
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 Use of your information 
					 </Box>
					 </Typography> 
					
					<CommonCard points={points} />

					
		</Box>			
		</Grid>
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
