import { Avatar,Box,Button, Card, CardContent, CardHeader, CircularProgress, IconButton ,makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { api, translateText } from "../../functions/Helper";








const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);




class Logs extends Component {

    constructor(props) {
	super(props);
	    this.translateText = translateText.bind(this);
	this.state={
		      msg:false,msg_type:false,
            course_show:[],start:0,end:20,hasMore: true,record_len:0}
    }
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
				axios.get(api+'/getAllSpeakers')
				.then((response) => {
				if(response.data.length<this.state.end){this.state.hasMore=false;}	
				this.setState({ course_show: [...this.state.course_show,...response.data]})
			});			
			}, 1500);
		}
	};	
	deleteSpeaker = (speaker_id) => {
    
		axios.post(api+'/deleteSpeaker',{'speaker_id':speaker_id})
		.then((response) => {
		//alert(response.data['msg'])
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        this.getAllSpeakers()
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}	
    updateSpeaker = (email,name) => {
    
		this.props.parentCallback([email,name])
	
	}
	getAllSpeakers=()=>
    {
        axios.get(api+'/getAllSpeakers', {search:'all'})
			.then((response) => {
			this.setState({course_show:response.data})
            //console.log(response.data.length)
			if(response.data.length<this.state.end)
            {this.state.hasMore=false;
           	this.setState({hasMore:false})
            }	
			//console.log(response.data)
			});
    }
	componentDidMount()
	{
		
		this.getAllSpeakers()	
	}
  	render()
  	{
	
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.course_show.map((e, index) => (
		<StyledTableRow>
			<StyledTableCell  >
			 <Box display="flex" flexDirection="row" p={1} m={1}>
				<Box p={1} >
				 <Avatar src={'assets/images/speakers/'+e['speaker_img']}  />
				</Box>
				<Box p={1} >
                    {e['speaker_email']}
				</Box>
			  </Box> 
			</StyledTableCell>
             <StyledTableCell  >{e['speaker_name']}</StyledTableCell>
			  <StyledTableCell  >{e['speaker_desc']}</StyledTableCell>
			  <StyledTableCell  >{e['speaker_desc2']}</StyledTableCell>
			  
              	<StyledTableCell  >
               <IconButton  onClick={()=>{this.updateSpeaker(e['speaker_email'],e['speaker_name'])}}>
						<EditIcon color="grey"   />
				  </IconButton> 

				  <IconButton  onClick={()=>{this.deleteSpeaker(e['speaker_id'])}}>
						<CloseIcon color="grey"   />
				  </IconButton>
             </StyledTableCell>
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={"Speakers"}/>
	  
		<CardContent>
		
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.course_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
									   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
				
				}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Name</StyledTableCell>
						<StyledTableCell >Position</StyledTableCell>
						<StyledTableCell >Description</StyledTableCell>
						<StyledTableCell ></StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
				 
				
				
				
			
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default Logs;