
import {
	Box, Card, CardContent, Button, CardHeader, Chip, CircularProgress, 
	Grid, Divider ,
	Typography , makeStyles
} from '@material-ui/core';
import React, { Component,Suspense } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-multi-carousel/lib/styles.css";
import axios from 'axios';
import "react-sweet-progress/lib/style.css";
import AlertBox from "../../AlertBox";
import { getCacheDetails } from "../../caching";
import { mentors_category, api } from "../../Helper";
import { ViewMentors_Skeleton,CarouselSkeleton } from '../../Skeleton';
import RenderCard from './RenderCard';
const MentorsCategory = React.lazy(() => import('./MentorsCategory'));

const useStyles = makeStyles(theme => ({
	
   chip: {
	 //  padding:'20px 4px',
	   borderRadius:'25px',
   },
   
	active :{
	"&:hover": {
			   backgroundColor: 'white',
			  color: 'rgb(37, 56, 124)',
				border: '1px solid rgb(37, 56, 124)'},
	},
	inactive :{
		"&:hover": {
			    color: 'rgb(37, 56, 124)',
				 border: '1px solid rgb(37, 56, 124)'
			},
	},
	
   		  
  }));

let status=null;
let student_id=null;
let email=null;

let token=localStorage.getItem('login_token')
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
}
if(token=="admin")
{
	student_id="admin"
}
let active_mentor_name=null
let active_mentor_email=null
let active_mentor_req=null
let active_mentor_id=null
class Scroll extends Component {

    constructor(props) {
    super(props);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	mentors_list:[],
	loading:true,hasMore:true,
	start:0,end:10,
	msg:false,msg_type:"success",
	search:this.props.action=="popular"?'all':'',search_with:false,
	btn_loading:false,
	hover:false,	
	active_card:null,
	
	}
    }
	
	
	
	setCustomState=(state,val)=>{
		this.setState({[state]:val})
	}
	
		get_search_record = (start,fetch,search,search_with) => {

		let payload={
			'email':email,
			'start':start,
			'end':this.state.end,
			'key':start+"_"+search_with+"_"+search,
			'search':search,
			'search_with':search_with,
			'action':'popular'
		}
		//alert(this.state.start)
		//alert(payload.key)
		//console.log(payload)
		axios.post(api+'/search_mentor',
		payload)
		.then((response) => {
			
				let data=response.data
				//console.log(data)

				this.setCustomState("loading",false)
				if(data.length<this.state.end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					
					this.setState({ mentors_list: data })
				}	
				else
				{
					if (data.length>0)
					{
						this.setState({
						 mentors_list: [...this.state.mentors_list,...data]
						})
					}
					else
					{
						this.setState({ msg: "You have seen it all!" })
					
					}

				}
				
				setTimeout(() => {
					this.setState({ msg: false})
				}, 2000);
				//console.log(this.state.mentors_list)
		});	
	}
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		setTimeout(() => {
			
			let st=this.state.start+this.state.end
			this.setState({start:st})
			if(this.state.search!='' && this.props.action=="popular")
			{
				this.get_search_record(st,true,this.state.search,this.state.search_with)
			}
			else 
			{
				this.getCachePlay(st,true)
			}
			
		}, 100);
	}}
	componentDidMount()
	{
		
				this.getCachePlay(this.state.start,false)	
			
			
	}
	
		
	search_mentors = (tag,search_with) => {
		
		
		this.setCustomState("search",tag)
		this.setCustomState("start",0)
		
		this.setState({start:0},()=>{console.log(this.state.start)})
		//alert("set to 0 "+this.state.start)
		this.setCustomState("hasMore",true)
		this.setCustomState("search_with",search_with)
		
		if(tag=="All")
		{
			this.setCustomState("mentors_list",[])
			this.getCachePlay(0,true)
		}
		else
		{
			this.get_search_record(0,false,tag,search_with)
		}
	}	
	getCachePlay = (start,fetch) => {
		let end=this.state.end
		//alert(end)
		//alert(fetch)
		
		this.getCacheDetails(
		student_id+"_mentors_"+this.props.action+"_"+start+"_to_"+end,
		"get_mentors",
		[student_id,status,start,end,this.props.action])
		.then(data => {	
			console.log(data.length)
				
				this.setCustomState("loading",false)
				if(data.length<end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					this.setState({ mentors_list: data })
				}	
				else
				{
					if (data.length>0)
					{
						this.setState({
						 mentors_list: [...this.state.mentors_list,...data]
						})
						//console.log(this.state.mentors_list)

					}
					else
					{
						this.setState({ msg: "You have seen it all!" })
					}

				}
		});	
	}


	handleCacheCallback = () => {
		this.getCachePlay(0,true)	
	}
    render()
	{
	const styles ={
	
		card: {position:"relative",width: '100%',height: 'auto',},
		
		img:{width: '100%',height: '100px'},
		imgF:{width: '100%',height: '250px'},
		
		box:{height:'auto',paddingRight:"12px"},
		small: {width: '64px',height: '64px'},
		avatar: { 'padding-right':'1rem'},
		iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px'}

				
		};
		const classes = this.props.classes;
		let i=0;

	    const CategoriesCard = () =>
		{ 
		let cateory=[...["All"],...mentors_category]
				return(	
				<Box padding={0}>
						  <Typography variant="subtitle1" color="textPrimary" >
							<Box fontWeight="600" marginBottom={2} display="none">Popular Categories</Box>
						 </Typography>
						 
						   <Box display="flex" marginBottom={2}>
						   <Grid container spacing={2} justifyContent="center">
						   {cateory.map((e, index) => (
								
							<Grid item xs="auto" key={index}>
								{
									e==this.state.search
									?<Chip 
									className={classes.chip+" "+classes.active}
									label={<Box fontWeight="bold" fontSize="12px">{e}</Box>} 
									 color={e==this.state.search?"primary":""}
									clickable onClick={() => {this.search_mentors(e,"tags")}}
									/>
									:<Chip 
									className={classes.chip+" "+classes.inactive}
									label={<Box fontWeight="bold" fontSize="12px">{e}</Box>} 
									 variant={"outlined"}
									 color={e==this.state.search?"primary":""}
									clickable onClick={() => {this.search_mentors(e,"tags")}}
									/>
								}
								</Grid>
								
							))}
							</Grid>
							
							
							</Box>
					
					<Box marginBottom={2} display="none">
					<Divider />
					</Box>
					
				</Box>
				)
		}
		const MentorsProfile = () => {
		let arr=this.state.mentors_list;
		arr=window.location.pathname=="/mentors-dashboard"?arr.slice(0,2):arr
		
		return (
			<InfiniteScroll style={{overflow:'hidden'}}
			dataLength={this.state.mentors_list.length}
			hasMore={this.state.hasMore}
			loader={
			this.state.hasMore ? (
			  <Box marginTop={2} marginBottom={2}
			  alignItems='center'
			display='flex'
			flexDirection='column'>
				{this.state.loading ? (
				  <CircularProgress disableShrink />
				) : (
				  <Button
					variant="outlined"
					size="medium"
					color="primary"
					disabled={this.state.loading}
					onClick={this.fetchMoreData}
				  >
					Load More
				  </Button>
				)}
			  </Box>
			) : (
			  <CircularProgress disableShrink />
			)
		  }
			endMessage={
				<>
				{window.location.pathname=="/mentors-and-experts"?'':
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>}
				</>
			}>
				<Box >
				<Grid container spacing={2}>
				{
		
				arr.map((e, index) => (
				<Grid item 
				xs={12} 
				md={["recent","followed"].includes(this.props.action)?4:6}
				lg={["recent","followed"].includes(this.props.action)?3:6}
				>
						<Box padding={0.5}>
						<RenderCard 
						user={e}
						action={this.props.action}
						parentCacheCallback={this.handleCacheCallback}
						/>
						</Box>
				</Grid>
			))}
			</Grid>
			</Box>
		</InfiniteScroll>		
		)
	}
	{/*style={{'box-shadow': 'rgb(140 152 164 / 25%) 2px -3px 6px 0px',
		'border-bottom-left-radius':'0px','border-bottom-right-radius':'0px'}}
	*/}
			
	 const MentorsFetch = () => {
	  return(
	 <>
	 {/* <Grid item xs={12}>
				<Suspense fallback={<div><CarouselSkeleton /></div>}>
				  <MentorsCategory category="Expert" />
				</Suspense>
	  </Grid>
	 */}
	  <Grid item xs={12}>
				  <Suspense fallback={<div><CarouselSkeleton /></div>}>
				  <MentorsCategory category="Leadership" />
				</Suspense>
	  </Grid>
	  </>	
	  )
	}
	return ( 
		
	
		<Box>
		
		<Grid container spacing={2}>
	    <Grid item xs={12}>
		<Card>
		{
			this.state.msg!=false
			? <AlertBox show_status={true} msg={this.state.msg} msg_type="success" />
			:''
		}

		<CardHeader 
		title={
		this.props.action=="recent"?status=='professor'?"Profile Viewers":"Recently Viewed Experts"
		:this.props.action=="popular"?'Popular Experts':status=='professor'?"Followers":"Followed Experts"
		} />
		<CardContent>
		{
			
			this.state.loading==true
			?<Box marginBottom={1}>
			{
				["recent","followed"].includes(this.props.action)
				?<ViewMentors_Skeleton  card="small" />
				:<ViewMentors_Skeleton/>
			}
			</Box>
			:
				<React.Fragment>
				{
						this.props.action=="popular"
						? <CategoriesCard />
						:""
				}
				{				
						this.state.mentors_list.length==0
						?
							<Typography variant="subtitle1" color='textSecondary'>
							<Box marginTop={1} lineHeight={1.5}>
							{
								this.props.action=="recent"
								?"You have not recently visited any experts."
								:	
									this.props.action=="popular"
									?"Experts cannot be found."
									:"You have not yet followed any experts."
							}
							</Box> 
							</Typography>
						:
							
							 <MentorsProfile />
							 

				}
				</React.Fragment>

		}
		
		</CardContent>
		</Card>
		</Grid>
		
		{this.props.action=="popular" && <MentorsFetch/> }
		
		</Grid>

	</Box>
				
	);
  }
}


export default (props) => {
    const classes = useStyles();
	return (
        <Scroll  
		action={props.action}
		classes={classes} />
    )
}
