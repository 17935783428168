import {
	Avatar, Box, Dialog, DialogContent, 
	IconButton,Button,
	Typography} from '@material-ui/core';


import React, { Component } from "react";
import { useHistory,useLocation } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

import "react-multi-carousel/lib/styles.css";
import "react-sweet-progress/lib/style.css";
import { useStyles } from "../useStyles";
import CloseIcon from '@material-ui/icons/Close';
import './blog.css'



let og_pah="https://www.opengrowth.com/assets/uploads/images/co_brand_1/article/"
		
class ArticleDialog extends Component {

    constructor(props) {
    super(props);
    }
	
	
	handleClose = () => {
		this.props.parentCallback();
	}
		
	viewblog= () => {

		if (this.props.article!=undefined) {
		if (window.location.pathname === "/blogs") 
		{
			//alert(this.props.article)

			let slug=this.props.article
			slug="https://www.opengrowth.com/resources/"+slug['slug']
			window.location.replace(slug);
		} 
		else {
		  this.props.history.push({
			pathname: "/blogs",
			  state: {
					article:this.props.article
			} })
		}}
		else{
			//alert(1)
					console.log(window.location.pathname)
					console.log(this.props.article)
					console.log(this.props.article['slug'])
			
		}

			
	}
    render()
	{
	const styles ={
	
		card: {position:"relative",width: '100%',height: 'auto',},
		
		img:{width: '100%',height: '100px'},
		imgF:{width: '100%',height: '250px'},
		
		box:{height:'auto',paddingRight:"12px"},
		iconsbg:{
		zindex: 9999,
		 backgroundColor:'#0000001f',
		 padding:'8px',
		 marginBottom:'8px',
		  position: 'fixed',
		  'z-index': 9999
		}

				
		};
		const classes = this.props.classes;

			let blog_content=this.props.article['content']
			let old_text="src=\"\/assets";
			let new_text="src=\"\https:www.opengrowth.com/assets";
			if(blog_content!=undefined)
			{
				blog_content=blog_content.replaceAll(old_text,new_text)
				blog_content=blog_content.replaceAll("<p>Let us know your opinion in the comment section.<\/p>\r\n\r\n<p>&nbsp;<\/p>\r\n\r\n<p>We, at&nbsp;<a href=\"https:\/\/www.opengrowth.com\/\" target=\"_blank\">OpenGrowth<\/a>, are continually looking for trending startups in the ecosystem.&nbsp;<\/p>\r\n\r\n<p>If you want to know any further information about the startup ecosystem or have any mind-boggling ideas, do refer to the other blogs at&nbsp;OpenGrowth. If you have any suggestions, do let us know in the comment section below.<\/p>\r\n",'')
			
				//console.log(blog_content)
			}
	
	const ContentOnly =()=>{
			let title=this.props.article['title']
			let meta_description=this.props.article['meta_description']
			if([null,undefined].includes(title)==false)
			{
				title=title.replaceAll(/[\\\/"]/g, '')
				meta_description=meta_description.replaceAll(/[\\\/"]/g, '')
			}
			let replacement = 'src=\"https:\/\/www.opengrowth.com\/'
		    blog_content = blog_content.replaceAll('src=\"/', replacement)
		return(
		<React.Fragment>
			<Box width="100%" display="flex" flexDirection="row">
				<Box flexGrow={1} paddingBottom={1}>
				<Typography variant="h6" color='primary'>
					<Box  fontWeight="600" style={styles.name}>
					{title}
					</Box>
				</Typography>
				</Box>
				<Box padding={1} display={this.props.render=="main"?"none":"block"}>
				<IconButton 
				onClick={this.handleClose}
				style={styles.iconsbg}>
							<CloseIcon color="grey"  
							style={{fontSize:"0.85rem"}} />
				</IconButton>
				</Box>
			</Box>
			<Box display="block">
			<Box marginBottom={2} style={{borderRight:"2px solid #F2F2F2"}}>
			 <Avatar style={{height:"427px",width:'100%'}} variant="square"  src={og_pah+this.props.article['cover_image']} >
			 </Avatar>
			</Box>
			<Typography variant="subtitle2" color="textSecondary" component="p">
				<Box lineHeight={1.5} className="blog" > 
				<span dangerouslySetInnerHTML={{ __html: blog_content }} />	
				</Box>
			</Typography>
			
			<Box marginTop={2} marginBottom={2} >
				<Button 
				onClick={this.viewblog}
				color="primary"
				variant="contained"
				size="medium"
				endIcon={ <FaChevronRight fontSize="medium"/>}>
				Read full article
				</Button>
		    </Box>
				
				
			</Box>
		</React.Fragment>
		)
	}
	
	const WithDialog =()=>{
		return(
			<Dialog
			open={this.props.article!=undefined?true:false}
			onClose={this.handleClose} 
			fullWidth style={styles.border}
			classes={{ paper: classes.dialog_paper}}
			maxWidth="lg">
			
			<DialogContent >
			<ContentOnly/>
			</DialogContent>			
			</Dialog>
		)
	}
	
	return ( 
		
			<React.Fragment>
			{
				this.props.render=="main"
				?<ContentOnly/>
				:<WithDialog/>
			}
			</React.Fragment>
				
	);
  }
}


export default (props) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
	return (
        <ArticleDialog  
		history={history}
		location={location}
		classes={classes}
		article={props.article} 
		render={props.render} 
		parentCallback={props.parentCallback}  
		 />
    )
}
