import React, { useState, useEffect } from 'react';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ThemeProvider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Academytheme } from "../../useStyles";
import { config } from "../../Helper";
import { menuItems,cimpmenuItems } from "./HeaderMenu";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  appBar: {
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transition: 'background-color 0.3s ease',
    backgroundColor: (isScrolled) => (isScrolled ? '#ffffff' : 'transparent'),
    boxShadow: (isScrolled) =>
      isScrolled ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: drawerWidth,
    },
  },
  toolbar: {
    width: '100%',
    margin: 'auto',
    justifyContent:(openMenu) => (openMenu ? 'space-between' : '') ,
	display: 'block',
	[theme.breakpoints.up('md')]: {
      display: 'flex',
	  width: '94%',

    },
  },
  menuButton: {
    margin:'8px 0px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }, 
  title: {
    color: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
	borderRadius: '0px'
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  logo: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  desktopMenu: {
    margin: config.logo === 'Logo_CIMP'?'unset':'auto',  
    display:(openMenu) => (openMenu ? 'flex' : 'none') ,
	alignItems: 'flex-start' ,
	flexDirection: 'column' ,
   [theme.breakpoints.up('md')]: {
      display: 'flex',
	  alignItems: 'unset' ,
	  flexDirection: 'unset' ,
    },
  },
  btn: {
  [theme.breakpoints.up('md')]: {
		margin: '0px 30px !important',
    },
    margin: '10px 0px !important',	  
    borderRadius: '25px',
    textTransform: 'unset',
    fontSize: '18px !important',
    padding: '7px 22px !important',
    fontWeight: '400 !important',
	
  },
  popup: {
    marginTop: '40px',
  },
  menuPaper: {
    borderRadius: '8px', 
  },
  popupitem: {
	fontSize: '18px',
	fontFamily: 'system-ui',
    color: '#757575',
	fontWeight: 400,
	"&:hover": {
      color: "black",
    },
  },
  menu: {
  [theme.breakpoints.up('md')]: {
       margin: '0px',
	   padding: '0px 30px',
		width: 'fit-content',
    },
    margin: '0px',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '1.5rem',
	fontFamily: 'system-ui',
  },
  me: {
    color: '#253B7C',
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
      color: "#253B7C",
    },
  },
  box: {
    backgroundColor: "",
  },
}));
const Header = () => {
  const classes = useStyles();
const theme = useTheme();
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState([]);
  const [isScrolled, setIsScrolled] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElIndex, setAnchorElIndex] = useState(null);
   let myMenu=config.logo === 'Logo_CIMP'?cimpmenuItems:menuItems
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingUp = prevScrollPos > currentScrollPos;
      setIsScrolled(currentScrollPos > 0 && isScrollingUp);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  const handleSubMenuToggle = (index) => {
    setOpenSubMenu((prevOpenSubMenu) => {
      const openSubMenuCopy = [...prevOpenSubMenu];
      openSubMenuCopy[index] = !openSubMenuCopy[index];
      return openSubMenuCopy;
    });
  };

  const handleMenuItemClick = (item) => {
    if (item.submenu.length > 0) {
      handleSubMenuToggle(myMenu.indexOf(item));
      setAnchorElIndex(myMenu.indexOf(item));
    } else {
		
      handleMenuToggle();
    }
  };

 const handleMenuOpen = (event, index, item) => {
  if (item.submenu.length === 0) {
    if (item.link.includes("http")) {
      window.location.href = item.link;
    } else {
      window.location.pathname = item.link;
    }
  }
  setAnchorEl(event.currentTarget);
  setAnchorElIndex(index);
};


  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const LogoCard = () => {
	  let logoname=config.logo === 'Logo_CIMP'?"https://cimp.opengrowth.com/assets/images/logo/cimp-hori-logo.jpeg":"https://www.opengrowth.com/assets/og/images/opengrowth-logo.png"
    return (
      <Box 
	  marginBottom={config.logo === 'Logo_CIMP'?2:0}
	  marginTop={config.logo === 'Logo_CIMP'?2:0}
	  onClick={()=>{window.location="https://opengrowth.com/"}}
	  className={classes.title} height="64px" display="flex" alignItems="center">
        <img src={logoname} height={config.logo === 'Logo_CIMP'?"54":"52"} alt="OpenGrowth Logo" loading="lazy" />
      </Box>
    );
  };

  const MenuCard = () => {
    return (
       <Box className={`${classes.desktopMenu} `}>
            {myMenu.map((item, index) => (
              <React.Fragment key={index}>
                <Button
                  className={` ${item.menu === "Login" || item.menu === "Join Now" ? classes.btn : classes.me} ${classes.menu}`}
                  variant={item.menu === "Login"  || item.menu === "Join Now" ? "contained" : "text"}
                  color={item.menu === "Login" || item.menu === "Join Now" ? "primary" : "inherit"}
                  aria-controls={`submenu-${index}`}
                  aria-haspopup="true"
                  endIcon={
                    item.submenu.length > 0 && (
                      openSubMenu[index] ? (
                        <TiArrowSortedUp color="primary" size={15} />
                      ) : (
                        <TiArrowSortedDown color="primary" size={15} />
                      ))}
                  onClick={(event) => handleMenuOpen(event, index,item)}
                >
                  {item.menu}
                </Button>
                {item.submenu.length > 0 && (
                  <Menu
                    id={`submenu-${index}`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && index === anchorElIndex}
                    onClose={handleMenuClose}
                    anchorOrigin={{
					  vertical: 'bottom',
					  horizontal: 'right'
					}}
					transformOrigin={{
					  vertical: 'top',
					  horizontal: 'right'
					}}
                    className={classes.popup}
                    classes={{ paper: classes.menuPaper }}
                  >
                    {item.submenu.map((subitem, subindex) => (
                      <MenuItem
                        key={subindex}
                        component="a"
                        href={subitem.link}
                        className={`${classes.popupitem}`}
                        onClick={handleMenuClose}
                      >
                        {subitem.label}
                      </MenuItem>
                    ))}
                  </Menu>
                )}
              </React.Fragment>
            ))}
          </Box>
    );
  };

  return (
    <ThemeProvider theme={Academytheme}>
      <AppBar position="fixed" className={`${classes.appBar}`}>
        <Toolbar className={classes.toolbar}>
		  <Box className={classes.box} display="flex" justifyContent="space-between">
		  <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="menu"
            onClick={handleMenuToggle}
          >
            <MenuIcon />
          </IconButton>
          <LogoCard />
		  </Box>
		  {window.innerWidth < 960 && openMenu && <MenuCard />}
		  {window.innerWidth > 960 && <MenuCard />}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
