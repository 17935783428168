import { AppBar, Box, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import React, { Suspense,useEffect } from 'react';
import { api,secondaryColor , primaryColor , successColor , notifyColor } from '../../Helper';
import {FaRegCalendarMinus,FaRegCalendarAlt,FaRegCalendarCheck,FaRegCalendarTimes} from "react-icons/fa";
import axios from 'axios';

const UpcomingRequest = React.lazy(() => import('./UpcomingRequest'));
const PendingRequest = React.lazy(() => import('./PendingRequest'));
const RejectedRequest = React.lazy(() => import('./RejectedRequest'));

let def={'upcoming':0,'pending':0,'attended':0}
let status=null
let user_id=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	user_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}

 

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {flexGrow: 1,width: '100%'},
  global:{textTransform: 'capitalize',color: 'rgb(99, 115, 129)','text-align': 'center',fontSize:'0.87rem',},
  m:{marginLeft:'auto' },
  width:{minWidth:'120px'},
  tab: {verticalAlign : 'middle',marginRight:'8px',display:'unset'},
	  upcoming: {
		  color: successColor
		//  "& .MuiTabs-indicator": {backgroundColor:successColor,height: 2},
		//  "& .MuiTab-root.Mui-selected": {color: successColor}
	  },
	 pending: {
		 color: secondaryColor
		//  "& .MuiTabs-indicator": {backgroundColor:secondaryColor,height: 2},
		//  "& .MuiTab-root.Mui-selected": {color: secondaryColor}
	  },
	 rejected: {
		 color: notifyColor
	//	  "& .MuiTabs-indicator": {backgroundColor:notifyColor,height: 2},
	//	  "& .MuiTab-root.Mui-selected": {color: notifyColor}
	  },
	 attended: {
		 color: primaryColor
		//  "& .MuiTabs-indicator": {backgroundColor:primaryColor,height: 2},
		//  "& .MuiTab-root.Mui-selected": {color: primaryColor}
	  },
	  transparent:{backgroundColor:'transparent'}

}));

export default function ScrollableTabsButtonPrevent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
 const [tab_count, setTabValue] = React.useState(def);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  }; 
   const getActionFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return parseInt(urlParams.get('action')) || 0;
  };

  	
	const get_tab_count=()=>
    {
		if(status!='')
		{
		   let user=status=='student'?"mentee":"mentor";
		   let payload={
				'user':user,
				'm_id':user_id
			}
			axios.post(api+'/get_tab_request_count',payload)
			.then((response) => {
				setTabValue(response.data)
			})
		}	
	}
	useEffect(() => {
		setValue(getActionFromUrl()); 
		get_tab_count();
    },[]);
	
// className={value==0?classes.upcoming:value==1?classes.pending:value==2?classes.rejected:classes.attended}
  return (
   
	<div className={classes.root}>
        <Grid container spacing={3} >
			
			<Grid item xs={12} sm={12}  >
				
			<AppBar position="static" color="inherit" elevation={0}   className={classes.transparent} >
			<Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" >
			 
						 			 
						
			<Tab  className={classes.width}  label= {<Box fontWeight="600" >
			<FaRegCalendarCheck className={classes.tab +" "+classes.upcoming} fontSize="18px"  /> 
			Upcoming Appoinments ({tab_count['upcoming']}) </Box>}
			className={classes.m+' '+classes.global}  {...a11yProps(0)} />
						  
						  
			<Tab  className={classes.width} label= {<Box fontWeight="600">
			<FaRegCalendarMinus className={classes.tab+" "+classes.pending} fontSize="medium"  /> 
			Pending Request ({tab_count['pending']}) </Box>}
			className={classes.global}  {...a11yProps(1)} />
						  
			 <Tab className={classes.width} label= {<Box fontWeight="600"  >
			 <FaRegCalendarTimes  className={classes.tab+" "+classes.rejected}  fontSize="medium"  /> 
			Rejected Request ({tab_count['rejected']}) </Box>} 
			 className={classes.global}  {...a11yProps(2)} />
						  
						  
			<Tab className={classes.width} label= {<Box fontWeight="600"  >
		    <FaRegCalendarAlt  className={classes.tab+" "+classes.attended}  fontSize="medium"  /> 
			Attended Appoinments ({tab_count['attended']})  </Box>} 
			className={classes.global}  {...a11yProps(3)} />
						  
						  
			</Tabs>
			</AppBar>
								
		</Grid>
        
		<Grid item  xs={12} sm={12} >
			
			<TabPanel value={value}   index={0} className="tab">
			<Suspense fallback={<div> </div>}><UpcomingRequest action="upcoming" /></Suspense>
			</TabPanel>
			
			
			<TabPanel value={value} index={1} className="tab">
			<Suspense fallback={<div></div>}><PendingRequest pendingCallback={get_tab_count} /></Suspense>
			</TabPanel>
			
			<TabPanel value={value}  index={2} className="tab">
			<Suspense fallback={<div> </div>}><RejectedRequest /></Suspense>
			</TabPanel>
			
			<TabPanel value={value}  index={3} className="tab">
			<Suspense fallback={<div> </div>}><UpcomingRequest action="attended" /></Suspense>
			</TabPanel>
			
	   </Grid> 
       
    </Grid>
    </div>


  );
}

