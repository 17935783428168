
import { Box, Button, Card, CardContent, CircularProgress, Grid,
FormControl,
InputLabel, 
MenuItem,
Select,
TextField, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";
import { validEmailRegex, validTextRegex ,speakers_path} from "../../functions/Helper";
import SpeakersList from './SpeakersList';
import ImageUpload from "../../functions/ImageUpload";





const location = window.location.pathname;

 
class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
            fname:null,
            lname:null,
            email:null,
            desc1:null,
            desc2:null,
            desc2:null,
            speakerImg:null,
            email_old:null,
            title:false,
            reg:false,
            loading:false,
            
            operation:'add',
            speaker_id:null,
            speaker_name:null,
            
            msg:false,msg_type:false,upload:false,
            isFileUpdated:false,operation:'add',
		errors: {
            fname:"Initalize",
            lname:"Initalize",
            email:"Initalize",
            desc1:"Initalize",
            desc2:"Initalize"
        }
	}
    }
	
	handleDateChangeStart = (dob) => {
		this.setState({ start: new Date(dob)});
		if(this.state.start!=null){this.state.errors.start='';}
	}
	handleDateChangeEnd = (dob) => {
		this.setState({ end: new Date(dob)});
		if(this.state.end!=null){this.state.errors.end='';}
	}
	
	
	componentDidMount()
	{
		
		if(localStorage.getItem('login_token')!='admin')
		{
			window.location='/webinars'
		}
	}
     handleChange = event => {
    //event.preventDefault();
	let errors = this.state.errors;
    const { name, value } = event.target;
    this.state[name]=value;
    this.setState({changeFound:true}); 
	if(name=="email")
	 {
		errors.email =  value!=null? "Email is required" : "";
		errors.email = validEmailRegex.test(value) ? "" :  "Invalid Email ID" ;
  	 }
	 else
	 	{	
	 		if(name=="fname"|| name=="lname")
	 		{
	 			errors[name] = value!=null? validTextRegex.test(value)==true ? "Only text allowed" : "":this.getText(name)+" is required";
	 			//alert(validTextRegex.test(value) +" "+value+" "+errors[name]);
	 			//console.log(value);
	 		}
	 		else
	 		{
	 			errors[name]=value==null?this.getText(name)+" is required":"";
	 		}	
	 		//alert("here i'm counting - "+name+ " "+value+" "+errors[name]);
	 	}
	 	this.setState({ errors, [name]: value });
  };

	validForm=()=>
	{
		const registerList = ['fname', 'email', 'desc1', 'desc2'];
		let allFilled=false;
		this.setState({reg:false});
		for (let i = 0; i < registerList.length; i++)
		{
			let value=registerList[i];
			allFilled=this.checkError(value,this.state[value]);
		    if (allFilled==false)
			{
				break;
		    }
		}
		return allFilled
	}
		

	handleSubmit = (e) =>
	{
		e.preventDefault();
		let isValidate=this.validForm()
		
           //alert(this.state.desc2)
		if(isValidate==true)	
        {
				this.setState({loading:true})	
                   	if(this.state.imgDetails==false && this.state.speakerImg==null)
                    {
                        this.setState({msg:'Please upload speaker image.'})
                        this.setState({msg_type:'warning'})
                        isValidate=false
                        
                    } 
                                
                    else
                        
                    {
						let fname=this.state.title==false?this.state.fname:this.state.title+' '+this.state.fname
                        let payload={
                            'fname':fname,
                            'lname':this.state.lname,
                            'email':this.state.email,
                            'desc1':this.state.desc1,
                            'desc2':this.state.desc2,
                            'email_old':this.state.email_old,
                        }
                        this.setState({loading: true});
                        
                        let path='/api/addSpeaker'
                        if(this.state.operation=='update'){path='/api/updateSpeaker'}
                        
                        this.setState({upload:'Updating databse ....'})
                        axios.post(path,payload)
                        .then((response) => 
                        {
                            //console.log(response.data);
                            //console.log(response.data['type'])
                            if(response.data['type']=="success")
                            {
                                
                                this.setState({upload:'Database updated ....'})
                                this.setState({reg: "Speaker added successfully"});
                                this.setState({msg: "Speaker added successfully"});
                                this.setState({msg_type:response.data['type']})
                                 this.setState({loading: false});
                                 
                                if(this.state.operation=='add'){
                                 setTimeout(() => {window.location.reload()}, 3000);    
                                }
                                

                                 
                            }
                            else
                            {
                                this.setState({upload:'Database update operation failed ....'})
                                this.setState({msg: "Try again if upload operation failed / Update Speakers details"});
                                this.setState({msg_type:'warning'})
                                setTimeout(() => {this.setState({msg:false})}, 3000); 

                            }
                        }, (error) => 
                        {
                            //console.log(error.data);
                        });
                        
                        this.setState({loading: false});
                        setTimeout(() => {this.setState({reg:false})}, 2000);
                    }
           }
            setTimeout(() => {
                                this.setState({msg:false})
                                  this.setState({loading: false});

             }, 2000);
		
	}
	
	checkError=(name,value)=>
    {
   		this.state.errors[name]=value==null||value==''?" All fields are required":"Initalize";
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
	}
	 
	getSpeakerDetails=(email)=>
    {
		axios.post('/api/getSpeakerWithID',
		{'email': email})
		.then((response) => 
		{
            let k=response.data[0]['speaker_name'] 
            k=k.split(" ")
			
			if (k.length>2)
			{
								
				this.setState({title:k[0]},()=>{console.log(this.state.title)})
				this.setState({fname:k[1]},()=>{console.log(this.state.fname)})
				this.setState({lname:k[2]},()=>{console.log(this.state.lname)})

			}	
			else{
				
				this.setState({fname:k[0]},()=>{console.log(this.state.fname)})
				this.setState({lname:k[1]},()=>{console.log(this.state.lname)})

			}
           
			this.setState({email:response.data[0]['speaker_email']})
			this.setState({desc1:response.data[0]['speaker_desc']})
			this.setState({desc2:response.data[0]['speaker_desc_para']})
			this.setState({speakerImg:response.data[0]['speaker_img']})
			//console.log(response.data)
		})
	}
	  	
	handleCallback=(data)=>
    {
        
		this.setState({speaker_name:data[1]},()=>{console.log(this.state.speaker_name)})
		this.setState({speaker_id:data[0]})
		this.setState({email_old:data[0]})
		this.setState({operation:'update'})
		this.getSpeakerDetails(data[0])
	}
	  
	
  	render()
  	{
		

		const { errors } = this.state;

		
		
		
		

		return(
			
            <Grid container spacing={2}>
        { 	
					
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type==="success"?"success":"warning"} />
					: ''
				
				}	 
		
		
        
		<Grid  xs={12} sm={12}>
        <Box padding={1}>
            <Card>
				
				<CardContent>
			
				
     			<Box  marginBottom={2}  fontWeight="600">
				<Typography variant="h6"  color="textPrimary">
				<Box  display="inline" fontWeight="600">Update Speaker</Box>
				</Typography>
				</Box>

				
				<form role="form" >
				<Grid container spacing={2}>
								
								<Grid item xs={12} sm={2}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel>Title</InputLabel>
									<Select name="title"
									onChange={event=>this.setState({title:event.target.value})}
									label="Titles"
									value={this.state.title}
									>
									<MenuItem value={false}>No title</MenuItem>
									<MenuItem value={"Dr."}>Dr.</MenuItem>
									<MenuItem value={"Prof."}>Prof.</MenuItem>
									<MenuItem value={'Dr. Prof'}>Dr. Prof </MenuItem>
									</Select>
								</FormControl>
								</Grid>
								
								 
								<Grid item xs={12} sm={5}>
									<TextField label="First Name" color="primary" variant="outlined"  name="fname" type="text"  onChange={this.handleChange} 
									error={ errors.fname!="Initalize" ? errors.fname: ''}
									value={this.state.fname || ''} 
									fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.fname!="Initalize" ? errors.fname: ''}
									</Typography>
									</Box>
								</Grid>
								
								<Grid item xs={12} sm={5}>	
									<TextField  label="Last Name" color="primary" variant="outlined"  name="lname" type="text"  onChange={this.handleChange} 
									error={ errors.lname!="Initalize" ? errors.lname: ''}
									value={this.state.lname || ''}   fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.lname!="Initalize" ? errors.lname: ''}
									</Typography>
									</Box>
								</Grid>
														
								
								
								
								<Grid item xs={12}>	
								<TextField  label="Email Address" name="email" type="email" color="primary" variant="outlined" type="text"  onChange={this.handleChange}
								error={ errors.email!="Initalize" ? errors.email: ''}
								value={this.state.email || ''}  InputProps={{ readOnly:location=="/editprofile"?true:false, }} fullWidth />
								<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.email!="Initalize" ? errors.email: ''}
									</Typography>
									</Box>
								</Grid>	
								
								<Grid item xs={12} sm={6}>
									<TextField label="Description 1 " color="primary" variant="outlined"  name="desc1" type="text"  onChange={this.handleChange} 
									error={ errors.desc1!="Initalize" ? errors.desc1: ''}
									value={this.state.desc1 || ''} fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.desc1!="Initalize" ? errors.desc1: ''}
									</Typography>
									</Box>
								</Grid>
								
								<Grid item xs={12} sm={6}>	
									<TextField  label="Description 2" color="primary" variant="outlined"  name="desc2" type="text"  onChange={this.handleChange} 
									error={ errors.desc2!="Initalize" ? errors.desc2: ''}
									value={this.state.desc2 || ''}   fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.desc2!="Initalize" ? errors.desc2: ''}
									</Typography>
									</Box>
								</Grid>
									
								
								<Grid item xs={12}>
								<Box display="flex" flexDirection="row-reverse"   marginBottom={2} >
								
								<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} 
								
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>{
                                      this.state.operation=='add'?'Add Speaker':'Update Speaker'
                                  }</span>}
								</Button>
								
								</Box>
								</Grid>
								
								
							
							</Grid>
					</form>
				
				
			 </CardContent>
			 </Card>
			 </Box>

		</Grid>
         
       <Grid  xs={12} >
		{this.state.operation!='add'?
        
                        <ImageUpload 
                            id={this.state.speaker_id}
                            table="speakers"
                            route={speakers_path}
                            img={this.state.speakerImg}
                            upload_for={'speakers'}
                            keys={'speaker'}
                            />
        :''}
       </Grid>
       
       <Grid xs={12}>
       <SpeakersList parentCallback={this.handleCallback}/>
       </Grid>
        
        
		  
		</Grid>
	
            
            
	    );
	
  	}
}
export default CourseBlock;
