import { Box,Typography , TextField, Button, FormControlLabel, Radio, RadioGroup, CircularProgress, Grid } from '@material-ui/core';
import AlertBox from "../../functions/AlertBox";
import {api} from "../../functions/Helper";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import cookie from 'react-cookies';

const ConfigForm = ({ project, module, component,parentCallback }) => {
  const [enable, setEnable] = useState(false);
  const [caching, setCaching] = useState(false);
  const [frontend_caching, setfrontend_caching] = useState(false);
  const [backend_caching, setbackend_caching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [operationStatus, setOperationStatus] = useState(null);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    setEnable(false);
    setCaching(false);
    setfrontend_caching(false);
    setbackend_caching(false);
    setEnable(false);
	let payload = {
      project: project,
      module: module,
      component: component
    };
    axios
      .post(api + '/get_configuration', payload)
      .then((response) => {
        const data = response.data;
        console.log(data);
		if(data)
        {
			setEnable(data.enable);
			setCaching(data.caching);
			setfrontend_caching(data.frontend_caching);
			setbackend_caching(data.backend_caching);
			parentCallback(data)
		}
		else{
			console.log("no default config after db call")
		}
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  }, [project, module, component]);

  const handleCache = (e) => {
	  setCaching(e.target.value === 'true')
	  if(e.target.value === 'false')
	  {
		  setbackend_caching(false)
		  setfrontend_caching(false)
	  }
  }
  	  const getConfiguration = async () => {
	  try {
		const response = await axios.post('/api/get_configuration', { action: 'fetch' });
		const data = response.data;
		 //console.log(data);

		if (typeof data === 'string') {
		  try {
			const jsonData = JSON.parse(data);
			console.log('Data is a string and has been converted to JSON:', jsonData);
			//const encryptedData = CryptoJS.AES.encrypt(jsonData, 'ac-key').toString();
		//	sessionStorage.setItem('config', encryptedData);
			cookie.save('config', jsonData, { path: '/' });
			console.log(jsonData);
			cookie.load('config');
		  } catch (error) {
			console.error('Failed to parse data as JSON:', error);
		  }
		} else {
		  console.log('Data is not a string');
		}
	  } catch (error) {
		console.error(error);
	  }
	};

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setOperationStatus(null);
    setMsg('');

    const formData = {
      project,
      module,
      component,
      'key':module+"-"+component,
      enable: enable ? 1 : 0,
      caching: caching ? 1 : 0,
      frontend_caching: frontend_caching ? 1 : 0,
      backend_caching: backend_caching ? 1 : 0
    };

    axios
      .post(api + '/manage_configuration', formData)
      .then((response) => {
        console.log(formData);
        console.log(response.data);
        setOperationStatus(response.data['status']);
        setMsg(response.data['msg']);
      })
      .catch((error) => {
        console.error(error);
        setOperationStatus('error');
        setMsg('Save operation failed.');
		getConfiguration();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const TitleCard = ({ text }) => {
    return (
      <Box
        marginBottom={-0.5}
        fontWeight={700}
        color={"#1A2027"}
        fontSize={"1rem"}
      >
        {text}
      </Box>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
	   {msg && 
		    <AlertBox  
			show_status={true} 
			msg={msg}
			msg_type={operationStatus==="success"?"success":"warning"} />
		}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TextField
            color="primary"
            variant="outlined"
            type="text"
            label="Project"
            value={project}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            color="primary"
            variant="outlined"
            type="text"
            label="Module"
            value={module}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            color="primary"
            variant="outlined"
            type="text"
            label="Component"
            value={component}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TitleCard text="Enable Module" />
          <RadioGroup
            row
            aria-label="Enable"
            name="enable"
            value={enable ? 'true' : 'false'}
            onChange={(e) => setEnable(e.target.value === 'true')}
          >
            <FormControlLabel value="false" control={<Radio />} label="Disable" />
            <FormControlLabel value="true" control={<Radio />} label="Enable" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TitleCard text="Caching" />
          <RadioGroup
            row
            aria-label="Caching"
            name="caching"
            value={caching ? 'true' : 'false'}
            onChange={handleCache}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No Caching"
            />
            <FormControlLabel value="true" control={<Radio />} label="Caching" />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TitleCard text="Frontend Caching" />
          <RadioGroup
            row
            aria-label="Frontend Caching"
            name="frontend_caching"
            value={frontend_caching ? 'true' : 'false'}
            onChange={(e) => setfrontend_caching(e.target.value === 'true')}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No Frontend Caching"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Frontend Caching"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TitleCard text="Backend Caching" />
          <RadioGroup
            row
            aria-label="Backend Caching"
            name="backend_caching"
            value={backend_caching ? 'true' : 'false'}
            onChange={(e) => setbackend_caching(e.target.value === 'true')}
          >
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="No Backend Caching"
            />
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Backend Caching"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Update Configuration'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default ConfigForm;
