
import {
	Box, Button, Card, CardContent, CardHeader, Grid, Typography
} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../AlertBox";
import { getCacheDetails } from "../caching";
import { api, translateText } from "../Helper";
import { useStyles } from "../useStyles";
import QuizRender from "./quizfun/QuizRender";
import { useHistory,useLocation } from 'react-router-dom';

//let student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
//let status=JSON.parse(localStorage.getItem('ac_login_user'))['status']

let student_id=null
let status=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}
class QuizrWelCard extends Component {

  constructor(props) {
    super(props);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.translateText = translateText.bind(this);
	this.state={attempted:0,msg:false,render:false,course_id:0,
	limit_attempts:this.init_state('quiz_attempts'),
	quiz_timer:this.init_state('quiz_timer')
	}
    }

	renderMsg = () => {
		this.setState({msg:"Attempt level reached"})
		setTimeout(() => {this.setState({msg:false})}, 1000);
    }
	renderQuiz = () => {
		this.setState({render:true})
		//alert(this.state.render)
    }
	checkattemptLeft = () => {
		//alert(localStorage.getItem('student_id'))
		//alert(localStorage.getItem('quiz_id'))
		let param={
		'student_id':student_id,
		'quiz_id':this.init_state('quiz_id')
		}
		// //console.log(param)
		axios.post(api+'/getQuizAttempt', param).then((response) => {
			//alert(response.data['attempt'])
		this.setState({attempted:response.data['attempt']})
		this.setState({course_id:this.init_state('course_quiz_id')})
		// // //console.log(response.data)
		});
		//alert(response.data['attempt'])

    }
    componentDidMount()
	{
		if(status=='student')
		{
			this.checkattemptLeft();
		}
		
		// //console.log(this.props.location.state)
		//alert("hiii")

		
	}
	
	handleCall=()=>{
		//alert("in welcom")
		if(window.location.pathname=="/course-modules")
		{
			//this.props.parentCallback();
		}
	}
	init_state=(variable)=>{
		
		let k=null
		if((window.location.pathname).startsWith("/quiz")==true)
		{
			if(this.props.location.state==null)
			{
				k=null;
			
			}
			else 
			{
				k=this.props.location.state[variable]
				// //console.log(k);
				
			}
		}
		else
		{
			k=this.props.quiz_data[variable]
		}
		return k;
	}
	
	render()
  	{
		
		
		const ButtonView = () =>
		{	
			let if_disabled=false
			if(this.state.limit_attempts-parseInt(this.state.attempted)==0 || 
			new Date()>new Date(this.state.ends_on))
			{
				if_disabled=true
			}
			return (
			<Button color="primary" size="medium" 
				variant={if_disabled?"contained":"outlined"}
				onClick={()=>{
				this.state.attempted==this.state.limit_attempts
				?this.renderMsg()
				:this.renderQuiz()
				}}
				disabled={if_disabled}>
				{if_disabled?new Date()>new Date(this.state.ends_on)?"Quiz expired.":"Attempt Reached":"Start Now"}
			</Button>
			)
		}
		const InnerCard = () =>
		{

				
			let quizQuestions=[];
			quizQuestions = this.init_state('quizQuestions');
			quizQuestions = JSON.parse(quizQuestions)
			// //console.log(quizQuestions);		
			return (
			
		
			<Grid container>
			
			  <Grid item xs={12} sm={10}>
			 	 <Box paddingRight={2}>
				 <Typography variant="subtitle1">
				 {[undefined,"null","",null].includes(this.init_state('quiz_de'))?"":this.init_state('quiz_de')}
				</Typography>
				</Box>
			  </Grid>
			  
			  <Grid item xs={12} sm={2}>
				<ButtonView />
			  </Grid>
			  
			  <Grid item xs={12} >
				  <Card elevation={0}>
				  <CardContent>
				 
							
					<Typography component="div" variant="subtitle1" color="textSecondary">
					<Box  variant="subtitle1"  color="text.primary" fontWeight="fontWeightBold" lineHeight={1.2}>
					Attempts
					</Box>
					{status=='professor'?"Any no. of time":this.state.limit_attempts+" every 24 hours"}
					</Typography>
					
					<Box marginTop={2}>
					</Box>

					<Box  display={status=='professor'?"none":""}>
					<Typography component="div" variant="subtitle1" color="textSecondary">
					<Box
					variant="subtitle1"  color="text.primary" fontWeight="fontWeightBold" lineHeight={1.2}>
					Attempt left 
					</Box>
					{this.state.limit_attempts-parseInt(this.state.attempted)} 
					</Typography>
					</Box>
			
			
	
					<Box marginTop={2}>
					</Box>


					
					<Typography component="div" variant="subtitle1" color="textSecondary">
					<Box  variant="subtitle1"  color="text.primary" fontWeight="fontWeightBold" lineHeight={1.2}>
					Receive grade
					</Box>
					 To pass 70% of higher (A)	
					</Typography>
				 	
					
					<Box marginTop={2}>
					</Box>
				 
					
					<Typography component="div" variant="subtitle1" color="textSecondary">
					<Box  variant="subtitle1"  color="text.primary" fontWeight="fontWeightBold" lineHeight={1.2}>
					Quiz duration
					</Box>
					{(this.init_state('quiz_timer'))+" Minutes"}
					</Typography>
					
					<Box marginTop={2}>
					</Box>

					
					<Typography component="div" variant="subtitle1" color="textSecondary">
					<Box  variant="subtitle1"  color="text.primary" fontWeight="fontWeightBold" lineHeight={1.2}>
					Expires on 
					</Box>
					{this.init_state('ends_on')}
					</Typography>
		
				 	
					<Box marginTop={2}>
					</Box>
				 
				  </CardContent>
				  </Card>
			  </Grid>
				
			
			
			</Grid>


			)
			}
			
		
		const WelCard = () =>
		{

				
			let quizQuestions=[];
			quizQuestions = this.init_state('quizQuestions');
			quizQuestions = JSON.parse(quizQuestions)
			// //console.log(quizQuestions);		
			return (
			
		
			<Card   style={{height: '100%'}}>
			{this.state.msg!=false?
			<AlertBox show_status={true} msg={this.state.msg} msg_type={"warning"} />
			: ''}
			
			<CardHeader title={ this.init_state('quiz_name')} />
			
			<CardContent >
			<InnerCard />
			</CardContent>
			</Card>
			


			)
			}
			
			
			return (
			<>
			{
				this.state.render==false 
				?
					(window.location.pathname).startsWith("/quiz")==true
					?<WelCard />
					:<InnerCard/>
				:<QuizRender quiz_data={this.props.quiz_data}  parentCallback={this.handleCall} />
			}
			</>
			);
  	}
}


export default (props) => {
	const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    return (
        <QuizrWelCard 
		classes={classes}  
		parentCallback={props.parentCallback}  
		quiz_data={props.quiz_data}  
		history={history}  
		location={location} />
    )
}

