import React, { Component, Suspense } from 'react';
import {
  Box,CircularProgress
} from '@material-ui/core';

let  Homepage_Old = React.lazy(() => import('./homepage_old/Homepage_Old'));
let  Homepage = React.lazy(() => import('./index/Homepage'));
let  Admin = React.lazy(() => import('../../../admin/users/WebinarRegistration'));
let  WebinarWaiting = React.lazy(() => import('../../../webinar/waiting'));
let  Dashboard = React.lazy(() => import('../../dashboard'));
let  OG_Homepage = React.lazy(() => import('./og'));


class Logs extends Component {

	
  	render()
  	{
	
	const LoadingComponent = () => {
		  return (
			<Box display="flex" height="100vh" justifyContent="center" alignItems="center">
			  <CircularProgress size={84} thickness={3} />
			  <Box
				position="absolute"
				display="flex"
				justifyContent="center"
				alignItems="center"
				width="100%"
				height="100vh"
				top={0}
				left={0}
			  >
			  {/* <Typography variant="h6" color="textSecondary">
				  Loading...
				</Typography>
			  */}
			  </Box>
			</Box>
		  );
		};
	
		return ( 
		
		<>
         
         {
             
			 [null].includes(localStorage.getItem('login_token'))==true
			 ?
				window.location.pathname=="/hompage-old"
				?
				   <div className="row">
				   <Suspense fallback={<div> </div>}><Homepage_Old/>  </Suspense> 
				   </div>
				:
					 window.location.pathname=="/og-home"
					?
					   <div className="row">
					   <Suspense fallback={<div> </div>}><OG_Homepage/>  </Suspense> 
					   </div>
					:
						 <div className="row">
						 <Suspense fallback={<div>  <LoadingComponent /> </div>}><Homepage/>  </Suspense> 
						 </div>
			 :
			 
				["admin"].includes(localStorage.getItem('login_token'))==true
				? <Suspense fallback={<div> </div>}><Admin/>  </Suspense>
					
					:["webinar"].includes(localStorage.getItem('login_token'))==true
						?<Suspense fallback={<div> </div>}><WebinarWaiting/>  </Suspense>
						:<Suspense fallback={<div> </div>}><Dashboard/>  </Suspense>
         }

		

		</>
		 );
  	}
}

export default Logs;
  