import {
	Avatar, Box, Card, CardContent, CardHeader, Chip, CircularProgress, Grid,
	List, ListItem, ListItemAvatar, ListItemText, Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import createActivityDetector from "activity-detector";
import axios from 'axios';
import clsx from 'clsx';

import React, { useEffect, useRef, useState,useContext } from 'react';
import Autolink from 'react-native-autolink';
import { api, userProfile_path } from "../Helper";
import ConnectionStatus from "./ConnectionSatus";
import MessagesForm from './MessagesForm';
import { chatStyle } from './styles/chatStyle';
import { UserContext } from '../courses/UserContext';

const activityDetector = createActivityDetector({ timeToIdle: 60000 });

var sender_email='';
var sender_img='';
var sender_name='';
var sender_status='';
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	sender_status=JSON.parse(localStorage.getItem('ac_login_user'))['status'];
	sender_email=JSON.parse(localStorage.getItem('ac_login_user'))['email'];
	sender_name=JSON.parse(localStorage.getItem('ac_login_user'))['fname'];
	sender_img=JSON.parse(localStorage.getItem('ac_login_user'))['img']
}
export default function App({receiver_info,parentCallback}) {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState(false);
  const chats = React.useRef([]);

  
	const [is_active, set_is_active] = useState(true);
	const [data_list, set_data_list] = useState([]);
	const [len, set_len] = useState(0);
	const [, forceUpdate] = useState(0);
	const classes = chatStyle();
	
	const [mounted, setMounted] = useState(false)
	const messagesEndRef = useRef(null)
	const context_ref = useContext(UserContext);

	const [msg_type, set_msg_type] = useState("success");
 
	  
	const showd = () =>
    {
		var chat_id=receiver_info['chat_id']
		axios.post(api+'/ac_getMsg',{'chat_id':chat_id,start:0,end:5})
		.then((response) => {
			//console.log("------------- fetching messages from databse once  --------------- ")
			////console.log(response.data);
			
			let k=(response.data).reverse()
			chats.current=k;
			//console.log(chats.current)
			set_data_list(k);
			set_len(k.length);
			scrollToBottom();
			//forceUpdate(n => !n)
		});
	}
  
	  
	const fetch_prev_msg = () =>
    {
		var chat_id=receiver_info['chat_id']
		let start=(data_list).length
		axios.post(api+'/ac_getMsg',{'chat_id':chat_id,start:start,end:5})
		.then((response) => {
			//console.log("------------- fetching top messages from databse on top scroll  --------------- ")
			
			let res=(response.data).reverse()
			let da=data_list
			let r=res.concat(da);
			//console.log(r)
			
			set_data_list(r);
			chats.current=r;

			//console.log("data - "+data_list.length)
			set_len(r.length);
			forceUpdate(n => !n)
			
			//scrollToBottom();
			//forceUpdate(n => !n)
		});
	}
  
    useEffect(() => {
		//console.clear();
		showd();
		
		if((context_ref.socket_info)!=null)
		{
			(context_ref.socket_info).emit('join', { 'user':sender_email,"room":"active_user"});
			(context_ref.socket_info).on("join", (data) => {
				//console.log(data);
			});
		}
		
		
    }, [receiver_info]);



	
    useEffect(() => {
			//console.log("------------- socket chat messages fetched from server  --------------- ");
			////console.log(socket)
			////console.log(" socket id "+socket.id)
			(context_ref.socket_info).on("data", (data) => {
			let k=chats.current
			//console.log("length is "+k.length)
			//console.log(data)
			k=[...k, data.data];
			chats.current=k;
			
			set_data_list(k);
			//console.log(chats.current)
			scrollToBottom();
			});
			
		
			
		//socket.on("log_active_user", (data) => {//console.log(data)});
		return () => {
		  (context_ref.socket_info).off("data", () => {console.log("data event was removed");});
		};
    }, [context_ref.socket_info]);
	
 
	
	
	const handleParent = (payload) =>
    {
        /*
		
		// alert("vbgf")
		//console.log(payload)
		save(payload);
		socket.emit(payload['chat']);
		scrollToBottom(); 
		*/
		parentCallback();
		
    }   
	 
	const scrollToBottom1 = () =>
	{
		/*if(data_list!=null)
		{
			if(this.el!=null && isMobile==false)
			{	
				 // alert("inside")
				this.el.scrollIntoView({ behavior: 'smooth' });
			}
		}*/
	}
	
	  
	const listInnerRef = useRef();
	const handleScroll = () => {
		if (listInnerRef.current) {
		  const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
		  ////console.log("scrollTop "+scrollTop+" ------ clientHeight "+clientHeight+"----------scrollHeight "+scrollHeight)
		  if (scrollTop + clientHeight === scrollHeight) {console.log('Reached bottom')}
		  if (scrollTop  === 0) {console.log('Reached top');fetch_prev_msg();}
		}
		//alert("1")
	  };
 
	  const scrollToBottom = () => {
		setTimeout(() => {
			messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
		}, 500);
	  }
	const get_msg_user=(db_sender__email,data)=>{
		
		let user="receiver"
		let k='';
		if(db_sender__email==sender_email)
		{
			user="sender"
		}	
		
		if(user=="sender")
		{
			k=sender_name
			if(data=="img")
			{
				k=sender_img
			}
		}
		else
		{
			k=receiver_info['name']
			if(data=="img")
			{
				k=receiver_info['img']
			}
		}
		return k;
			
	}
	
	
      
        const Message = ({text}) =>
        {return (
           <Autolink
            style={{color:"#637381"}}
            text={text}
            email
            hashtag="instagram"
            mention="twitter"
            phone="sms"
            url
          />
        )}
        
          
        
        const ChatLists = ({chats}) =>
        {
			let arr=chats;
			return (
			
            <List>
			
			 {	
				arr.length>0 && arr.map((e, index) => (
				
                
                <>
				{
					receiver_info['chat_id']==e['chat_id']					
					?
						e['sender']==sender_email					
						?
					
							<ListItem alignItems="flex-reverse" 
							style={{padding:"0px"}}>
							
							<ListItemText
							style={{marginLeft:'0px',marginTop:'0px',marginBottom:'0px',display:'flex',justifyContent:'end'}}
							primary={
								
							<Box className={classes.reply}>
							<Typography color="textPrimary" variant="caption">
								<Box display="flex">
								<Box lineHeight={1.1}  
								marginRight={1}
								color="text.secondary"> 
								{e['added_on']+" ago"} 
								</Box>
								
							
							<Box flexGrow={1} display="block">
							<Box lineHeight={1.1}  textAlign="right" className={classes.title} fontWeight="bold"  > 
							{get_msg_user(e['sender'],"name")} 
							</Box>
							<Box marginTop={1} lineHeight={1.6} fontSize="caption.fontSize" color="text.secondary"
							 textAlign="right" > 
							<Message text={e['chat'] } />
							</Box>
							</Box>
							
						   
						</Box>
						</Typography>	
						</Box>					 
							}/>
							
							<ListItemAvatar
							className={classes.avatar}>
							<Box margin={1}> <Avatar  style={{height:'35px',width:'35px'}} src={userProfile_path+get_msg_user(e['sender'],"img")} /></Box>
							</ListItemAvatar>
							
							</ListItem>
					:
					
				
						<ListItem alignItems="flex-start"  style={{padding:'0px'}}>
						<ListItemAvatar
						className={classes.avatar}>
						<Avatar  style={{height:'35px',width:'35px'}} src={userProfile_path+get_msg_user(e['sender'],"img")} />
						</ListItemAvatar>
						<ListItemText
						  primary={
							<Box  padding={1} 
							className={data_list.length-index<=100?
							clsx(classes.question,classes.unread):
							clsx(classes.question,classes.read)}>
							 <Typography color="textPrimary" variant="caption">
							<Box display="flex">
								<Box lineHeight={1.1} flexGrow={1} className={classes.title} fontWeight="bold" > 
								{get_msg_user(e['sender'],"name")} 
								</Box>
								<Box marginLeft={1} lineHeight={1.1} color="text.secondary" > 
								{e['added_on']+" ago"} 
								</Box>
							</Box>
							<Box marginTop={1} lineHeight={1.6} fontSize="caption.fontSize" color="text.secondary"> 
							<Message text={e['chat'] } /> </Box>
							 </Typography>
							
							</Box>
							}
						/>
						</ListItem>

					:""
				}
			  </>
			))}
			
             <ListItem>
			 <ListItemText primary={<div 
			 style={{ float:"left", clear: "both" }}
			  ref={messagesEndRef} ></div>} />
			</ListItem>
			
			</List>

        )}
        
    	const ReceiverData=()=>{
		return(
		     
						 <Box 
						 paddingBottom={0}
							marginTop={2}
						 display="flex">
						 
						 
						 <Box marginRight={2}>
						 <Avatar 
						style={{height:'50px',width:'50px'}}
						src={userProfile_path+receiver_info['img']} />
						 </Box>
						 
						 
						 {/* borderBottom="1.5px solid #13171f21"*/}
						<Box>
						<Typography 
						color="textPrimary"
						variant="h6">
						<Box lineHeight={1.5}
						className={classes.title} > 
						{receiver_info['name']} 
						</Box>
						</Typography>
						
						 
						  
						 <Typography>
						 <Box lineHeight={1.25} fontSize="caption.fontSize" color="text.secondary">{receiver_info['user_is']} </Box>
						</Typography>
						 </Box>
						
							
						<Box>
						<ArrowRightIcon fontSize="large" />
						</Box>
						</Box>
		)
	}
	let col=is_active==true ? "#39BF6F" : "#ff9800"
	return (
 	<React.Fragment>
	<ConnectionStatus  user={receiver_info} 	
	parentCallback={()=>{console.log("in list callback")}}
	/>
	
	
      <Card elevation={3} >
		<CardHeader title={<ReceiverData />}/>

		<CardContent className={classes.card} 
		style={{backgroundColor:"rgb(253 253 254)"}}>
		<Grid container >
			
				
                 <Grid  item xs={12}>
		
					{/* list data */}
						<Grid container spacing={0} >
						<Box display="none">
						<Chip
							avatar={<FiberManualRecordIcon style={{color:col,height:'1.1rem'}} />}
							label={is_active==true ? 'Active' : 'Idle'}
							clickable
							variant="outlined"
						/>
						</Box>
				
				{/*
				style={(this.receiver_info).hasOwnProperty('session')?{height:"53vh"}:{height:"50vh"}}
				*/}
							<Grid item xs={12}
							style={{height:'55vh'}}
							 className={classes.scrollroot} id="scrollDiv" 
							  onScroll={handleScroll} ref={listInnerRef}>
							 {
								 
								 len==null?
									<Box marginTop={2} marginBottom={2} sx={{ alignItems: 'center', display: 'flex',flexDirection: 'column'}}>
									 <CircularProgress color="primary" size={24} />
									</Box>
								:<ChatLists chats={data_list} />
							 }
							</Grid>
							
							
							<Grid item xs={12}
							style={{height:'7vh'}}>
							
							
							{
								is_active==true 
								?
									<MessagesForm 
									receiver_info={receiver_info}
									parentCallback={handleParent}
									/>
																		 
								:
									<Box marginTop={1} >
									<Card style={{ backgroundColor:"#f3f6f9"}}>
									<Box padding={1}>
									<Typography variant="subtitle2" color="textPrimary" align="center">
									You are offline.		
									</Typography>
									</Box>
									</Card>
									</Box>
							}
							</Grid>
					</Grid>
					
			</Grid>
		</Grid>
		</CardContent>
		</Card>
	 </React.Fragment>

  );
}
