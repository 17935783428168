import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ManageSignIn from './signin';
import ManageSignUp from './signup';
import ManageForgot from './forgot-password';
import ManageBlogs from './blogs';
import ManageCommunity from './community';
import ManageCaptcha from './captcha';
import ManageOTP from './otp';
import ManageCourses from './courses';
import ManageMentorship from './mentorship';
import ManageMessages from './messages';
import ManageWebinar from './webinar';


const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/manage-signin-module" component={ManageSignIn} />
        <Route path="/manage-signup-module" component={ManageSignUp} />
        <Route path="/manage-forgot-password-module" component={ManageForgot} />
        <Route path="/manage-otp-module" component={ManageOTP} />
        <Route path="/manage-captcha-module" component={ManageCaptcha} />
        <Route path="/manage-blogs-module" component={ManageBlogs} />
        <Route path="/manage-community-module" component={ManageCommunity} />
        <Route path="/manage-course-module" component={ManageCourses} />
        <Route path="/manage-mentorship-module" component={ManageMentorship} />
        <Route path="/manage-messages-module" component={ManageMessages} />
        <Route path="/manage-webinar-module" component={ManageWebinar} />
      </Switch>
    </Router>
  );
};

export default App;
