
import {
	Box, Button, Card, CardContent, CardHeader, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Grid,
	TextField, Typography
} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AlertBox from "../AlertBox";
import ImageUpload from "../ImageUpload";
import { deleteLocal,getKeys } from "../caching";
import { api, university_path, translateText } from "../Helper";
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';




class UniversityAdd extends Component {

    constructor(props) {
    super(props);
	this.translateText = translateText.bind(this);
    this.deleteLocal = deleteLocal.bind(this);
    this.getKeys = getKeys.bind(this);
	
	this.state={
		university_name:this.init_state('university_name'),
	    university_id:this.init_state('university_id'),
	    university_logo:this.init_state('university_logo'),
		university_de:this.init_state('university_de'),
		open:'',logoDetails:false,
		loading:false,disable:false,
		fileType:'image',upload:false,
        keys:false,
        
		university_data:false,
		msg:false,msg_type:'success',
		img_msg:false,img_msg_type:'success',
		deletePop:false,
		errors: {university_name:"Initalize",university_de:"Initalize"}
		}
    }
		
	
	init_state=(variable)=>{
		
		if(window.location.pathname=='/university/add')
        {
			return null;
        
        }
		else 
		{
			let k=this.props.location.state[variable]
			return k;
		}
	}
	
	deleteKeys=()=>{
		
		    let u_id_keys=this.deleteLocal(this.state.university_id+"_")
		    let university_keys=this.deleteLocal("university_")
			let all_keys=this.deleteLocal("all_university_")		
			let keys=[all_keys,university_keys,u_id_keys]
			return keys.toString()
			
			
	}
	
	insertIntoDatabase =  (dataObject) =>  {
		var indexedDBOpenRequest = window.indexedDB.open('uni-sync',  1)
		indexedDBOpenRequest.onupgradeneeded = function () {
			this.result.createObjectStore('coupon_requests', {
			autoIncrement:  true })
		}

		indexedDBOpenRequest.onsuccess = function () {
			let db = this.result
			let transaction = db.transaction("coupon_requests", "readwrite");
			let storeObj = transaction.objectStore("coupon_requests");
			storeObj.add(dataObject);
		}
		indexedDBOpenRequest.onerror = function (error) {
			console.error('IndexedDB error:', error)
		}
	  }
	  
	handleChecked = () => {this.setState({checkedA: !this.state.checkedA})}
	handleChange = event => {event.preventDefault();
	let errors = this.state.errors;
	const { name, value } = event.target;
	errors[name]=value==null|| value==''?"Please fill out this field.":"Initalize";
	this.state[name]=value;
	let min=10
	let max=70
	if(name=="university_de")
	{
		min=10
		max=500
	}
	errors[name]= value.length<min || value.length>max ?" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters.":"Initalize"	
	this.setState({ errors, [name]: value });
	};
	
		
	checkError=(name,value)=>
    {
		if(["Initalize",undefined].includes(this.state.errors[name]))
		{
			let val =[null,''].includes(value)?"Please fill out this field.":"Initalize";
			this.state.errors[name]=val
		}
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}			
	}
	validForm=(list)=>
	{
		let allFilled=false;
		for (let i = 0; i < list.length; i++)
		{
			let name=list[i];
			allFilled=this.checkError(name,this.state[name]);
			this.forceUpdate();
			if (allFilled===false)
			{
				break;
			}
		}
		return allFilled
	}
	handleClose()
	{
		setTimeout(() => {
			this.setState({ deletePop: false});
		}, 1000);
	}
	

  handleSubmit = (e) =>
   {
	   
	   
		e.preventDefault();			

		let isValidate=this.validForm(['university_name','university_de'])
		
		if(isValidate==true)
		{	
			
			let payload = {
				'university_name': this.state.university_name,
				'university_de': this.state.university_de
			}
			if(window.location.pathname!=="/university/add")
			{	
				payload.operation='update';
				payload.university_id=this.state.university_id;
				payload.keys=this.deleteLocal(this.props.location.state['keys'])

			}
			else{
				
				payload.operation='insert';
				payload.keys=this.deleteKeys()

			}
			this.setState({addStatus:'' });
			if(!navigator.onLine){
			// if(1>2){
					
					//console.log("University Offline")
					serviceWorkerRegistration.registerSync(); // register background sync request with tag name order
					this.insertIntoDatabase(payload); // insert data into indexedb
					if ((this.state.university_data).length==0)
					{
						this.setState({ university_data: payload});

					}	
					else 
					{
						
						this.setState({ university_data: [...this.state.university_data, payload]});
					}
					this.state.loading=false;
					this.setState({msg:"You are offline! University IndexDB Updated",loading:false})
		     }
			else 
			{
			
				this.state.loading=true;
				//console.log("University  Online")	
				axios.post(api+'/addUpDeUniversity',payload)
				.then((response) => {
				//console.log("this is response");
				//console.log(response.data);
				this.state.loading=false;
				this.setState({msg:response.data['msg']})
				this.setState({msg_type:response.data['type']})
				
				});
		   
				setTimeout(() => {
				this.setState({ msg: false});
				this.props.history.goBack();
				}, 3000);	
			
			}
		}

	}
	
	handleClose = () => {
		this.setState({ deletePop: false});
	}
	handleDelete = () => {
		
		let keys=this.deleteLocal(this.props.location.state['keys'])
		//alert(" del this "+keys)
		
		let payload = {
		'university_id': this.state.university_id,
		'operation':'delete',
		'keys':keys
		}
		axios.post(api+'/addUpDeUniversity',payload)
		.then((response) => {
		this.setState({ deletePop: false});
		
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
		setTimeout(() => {
			localStorage.removeItem ('university_logo')
            this.props.history.goBack();
            }, 3000);
		});
	}
	componentDidMount()
	{
		//alert("oooo " +this.props.location.state['keys'])
	}
	
  	render()
  	{
		
		const { errors } = this.state;
        
			const DeletePopup = () =>
			{

			return(
			<Dialog
			open={this.state.deletePop}
			onClose={this.handleClose} 
			fullWidth 
			maxWidth="xs">
			
			<DialogTitle >
			<Typography variant="h6" color="textPrimary">Are you sure ?</Typography>
			</DialogTitle>
			
			<DialogContent >
			  <DialogContentText >
			  	<Typography variant="subtitle1" color="textSecondary"  >
				Do you really want to delete these records? 
				This process cannot be undone.
			  </Typography>
			  </DialogContentText>
			</DialogContent>
			
			
			<DialogActions >
			  <Button  onClick={ this.handleDelete}  color="primary"  size="large">Delete </Button>
			  <Button onClick={ this.handleClose}   color="primary" size="large">Cancel</Button>
			</DialogActions>
			</Dialog>
			
			
			)
			
		}


		
		return (
		  
           <Grid container spacing={2}>
                   { 	
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type} />
					: ''	
		          }	 
		
		
			<Grid item xs={12} >	
			<Card style={{height:'calc(100vh - 128px)'}}>
				 <CardHeader title={window.location.pathname!=="/university/add"?"Update University":"Add University "} />
				<CardContent>
				<form role="form">
						
						<Grid container spacing={2}>
						
						<Grid item xs={12}>
						<TextField   label="University Name" color="primary" variant="outlined"  name="university_name" type="text" onChange={this.handleChange} error={ errors.university_name!="Initalize" ? errors.university_name: ''} 
						value={this.state.university_name}
						fullWidth/>
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.university_name!="Initalize" ? errors.university_name: ''}
						</Typography>
						</Box>
						</Grid>

						<Grid item xs={12}>
						<TextField error={ errors.university_de!="Initalize" ? errors.university_de:''} 
						name="university_de"  type="text"  label="University Description" multiline  rows={7}
						variant="outlined" fullWidth 
						onChange={this.handleChange} 
						defaultValue={this.state.university_de}/>
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.university_de!="Initalize" ? errors.university_de: ''}
						</Typography>
						</Box>
						</Grid>

						
						<Grid item xs={12}>
						<Box display="flex" flexDirection="row-reverse" >
						
						{window.location.pathname!='/university/add'?
						<Box marginLeft={1}>
						<Button variant="contained" size="large" color="secondary"  onClick={()=>{this.setState({deletePop:true})}}>
						Delete
						</Button>
						<DeletePopup />
						</Box>
						:''}
						
						<Box>
						<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} fullWidth disabled={this.state.loading}>
						  {this.state.loading && <CircularProgress size={24} />}
						  {!this.state.loading && <span>{window.location.pathname=='/university/add'?"Add University":"Update University"}</span>}
						</Button>
						</Box>
						
						</Box>
						</Grid>
						</Grid>
						
			    	
			      	</form>
					</CardContent>
					</Card>
					</Grid>

                    <Grid item xs={12}>	
                        {
                            window.location.pathname!=="/university/add"
                            ? <ImageUpload 
                            id={this.state.university_id}
                            table="university"
                            route={university_path}
                            img={this.state.university_logo}
                            upload_for={'university'}
                            keys={this.deleteLocal(this.props.location.state['keys'])}
                            />
                            :''
                        }
					</Grid>
				</Grid>

            
	    );
  	}
}

 export default () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <UniversityAdd  location={location}  history={history} />
    )
} 