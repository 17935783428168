import DateFnsUtils from '@date-io/date-fns';
import { FormControl, InputLabel, Select, MenuItem ,Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import "react-multi-carousel/lib/styles.css";
import { useHistory } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { deleteLocal } from "../../caching";
import { convertDate } from "../../Helper";







let mentor_id_lc=null
let professor_id=null
let email=null
let status=null
if(["admin",null].includes(localStorage.getItem('login_token'))==false)
{
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	professor_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	if(status=='professor')
	{
		mentor_id_lc=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_id']
	}	
	//alert(mentor_id_lc)
}

 const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items:4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const useStyles = makeStyles(theme => ({
	icon:{fontSize:'0.85rem'},
	iconbtn:{padding:'2px'},
	shadow:{
		border:'0px solid black',
		backgroundColor:"#3453b01a",
		fontWeight:"bold",
		color:"#253B7C",
		'&:focus':{
			
		},
	},
	ic:{padding:'6px'},
	mb:{width:isMobile?'91vw':''}
	
}));

export default function AddSlot(props) {
	
		const classes = useStyles();
		const history = useHistory();
		const [mentor_id, setMentorID] = useState(mentor_id_lc);
		const [loading, setLoading] = useState(false);
		const [msg, setMessage] = useState(false);
		const [msg_type, setMessageType] = useState("success");

		//const [add_btn, addBtn] = useState(props.action=="update"?false:true);
		const [slot_id, setSlotID] = useState(props.action=="update"?props.slot_data['slot_id']:null);
		const [is_update, setUpdate] = useState(props.action=="update"?true:false);
		const [date, changeDate] = useState(props.action=="update"?new Date(props.slot_data['date']):new Date());
		const [time, changeTime] = useState(props.action=="update"?new Date(props.slot_data['date']):new Date());
 
		const [selectedHours, setSelectedHours] = useState(props.action === "update" ? (props.slot_data['duration'].split(':')[0]) : '0');
		const [selectedMinutes, setSelectedMinutes] = useState(props.action === "update" ? (props.slot_data['duration'].split(':')[1]) : '30');

		//const [date, changeDate] = useState(new Date());
		//const [time, changeTime] = useState(new Date());
				
		const deleteKeys=()=>{
			
				let keys_1=deleteLocal(professor_id+"_all_mentors_")
				let keys_2=deleteLocal(professor_id+"_mentors_")
				let keys_4=deleteLocal(professor_id+"_my_mentors_")
				let keys_3=deleteLocal(professor_id+"_upcoming_mentorship_")	
				let keys_5=deleteLocal(professor_id+"_all_mentors_0_to_")		
				let keys_6="user_"+professor_id+"_mentor_slot_"
				let keys_7="mentor"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6,keys_7]
				return keys
		}
	   
	    const process_request=() => {
			
			const getH = time.getHours();
			const getM = time.getMinutes();
			/*
			restrict slot timing
			if([10,11,12,13,14,15,16,17,18].includes(getH)==false)
			{
				setMessage("Kindly schedule session between 10am to 7pm.");
				setMessageType("warning");
				
				setTimeout(() => {
				setMessage(false);
				//localStorage.clear();
				}, 5000);
			}
			else
			{
				add_mentor_slots();
			}
			*/
			add_mentor_slots();
			
		}
		
	    const add_mentor_slots=() => {

			setLoading(true)
			//console.log(date);
			//Wed Jun 22 2022 02:44:34 GMT+0530 (India Standard Time)
			//console.log(time);
		
			const getH = time.getHours();
			const getM = time.getMinutes();
			
			let slot=date.setHours(getH);
			slot=date.setMinutes(getM);
			slot=convertDate(slot);
			
			//console.log(slot);
			let s_id=is_update==true?slot_id:'insert';

			//slot=is_update==true?slot.slice(0,10):slot;
			//alert(slot);

			let key=(deleteKeys()).toString()
			let param={
				'mentor_id': mentor_id,
				'slot_date': slot,
				'slot_time': getH+":"+getM,
				'slot_duration':selectedHours+":"+selectedMinutes,
				'slot_id':s_id,
				'key':key,
			}
			console.log(param)
			axios.post('/api/add_mentors_slot',param)
			.then((response) => 
			{
				
				let data=response.data;
				//console.log(data);
				//alert(mentor_id)
				if(data['msg']=="success")
				{
					if(is_update==true)
					{
						setMessage("Slot updated successfully!");
					}
					else
					{
						setMessage("Slot added successfully!");
					}
					setMessageType("success");
					
				}	
				else
				{
					if(data['info']=="failed")
					{
						setMessage("Try again later!");
					}
					if(data['info']=="mentee")
					{
						let new_mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))
						new_mentor_req['mentor_req']=0
						new_mentor_req['is_mentor']="mentee"
						localStorage.setItem('ac_login_user',JSON.stringify(new_mentor_req));
						
						setMessage("Your experts profile is not activated. Kindly login again.");
						
						setTimeout(() => {
						setMessage(false);
							history.push("/dashboard")
						}, 5000);
						
						
					}
					
					if(data['info']=="full")
					{
						//setMessage("Slot is already added!");
						setMessage("Slots are overlapping!");
					}
					if(data['info']=="overlap")
					{
						setMessage("Slot timing overlap other slot");
					}
					if(data['info']=="old")
					{
						setMessage("Slot time must be defined within the permissible slot.");
					}
					setMessageType("warning");
				}	
				if(is_update==true)
				{
					setUpdate(false);
				}

				setLoading(false)
				setTimeout(() => {
						setMessage(false);
						props.parentCallback();

				}, 3000);
						
			})
		}

	
		const getDisabledHours = () => {
			var hours = [];
			for(var i =0; i < moment().hour(); i++){
				hours.push(i);
			}
			return hours;
		}

		const getDisabledMinutes = (selectedHour) => {
			var minutes= [];
			if (selectedHour === moment().hour()){
				for(var i =0; i < moment().minute(); i++){
					minutes.push(i);
				}
			}
			return minutes;
		}
		const addStr=(str, index, stringToAdd)=>
		{
		  return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
		}	
		
		const get_time=()=>
		{
			let tt=time.getHours()>11?time.getHours()-12:time.getHours()
			let t=tt.toString()
			t=t.length==2?t:("0"+t)
			
			if(t=="00")
			{
				t="12"
			}
			t=t+ ":" 
			
			let m=time.getMinutes().toString()
			m=m.length==2?m:("0"+m)
			
			t=t+m+" "
			return t;
		}	
		
		useEffect(() => {
			//console.log(props.action);
			//console.log(props.slot_data);
			//console.log(time);
			
			if(mentor_id==null)
			{
				get_mentor_id_if_exists();
			}
			//Fri Aug 05 2022 17:22:03 GMT+0530 (India Standard Time)

			/*//console.log(props.slot_data);
			//console.log(props.slot_data['date']);
			//console.log(typeof(props.slot_data['date']));
			//console.log(JSON.stringify(props.slot_data['date']));
			//console.log(JSON.stringify(typeof(props.slot_data['date'])));
			/*if(props.action=="update")
			{
				var date=props.slot_data['date']
				//Sat Jul 30 2022 19:09:00 GMT+0530 (India Standard Time)
				changeDate(date)
				changeTime(date)
			}*/
		},[])
		const get_mentor_id_if_exists = () => {
		
			axios.post('/api/find_is_mentor',
			{'email':email})
			.then((response) => {
				//console.log(response.data)
				if(response.data['is_mentor']=="mentor")
				{
					setMentorID(response.data['mentor_id'])
				}
			});
		}
		const BookSlot= () =>{
			let day="AM"
			if(time.getHours()>11)
			{
				day="PM"
			}
			
			let time_view=get_time()+" "+day;
			
			
			let year=date.getFullYear();

			let date_view=(date.toDateString()).slice(0, -4);
			date_view=addStr(date_view, 4, ", ")

			return (
			<Box padding={2}>
			{
				
				
				<Grid container spacing={2}  direction="column">
				<Grid item xs="auto" >
				<Box marginBottom={2}>
				<Typography variant="subtitle1" color="textPrimary"><Box fontWeight="600">Choose date :</Box></Typography>
				<Card elevation={0}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.mb}>
				<DatePicker
					autoOk
					minDate={new Date()}
					format="dd-MM-yyyy"
					orientation={isMobile?"":"landscape"}
					variant="static"
					openTo="date"
					value={date}
					onChange={changeDate}
					className={classes.mb}
				  />
				</MuiPickersUtilsProvider>
				</Card>
				</Box>
				</Grid>
				
				
				<Grid item xs="auto" >
				<Box marginBottom={2}>
				<Typography variant="subtitle1" color="textPrimary"><Box fontWeight="600">Choose time : </Box></Typography>
				<Card elevation={0}>
				<MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.mb}>

						  <TimePicker
							minTime={new Date(0, 0, 0, 10)}
							maxTime={new Date(0, 0, 0, 17, 0)}
							
							disabledHours={getDisabledHours()}
							disabledMinutes={getDisabledMinutes()}
							
							ampm={true}
							variant="static"
							orientation={isMobile?"":"landscape"}
							value={time}
							onChange={changeTime}
							className={classes.mb}
						  />
				
				
				</MuiPickersUtilsProvider>
				</Card>
				</Box>
				</Grid>
				
				<Grid item xs={12} >
				<Card>
				<CardContent>
				<Grid container spacing={2} >
				<Grid item xs={12} >
				<Typography variant="subtitle1" color="textPrimary">
				<Box fontWeight="600">Choose duration : </Box>
				</Typography>
				</Grid>

				<Grid item xs={6} >				
				<FormControl fullWidth variant="outlined">
					  <InputLabel>Hours</InputLabel>
					  <Select
						value={selectedHours}
						onChange={(event) => setSelectedHours(event.target.value)}
					  >
						{[...Array(3).keys()].map((hour) => (
						  <MenuItem key={hour} value={hour}>
							{hour} hr
						  </MenuItem>
						))}
					  </Select>
					</FormControl>
				</Grid>
				<Grid item xs={6} >				
					<FormControl fullWidth variant="outlined">
					  <InputLabel>Minutes</InputLabel>
					  <Select
						value={selectedMinutes}
						onChange={(event) => setSelectedMinutes(event.target.value)}
					  >
						{[...Array(60).keys()].map((minute) => (
						  <MenuItem key={minute} value={minute}>
							{minute} min
						  </MenuItem>
						))}
					  </Select>
					</FormControl>
				</Grid>
				</Grid>
				</CardContent>
				</Card>
				</Grid>
				
				
				
				<Grid item xs={12} >
				
				<Box height="160px" width="100%">
				<Typography variant="subtitle1" color="textPrimary"><Box fontWeight="600">Your slot : </Box></Typography>
				<Card elevation={1} style={{ height:"100%"}}>
				<CardContent style={{ height:"100%",justifyContent:"center" ,alignItems:"center",display:"flex"}}>
				<Box textAlign="center">
				
				<Typography variant="h6">
				{year}
				</Typography>
				<Typography variant="h6">
				<Box fontWeight="700">{date_view}</Box>
				</Typography>
				<Box >
				<Typography variant="h6">
				{time_view}
				</Typography>
				
				</Box>
				</Box>
				</CardContent>
				</Card>
				</Box>
				</Grid>
				
				<Grid item xs={12}  width="100%">
				<Typography variant="subtitle2" color="error">
				<Box fontWeight="600" marginTop={4}>Note : </Box>
				</Typography>
				<Typography variant="subtitle2" color="textPrimary">
				* Default slot duration : 30 min
				</Typography>
				{/*
				<Typography variant="subtitle2" color="textPrimary">
				* Permissible slot time : Between 10AM to 7PM
				</Typography>
				*/}
				</Grid>
				
				
				<Grid item xs={12} >
				<Button variant="contained" size="large" 
				color="primary"
				onClick ={process_request}
				fullWidth
				disabled={loading} >
				 {loading && <CircularProgress size={20} />}
				 {!loading && <span>Add slot</span> }
				</Button>
				</Grid>
				
				
				</Grid>
			
			}
			
	
		</Box>
		)}

		return (
		<Box  style={{ backgroundColor:"#F3F6F9"}}>	
		{
				
				msg!=false
				? <AlertBox 
				show_status={true} 
				msg={msg} 
				msg_type={msg_type} />
				:''
		}
		<Box padding={2} display='flex'  margin="0px 8px 8px 8px">
		
			<Box flexGrow={1}>
			<Typography variant="h6">
			<Box fontWeight="bold">{is_update==true?"Edit Slots":"Add Slots"}</Box>
			</Typography>
			<Typography variant="caption">
			<Box fontWeight="bold" display={is_update==true?"flex":"none"}>Slot ID : {slot_id}</Box>
			</Typography>
			</Box>
			
			<IconButton onClick={()=>{props.parentCallback()}} >
			<CloseIcon color="textPrimary"   style={{fontSize:"0.85rem"}} />
			</IconButton>	
			
		</Box>
					
					
		<Box display={status=='professor'?"block":"none"}>
		<BookSlot/>
		</Box>
		
		
		</Box>

	    );
	
  	}
