import {
	Avatar, Box, Button ,
	Dialog, DialogActions, DialogContent,
	IconButton, makeStyles, Typography,Grid, Chip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { userProfile_path,get_name_slug,generateProfileUrl } from "../../Helper";


const useStyles = makeStyles(theme => ({
  large: {
    width: '116px !important',
    height: '116px !important',
  //  height: theme.spacing(15) ,
	border: '2px solid #d8d8d8 !important'
  },
  iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px'},
  
  chip:{    color: '#25387c', border: '1px solid #25387c',fontWeight:'bold',backgroundColor:'white'},
  fontsize:{fontSize:"0.85rem"},
  borderR:{borderRight:"2px solid #F2F2F2"}
  
  }));
let cls='btn pt btn-lg btn-success btn-block updated_btn';
let setButtonText="Edit Course";
let k=0;	

export default function MentorsPopup(props) {
	
	const classes = useStyles();
	const history = useHistory();
	const [profile_popop, setPopop] = React.useState(true);


   useEffect(() => {
	setTimeout(() => {

	}, 500);
   },[])

		

	
	const search_mentors = (tags,val) => {
		
		let string="search,"+tags
		if(window.location.pathname=="/mentors-and-experts")
		{
			props.handleCallback(string)
		}
		else
		{
			history.replace({
			pathname: "/mentors-and-experts",
			  state: {
					industry:tags
			  } })
		}

	}
	const handleClose = () => {
		setPopop(false)
		props.handleCallback("close");
	}	
	const get_full_profile = () => {
	let mentor=props.profile_details	
	let name=get_name_slug(mentor['name']);
	let mail_url=generateProfileUrl(mentor['email'])
	history.push({
	  pathname: '/profile/'+mail_url,
	  state: {
			profile_mail:mentor['email'],
			profile_type:'professor',
			profile_id:mentor['professor_id'],
			course_author_id:mentor['professor_id']
	  },
	})
	}
		const ChipCard= ({user}) => {	
		let category=[undefined,null].includes(user['category'])==false?(user['category']).split(","):""	
		return (
		 <Box marginBottom={2}
		 display={[undefined,null,''].includes(user['category'])==false?"block":"none"}
		 >
		 <Grid container spacing={2}>
		 {
			category.length>0  && category.map((e, index) => (
			 <Grid item xs="auto">
			
				<Box display={"flex"} >
					<Chip onClick={() => { search_mentors(e,"tags")}}
					key={e} 
					clickable
					size="medium"
					className={classes.chip}
					label={
					<Typography variant="caption" color="primary" >
					<Box fontWeight="600">	{e} </Box>
					</Typography>
					}
					/>	
				 </Box>
			 
			 </Grid>
			 ))
		  }
				 
		</Grid>
		</Box>
		)}
		
	const ProfileDialog = () =>
	{ 
			let profile_details=props.profile_details
			return(
			<Dialog
			open={profile_popop}
			onClose={handleClose} 
			fullWidth
			maxWidth="md">
			<DialogActions >
				<IconButton 
				onClick={handleClose}
				className={classes.iconsbg}>
							<CloseIcon color="grey"  
							className={classes.fontsize} />
				</IconButton>
			</DialogActions>
			<DialogContent >
			<Box display="flex"  marginBottom={2} >
			<Box display="block" paddingRight={2} className={classes.borderR}>
			 <Avatar className={classes.large} src={userProfile_path+profile_details['img']} >
			 </Avatar>
			 
			 <React.Fragment>	
						<Typography variant="subtitle1" color="primary"  >
						<Box marginLeft="5px" marginTop={2} fontWeight="600" lineHeight={1.25}
						display="flex" justifyContent="center" alignItems="center" textAlign="center" >
						{profile_details['name']}</Box>
						</Typography>
						
						<Typography variant="subtitle2"   color='textSecondary' >
						<Box marginLeft="5px" lineHeight={1.25} textAlign="center"
						display="flex" justifyContent="center" alignItems="center">{profile_details['industry']}</Box>
						</Typography >
			</React.Fragment>
			 
			{/* <React.Fragment>	
				<Typography variant="caption" color="textPrimary" >
				<Box marginTop={2} fontWeight="600" lineHeight={1.25}> 
				Email
				</Box>
				</Typography>
						
				<Typography variant="caption" color='textSecondary' >
				<Box lineHeight={1.25}>{props.profile_details['email']}</Box>
				</Typography >
			</React.Fragment>
			
			<React.Fragment>	
				<Typography variant="caption" color="textPrimary" >
				<Box marginTop={2} fontWeight="600" lineHeight={1.25}> 
				Phone No
				</Box>
				</Typography>
						
				<Typography variant="caption" color='textSecondary' >
				<Box lineHeight={1.25}>{profile_details['phone']}</Box>
				</Typography >
			</React.Fragment>
			*/}
						
			</Box>
			<Box display="block" paddingLeft={3} >
			
			<Typography variant="subtitle1" color="primary" component="p">
				<Box lineHeight={1.5} fontWeight="bold" display="none"> 
				About me 
				</Box>
				<ChipCard user={profile_details} />
			</Typography>
			
			<Typography variant="subtitle2" color="textSecondary" component="p">
				<Box lineHeight={1.5} > 
				{profile_details['about']}
				</Box>
			</Typography>
			
			<Box display="flex" justifyContent="end" > 
			<Button size="medium" variant="contained" color="primary"
			onClick={get_full_profile}>
			View Profile
			</Button>
		     </Box>
			
			</Box>
			</Box>
			</DialogContent>			
			
			</Dialog>
			
			)
	}				
	
			
	
		return (
			<ProfileDialog/>
	    );
	
  	}
