import axios from 'axios'
let data=0;
export let api="/api";
let student_id=null
let professor_id=null;
let status=null;
let inds=null;
let edu=null
let mentor_id=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	mentor_id=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_id']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	professor_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	inds=JSON.parse(localStorage.getItem('ac_login_user'))['industry']
	edu=JSON.parse(localStorage.getItem('ac_login_user'))['edu']

}	

export function list_community_members(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/list_community_members',arr)
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}
export function get_community_members_status(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/get_community_members_status',arr)
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}
export function getCommunity(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/get-community',arr)
	.then(response => {
	// setWithExpiry(key,response.data);
	return response.data
	})	
}
export function getCourseStructure(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/getCourseStructure',arr)
	.then(response => {
	// setWithExpiry(key,response.data);
	return response.data
	})	
}
export function get_chapter_doc(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/get_chapter_doc',arr)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function get_chapter_count(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/get_chapter_count',arr)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function get_submitted_assignments(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/get_submitted_assignments',arr)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function getJSONStructure(key,arr)
{		
	//console.log(arr);
	////console.log("hh")
	return axios.post(api+'/getJSONStructure',arr)
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function get_asssigment_added(key,arr)
{		
	let param={'action':'fetch',
	'search_by':arr[0],'id':arr[1],'request':arr[2],'start':arr[3],'end':arr[4],
	'key':key}
	if(status=='student')
	{
		param.student_id=student_id
		param.tab_request=arr[5]
	}
	//console.log(param);
	return axios.post(api+'/manage_assignment',param)
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}	
export function get_mentors(key,arr)
{		
	return axios.post(api+'/get_mentors',{
		'user_id':arr[0],'user_is':arr[1],'start':arr[2],'end':arr[3],'action':arr[4],'key':key})
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}
export function get_og_blogs(key,arr)
{		
	return axios.post(api+'/get_og_blogs',{'start':arr[0],'end':arr[1],'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}
export function get_user_specific_mentorship(key,arr)
{		
	let us=status=='student'?"mentee":"mentor"
	////console.log(us)
	let payload={'mentee_id':arr[0],'start':arr[1],'end':arr[2],'user':us,'mentorship_type':arr[4],'m_id':professor_id,'key':key}
	if(us=="mentor" && arr[4]=="pending")
	{
		
		payload.time_slot_id=arr[5];
	}
	
	return axios.post(api+'/get_user_specific_mentorship',payload)
	.then(response => {
	//setWithExpiry(key,response.data);
	return response.data
	})	
}
export function get_all_mentors(key,arr)
{		
	return axios.post(api+'/get_all_mentors',{'id':arr[0],'start':arr[1],'end':arr[2],'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}	
export function get_mentees_mentors(key,arr)
{		
	return axios.post(api+'/get_mentees_mentors',{'user_is':status,'id':arr[0],'start':arr[1],'end':arr[2],'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}	
export function get_users_webinar(key,arr)
{		
	return axios.post(api+'/get_users_webinar',{'start':arr[0],'end':arr[1],'email':arr[2],'key':key,'search':arr[3]})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function getQuizProgress(key,arr)
{		
	return axios.post(api+'/getQuizProgress',arr)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}
export function getRecommendation(key,profile_id)
{	

	inds=JSON.parse(localStorage.getItem('ac_login_user'))['industry']
	edu=JSON.parse(localStorage.getItem('ac_login_user'))['edu']
	let payload = {'user_id': profile_id,
	'start':0,
	'end':50,
	'key':key,
	'industry':inds,
	'qualification':edu,
	'search_by':"recommendation"}
					
	if(status==='student')
	{
		payload.user_type='student';
	}
	else
	{
		payload.user_type='professor';
	}

	return axios.post(api+'/findCourse',payload)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}
export function getProfessorPath(key,arr)
{		
	return 	axios.post('/api/get_user_email',{'path':arr,'key':key})
		.then((response) => {
		if(response.data['mail']=="null")
		{
			window.location.pathname='/profile-not-found'
		}
		else 
		{
			setWithExpiry(key,response.data);
			return response.data	
		}
	});
}
export function getCourseReview(key,arr)
{		
	if(arr[3]=="new")
	{
		localStorage.removeItem(key);
	}
	return axios.post(api+'/getReview', {'course_id':arr[0],
	'start':arr[1],'end':arr[2],'key':key,'pos':arr[3]})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}

export function getCourseRatings(key,course_id)
{		
	return axios.post(api+'/getRatingsDe', {'course_id':course_id,'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}
export function getChaptersTranscripts(key,chapter_id)
{		
	return axios.post(api+'/getTranscripts', {'chapter_id':chapter_id,
	'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}		
export function getCourseUniversity(key,university)
{		
	key=(key.toLowerCase()).replaceAll(" ","-")
	return axios.post(api+'/getUniversity', {'university':university,'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}	
export function getCourseInstructors(key,course_id)
{		
	return axios.post(api+'/getCourseInstructors', 
		{'course_id':course_id})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}	
export function getCourseChapters(key,course_id)
{		
	return axios.post(api+'/getChapters', 
		{'course_id':course_id,'search_by':'CourseID','start':0,'end':20})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}
export function getCourseHighlights(key,course_id)
{		
	return axios.post(api+'/addExCourse', 
		{'course_id':course_id,'search_by':'Highlights','operation':'search','key':(course_id+"_course_highlights")})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}

export function getCourseAdvantages(key,course_id)
{		
	return axios.post(api+'/addExCourse', 
		{'course_id':course_id,
		'key':course_id+'_course_advantage',
		'search_by':'Advantages',
		'operation':'search'})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})	
}

export function getCourseDetails(key,course_id)
{		
	return axios.post(api+'/findCourse', {
		'course_id':course_id,
		'search_by':"CourseID",
	})
	.then(response => {
		//console.log("calledd with db")
	setWithExpiry(key,response.data);
	return response.data
	})	
}

export function getGraphCompleted(key,stu_id)
{		
	return axios.post(api+'/getGrapgh', {
		'user_id':student_id,
		'type':"completed",
		'key':student_id+"_graph_completed_dashboard"
	})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}
export function getGraphCertificates(key,stud_id)
{		
	return axios.post(api+'/getGrapgh', {
		'user_id':student_id,
		'type':"certificate",
		'key':student_id+"_graph_certificated_dashboard"
	})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}
export function getGraphEnrolled(key,stud_id)
{		
	return axios.post(api+'/getGrapgh', {
		'user_id':student_id,
		'type':"enrolled",
		'key':student_id+"_graph_enrolled_dashboard"
	})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}

export function getCourseProgress(key,stud_id)
{		
	return axios.post(api+'/getChapterStatus',{'student_id': student_id})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}

export function getVideoProgress(key,data)
{		
	return axios.post(api+'/getVideoProgress',{'student_id':data[0],'start':data[1],'end':data[2],'key':key})
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	
	
}

	
export function getQuizEnrolled(key,arr)
{		
	return axios.post('/api/getQuizEnrolled', {
			'operation':"enrolled_courses",
			'student_id':arr[0],
			'search_by':"search",'start':arr[1],'end':arr[2],'key':key})
			.then(response => {
				//console.log(response.data)
			//	//console.log("vg")
			setWithExpiry(key,response.data);
			return response.data
			})
	
	
}

export function getQuizAdded(key,arr)
{		
	//console.log(arr)
	return axios.post('/api/addUpDeQuiz',arr)
	.then(response => {
	setWithExpiry(key,response.data);
	return response.data
	})
	.catch(function (error) {
		//console.log("err cahing 319")
		//console.log(error)
	})
	
	
	
}


export function getStudentEnrolledCourses(key,data)
{
		return axios.post('/api/findCourse', {'course_enrolled_id':data[0],
		'search_by':"StudentID",'start':data[1],'end':data[2],'key':key})
		.then(response => {

		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getProfessorAddedCourses(key,data)
{
			let payload= {'course_author_id':data[0],
		'search_by':"ProfessorID",'start':data[1],'end':data[2],'key':key}
		//console.log(payload)
		//alert(payload)
		return axios.post('/api/findCourse',payload)
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getUserData(key,email)
{
			
		return axios.post('/api/get_user', {'email':email})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getRecentlyViewed(key,arr)
{
		return axios.post(api+'/findCourse',
		{'search_by':"Recently Viewed",
		'start':arr[0],'end':arr[1],'user_id':arr[2],'key':key,
		'user_type':status})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getSuggestedCourses(key,data)
{
		
		return axios.post(api+'/findCourse',
		{'search_by':"suggested",
		'start':data[0],'end':data[1],'key':key,'student_id':student_id})
		.then(response => {
	//	alert(response.data.length)
		setWithExpiry(key,response.data);
		return response.data
		})
	
}
export function getPopularCourses(key,data)
{
		
		return axios.post(api+'/findCourse',
		{'search_by':"Popular",
		'start':data[0],'end':data[1],'key':key})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getBookmarkedCourses(key,data)
{
		
		return axios.post(api+'/findCourse',
		{'search_by':"Bookmarked",
		'start':data[0],'end':data[1],'user_id':data[2],'key':key,
		'user_type':status})
		.then(response => {
		setWithExpiry(key,response.data);
		////console.log(response.data.length)		
		return response.data
		})
	
}

export function getAllCourses(key,data)
{
		let payload = {'start':data[0],'end':data[1],'key':key}
		// console.log(payload)
		return axios.post(api+'/course',payload)
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getUniversity(key,data)
{
		
		return axios.post(api+'/addUpDeUniversity',
		{'operation':'search','search_by':"list",'start':data[0],'end':data[1],'key':key})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getSubscription(key,data)
{

		return axios.post(api+'/addUpDeSubscription',
		{'operation':'search','search_by':"list",'start':data[0],'end':data[1],'key':key})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function getCoupons(key,data)
{

		return axios.post(api+'/addUpDeCoupon',
		{'operation':'search','search_by':"list",'start':data[0],'end':data[1],'key':key})
		.then(response => {
		setWithExpiry(key,response.data);
		return response.data
		})
	
}

export function callFunction(key,funName,parameter)
{
		
		if (funName=="getUserData")
		{
			return getUserData(key,parameter).then(data => {return data});		
		}
		else if (funName=="getQuizEnrolled")
		{
			return getQuizEnrolled(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseProgress")
		{
			return getCourseProgress(key,parameter).then(data => {return data});		
		}
		else if (funName=="getRecommendation")
		{
			return getRecommendation(key,parameter).then(data => {return data});		
		}
		else if (funName=="getGraphEnrolled")
		{
			return getGraphEnrolled(key,parameter).then(data => {return data});		
		}
		else if (funName=="getGraphCompleted")
		{
			return getGraphCompleted(key,parameter).then(data => {return data});		
		}
		else if (funName=="getGraphCertificates")
		{
			return getGraphCertificates(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseDetails")
		{
			return getCourseDetails(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseAdvantages")
		{
			return getCourseAdvantages(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseHighlights")
		{
			return getCourseHighlights(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseChapters")
		{
			return getCourseChapters(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseInstructors")
		{
			return getCourseInstructors(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseUniversity")
		{
			return getCourseUniversity(key,parameter).then(data => {return data});		
		}
		else if (funName=="getChaptersTranscripts")
		{
			return getChaptersTranscripts(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseRatings")
		{
			return getCourseRatings(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseReview")
		{
			return getCourseReview(key,parameter).then(data => {return data});		
		}
		else if (funName=="getStudentEnrolledCourses")
		{

			return getStudentEnrolledCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getProfessorAddedCourses")
		{
			return getProfessorAddedCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getRecentlyViewed")
		{
			return getRecentlyViewed(key,parameter).then(data => {return data});		
		}
		else if (funName=="getBookmarkedCourses")
		{
			return getBookmarkedCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getPopularCourses")
		{
			return getPopularCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getQuizAdded")
		{
			return getQuizAdded(key,parameter).then(data => {return data});		
		}
		else if (funName=="getAllCourses")
		{
			return getAllCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getUniversity")
		{
			return getUniversity(key,parameter).then(data => {return data});		
		}
		else if (funName=="getSubscription")
		{
			return getSubscription(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCoupons")
		{
			return getCoupons(key,parameter).then(data => {return data});		
		}
		else if (funName=="getVideoProgress")
		{
			return getVideoProgress(key,parameter).then(data => {return data});		
		}
		else if (funName=="getProfessorPath")
		{
			return getProfessorPath(key,parameter).then(data => {return data});		
		}
		else if (funName=="getQuizProgress")
		{
			return getQuizProgress(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_users_webinar")
		{
			return get_users_webinar(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_mentees_mentors")
		{
			return get_mentees_mentors(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_all_mentors")
		{
			return get_all_mentors(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_user_specific_mentorship")
		{
			return get_user_specific_mentorship(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_og_blogs")
		{
			return get_og_blogs(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_mentors")
		{
			return get_mentors(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_asssigment_added")
		{
			return get_asssigment_added(key,parameter).then(data => {return data});		
		}
		else if (funName=="getJSONStructure")
		{
			return getJSONStructure(key,parameter).then(data => {return data});		
		}
		
		else if (funName=="get_submitted_assignments")
		{
			return get_submitted_assignments(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_chapter_count")
		{
			return get_chapter_count(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_chapter_doc")
		{
			return get_chapter_doc(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCourseStructure")
		{
			return getCourseStructure(key,parameter).then(data => {return data});		
		}
		else if (funName=="getSuggestedCourses")
		{
			return getSuggestedCourses(key,parameter).then(data => {return data});		
		}
		else if (funName=="getCommunity")
		{
			return getCommunity(key,parameter).then(data => {return data});		
		}
		else if (funName=="get_community_members_status")
		{
			return get_community_members_status(key,parameter).then(data => {return data});
		}
		else if (funName=="list_community_members")
		{
			return list_community_members(key,parameter).then(data => {return data});
		}
		
}
export function fromStorage(key)
{
			
	return JSON.parse(localStorage.getItem(key));

	
}

export function getCacheDetails(key,funName,parameter){
    
				
		if (window.localStorage)
		{
			if(![undefined,'',null].includes(getWithExpiry(key))) 
			{	
		
				if(funName=="getCourseDetails")
				{
					//console.log("called with localstorage")
				}
				/*if(funName=="getQuizAdded")
				{
					if(localStorage.getItem(key)=="[]")
					{
						//console.log(" no data "+localStorage.getItem(key))
					}
					else
					{
						//console.log("have data" + (localStorage.getItem(key)).length)
					}
				}*/
				
				//if(funName=="getVideoProgress"){//console.log(funName+' - from localstorage')}
				
				return Promise.resolve('key').then(function(value) {
				return getWithExpiry(key);
				})

			}
			else
			{  
				//if(funName=="getVideoProgress"){//console.log(funName+' -  local storage, coming from db')}
				return callFunction(key,funName,parameter)
				  
			}

		}
		else
		{ 
			//if(funName=="getVideoProgress"){//console.log(funName+'  - no local storage, coming from db')}
			return callFunction(key,funName,parameter)

		}
}
export function setWithExpiry(key, value, ttl) {
	const now = new Date()

	// `item` is an object which contains the original value
	// as well as the time when it's supposed to expire
	//ttl or 5 min=300 //
	
	const item = {
		value: value,
		expiry: now.getTime() + (5*60*1000),
	}
	localStorage.setItem(key, JSON.stringify(item))
}
export function getWithExpiry(key) {
	const itemStr = localStorage.getItem(key)
	// if the item doesn't exist, return null
	if (!itemStr) {
		//console.log(key+" return null expery check")
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key)
		//console.log(key+" - current date "+now.getTime())
		//console.log(key+" - expiry date "+item.expiry)
		return null
	}
	////console.log(item.value)
	return item.value
}
export function deleteLocal(matchKeys)
{
		if((matchKeys.toString()).includes(","))
		{
			    matchKeys=matchKeys.toString()
				let arr=matchKeys.split(",")
				for(let i=0;i<arr.length;i++)
				{
					//alert()
					del_keys(arr[i])
				}	
				////console.log(matchKeys+" 5 key deleted successfullly ")
				return matchKeys				
		}
		else
		{
			
				let key=matchKeys.toString()!=null ?del_keys(matchKeys.toString()):''
				////console.log(key+" 8 key deleted successfullly "+matchKeys)
				return key
		}
}

export function getKeys(matchKeys)
{
		let localStorage_Keys=''
		if(/\d/.test(matchKeys))
		{
			localStorage_Keys=(Object.keys(localStorage)).filter(res => res.includes(matchKeys))
		}
		else
		{
			localStorage_Keys=(Object.keys(localStorage)).filter(res => (res.toLowerCase()).includes(matchKeys.toLowerCase()))
		}
		return localStorage_Keys
		
}


export function del_keys(matchKeys)
{
		////console.log(matchKeys)
		let k=matchKeys
		if(matchKeys!="_")
		{
			let localStorage_Keys=getKeys(matchKeys)
			for(let j = 0; j < localStorage_Keys.length; j++)
			{ 
				////console.log(localStorage_Keys[j])
				localStorage.removeItem(localStorage_Keys[j]); 
			}
			k=localStorage_Keys==""?matchKeys:localStorage_Keys		
		}
		////console.log(matchKeys+"  ff "+k)
		return k
		
}