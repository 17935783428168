import {Box,Grid} from '@material-ui/core';
import React, { Component, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { ForgotPassCard } from '../../Routes';
import { useStyles } from "../../useStyles";

const CarouselCard = React.lazy(() => import('../../../webinar/login/CarouselCard'));
class ForgotPassword extends Component {

	 componentClicked = () => console.log("clicked");
  
 
    render() {
	const classes = this.props.classes;
    const st=isMobile==true ?'none':'block'

	const ImgBox = () => {
	return (
           <Box paddingTop={5} paddingBottom={5}>
				<img src="/assets/images/sm.jpg" alt="" height="100%"  width="100%" />
			</Box>

	
	)
	}

	
	
	
	return(
    
    	<Box 
		    display = "flex" 
		    justifyContent = "center" 
		    alignItems = "center"
		    margin={2}			
		   >
			<Grid container spacing={2} >
			<Grid item xs={0} md={8}>
                <Suspense fallback={<div> </div>}>
						<CarouselCard /> 
				</Suspense>   
			</Grid>
			
			
			<Grid item xs={12} md={4}>
				<Suspense fallback={<div> </div>}>
							<ForgotPassCard /> 
				</Suspense>
			</Grid>

			</Grid> 
			</Box> 
 
	)
  
    }
}
export default () => {
    const classes = useStyles();
    return (
        <ForgotPassword classes={classes} />
    )
}



