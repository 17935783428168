
import {
	Card, CardContent, CardHeader, Grid
} from "@material-ui/core";
import React, { Component, Suspense } from 'react';
import TimelineUpload from "./TimelineUpload";
import ImgUpload from "./UserImg";
import { ProfessorRegister, StudentRegister } from '../../Routes';
import { user_view } from '../../Helper';
import { useStyles } from "../../useStyles";


let status=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}	

class EditProfile extends Component {

    render()
  	{	
		const classes = this.props.classes;
	
	
	
	const EditForm = () => {
	return (

		<Card>
        <CardHeader title="Update profile " />
		<CardContent>
		{
             status=='professor'
             ? <Suspense fallback={<div> </div>}><ProfessorRegister/></Suspense>
             : <Suspense fallback={<div> </div>}><StudentRegister/></Suspense>
         }
		</CardContent>
		</Card>
	
	    
		)
	}
	
	const UploadForm = () => {
	return (

	
		<Grid container spacing={3}   className=" block">
          <Grid item xs={12}>
			<ImgUpload />
          </Grid>
         <Grid item xs={12}>
			<TimelineUpload />
        </Grid>
		
		
        </Grid>
		)
	}
	
	return (
	      
		<Suspense fallback={<div> </div>}>
		
			<Grid container spacing={3}>
				 
			{
				
				user_view=="academy"
				?<Grid item xs={12} sm={4} >
					  <UploadForm />
				</Grid>
				:""
			}
			<Grid item xs={12} sm={user_view=="academy"?8:12}  >
			  <EditForm />
			</Grid>
		  </Grid>
		
		</Suspense>


	    );
  	
}}
export default () => {
    const classes = useStyles();
    return (
        <EditProfile classes={classes} />
    )
}
