import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

export const Webinartheme = createMuiTheme({
MuiDialogContent:{root :{ backgroundColor: 'red !important'}}
})


export const Ogtheme = createMuiTheme({

	palette: {
		type: 'light',
		primary: {main: '#253B7C'},
		secondary: {main: '#FFD84E'},
		grey: {  main: "#02a24e" },
		success: { main : "#4caf50" },
		sky: { main : "#03a9f4" }, //notification
		text: {
			primary:"#1A2027",
			secondary:"rgb(90, 105, 120)",
		},
	},
	overrides: 
	{
		//background: linear-gradient(to right bottom, rgb(14 107 200), #25387c 120%);
		//MuiGrid: { root: { backgroundColor: 'white'}},
		MuiPaper: { 
		
		root: { borderRadius: '8px'},
		elevation1:{
			//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
			'box-shadow':'',
			border: '1px solid rgb(229, 232, 236)'
		},

		},
		
		MuiPopover: { 
		paper: {
		"&.pop-paper": 	{borderRadius: '8px',padding:'24px'}
			},
		},
		MuiOutlinedInput:{  
		root:{
		//backgroundColor:"red",
		
		borderRadius: 'inherit'
		}
		,input: { padding: '14.5px 14px'}},

		MuiIconButton: { root: { fontSize: '1rem',  }},
		MuiCard: { root: { borderRadius: '12px'  ,
		//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'

		/* 'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'*/
		
		//'box-shadow': 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
		
		}, },
		MuiChip: { root: { backgroundColor: '#f3f3f3'}, },
		MuiCardHeader: {
        root:{
			'border-bottom':'1px solid rgba(0, 0, 0, 0.12)',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight:'600',
			lineHeight: '1.6em'
        },
		},
		MuiCardContent:{root:{ '&:last-child': { 
		   paddingBottom: '16px', 
		}}},
		MuiList:{padding:{paddingTop:'0px',paddingBottom:'0px'}},
		MuiListItemIcon:{root:{'min-width':'34px'}},
		
		   MuiTimelineItem: {
			  missingOppositeContent: {
				"&:before": {
				  display: "none"
				}
			  }
		},
		

		MuiDialog:{paperWidthXs :{ 'border-radius': '8px'}},
		MuiDialogTitle: {
		root: {'& h6': {fontWeight: '700'}}},
	//	MuiDialogContent:{root:{'padding':'16px'}},
		
		MuiSkeleton:{text:{'transform-origin': '0 0%'}},
		
		//MuiAvatar:{img:{'object-fit': 'contain'}},
		//MuiTabPanel:{root:{'padding':'0px'}},
	//	MuiListItem:{gutters:{'padding-top': '0.85px','padding-bottom': '0.85px'}},
		MuiButton : {
			
			root :{padding: '7.3px 16px',textTransform: 'capitalize',borderRadius:'22px',fontFamily:"samsungsharpsans-bold"},
			
		 sizeLarge: {
			"& $label": {
			  fontFamily: "samsungsharpsans-bold",
			  fontSize:'18px',
			  padding:"10px 32px",
			}
		  },
		  	
		 sizeSmall: {
			"& $label": {
			  fontFamily: "samsungsharpsans-bold",
			  borderRadius:'12px'
			}
		  },
		  
		outlinedPrimary:{
			fontFamily: "samsungsharpsans-bold",
			"& $label": {
			  fontFamily: "samsungsharpsans-bold",
			}},
		containedPrimary:{background: "linear-gradient(180deg, #324FA4 7.27%, #253B7C 88.9%)",},
		containedSecondary:{
			color:'#253B7C'
		},},
	
		MuiTab: {textColorInherit: {    'opacity': '0.3'}},
		/*MuiTab: {
		root: {
        "&:hover": {
          backgroundColor: 'red',
          color: 'blue'
        }
      },
      selected: {
        backgroundColor: 'yellow',
        color: 'green',
        "&:hover": {
          backgroundColor: 'white',
          color:'pink'
        }
		}}
		 */
		/*MuiTab: {
			wrapper: {flexDirection: "row",textTransform: 'capitalize',},
			labelIcon: {minHeight: "48px",wrapper: {  '& > :first-child': {           'margin-bottom': '0px'     },'margin-bottom': '0px'   },
		}}*/
	},

	typography: {
	fontFamily: ["samsungsharpsans"].join(','),
	//fontFamily: ["Samsung Sharp Sans"].join(','),
	fontWeight: 500
	}, 	
	button: { textTransform: 'capitalize','box-shadow': 'rgba(255, 193, 7, 0.24) 0px 8px 16px 0px'}
});
export const OgthemeOld = createMuiTheme({

	palette: {
    type: 'light',
    primary: {
      main: '#01579b', //pallete
    },
    secondary: {
      main: '#FAAF01', //rating
    },
	grey: {  main: "#02a24e" },
	success: { main : "#4caf50" },
	sky: { main : "#03a9f4" }, //notification

	text: {
		primary:"#1A2027",
		secondary:"rgb(90, 105, 120)",
	},
  },
	overrides: 
	{
		//background: linear-gradient(to right bottom, rgb(14 107 200), #25387c 120%);
		//MuiGrid: { root: { backgroundColor: 'white'}},
		MuiPaper: { 
		
		root: { borderRadius: '8px'},
		elevation1:{
			//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
			'box-shadow':'',
			border: '1px solid rgb(229, 232, 236)'
		},

		},
		
		MuiPopover: { 
		paper: {
		"&.pop-paper": 	{borderRadius: '8px',padding:'24px'}
			},
		},
		MuiOutlinedInput:{input: { padding: '14.5px 14px'},root:{borderRadius: '7px'}},

		MuiIconButton: { root: { fontSize: '1rem',  }},
		MuiCard: { root: { borderRadius: '8px'  ,
		//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'

		/* 'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'*/
		
		//'box-shadow': 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
		
		}, },
		MuiChip: { root: { backgroundColor: '#f3f3f3'}, },
		MuiCardHeader: {
        root:{
			'border-bottom':'1px solid rgba(0, 0, 0, 0.12)',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight:'600',
			lineHeight: '1.6em'
        },
		},
		MuiCardContent:{root:{ '&:last-child': { 
		   paddingBottom: '16px', 
		}}},
		MuiList:{padding:{paddingTop:'0px',paddingBottom:'0px'}},
		MuiListItemIcon:{root:{'min-width':'34px'}},
		
		   MuiTimelineItem: {
			  missingOppositeContent: {
				"&:before": {
				  display: "none"
				}
			  }
		},
		

		MuiDialog:{paperWidthXs :{ 'border-radius': '8px'}},
		MuiDialogTitle: {
		root: {'& h6': {fontWeight: '700'}}},
	//	MuiDialogContent:{root:{'padding':'16px'}},
		
		MuiSkeleton:{text:{'transform-origin': '0 0%'}},
		
		//MuiAvatar:{img:{'object-fit': 'contain'}},
		//MuiTabPanel:{root:{'padding':'0px'}},
	//	MuiListItem:{gutters:{'padding-top': '0.85px','padding-bottom': '0.85px'}},
		MuiButton : {root :{padding: '7.3px 16px',textTransform: 'capitalize'},
		containedPrimary:{'box-shadow': 'rgb(37 56 124 / 24%) 0px 8px 16px 0px'},
		containedSecondary:{'box-shadow': 'rgb(255 193 7 / 24%) 0px 8px 16px 0px'},},
	
		MuiTab: {textColorInherit: {    'opacity': '0.3'}},
		/*MuiTab: {
		root: {
        "&:hover": {
          backgroundColor: 'red',
          color: 'blue'
        }
      },
      selected: {
        backgroundColor: 'yellow',
        color: 'green',
        "&:hover": {
          backgroundColor: 'white',
          color:'pink'
        }
		}}
		 */
		/*MuiTab: {
			wrapper: {flexDirection: "row",textTransform: 'capitalize',},
			labelIcon: {minHeight: "48px",wrapper: {  '& > :first-child': {           'margin-bottom': '0px'     },'margin-bottom': '0px'   },
		}}*/
	},

	typography: { fontFamily: ['IBM Plex Sans',"-apple-system,BlinkMacSystemFont","sans-serif"].join(','),
	fontWeight: 500
	}, 
	button: { textTransform: 'capitalize','box-shadow': 'rgba(255, 193, 7, 0.24) 0px 8px 16px 0px'}
});
export const Academytheme = createMuiTheme({

	palette: {
    type: 'light',
    primary: { main: '#25387c' },
    secondary: { main: '#f9bb02' },
	grey: {  main: "#02a24e" },
	success: { main : "#4caf50" },
	error: { main : "#d32f2f" },
	info: { main : "#0288d1" },

	 text: {
		primary:"#1A2027",
		secondary:"rgb(90, 105, 120)",
	},
  },
	overrides: 
	{
		//background: linear-gradient(to right bottom, rgb(14 107 200), #25387c 120%);
		//MuiGrid: { root: { backgroundColor: 'white'}},
		//MuiBox: { root: { width: '-webkit-fill-available'}},
		MuiPaper: { 
		
		root: { borderRadius: '8px'},
		elevation1:{
			//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
			'box-shadow':'',
			border: '1px solid rgb(229, 232, 236)'
		},

		},
		
		MuiPopover: { 
		paper: {
		"&.pop-paper": 	{borderRadius: '8px',padding:'24px'}
			},
		},
		MuiOutlinedInput:{input: { padding: '14.5px 14px'}},

		MuiIconButton: { root: { fontSize: '1rem',  }},
		MuiCard: { root: { borderRadius: '8px'  ,
		//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'

		/* 'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'*/
		
		//'box-shadow': 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
		
		}, },
		MuiChip: { root: { backgroundColor: '#f3f3f3'}, },
		MuiCardHeader: {
        root:{
			'border-bottom':'1px solid rgba(0, 0, 0, 0.12)',
		},
		title: {
			fontSize: '1.25rem',
			fontWeight:'600',
			lineHeight: '1.6em'
        },
		},
		MuiCardContent:{root:{ '&:last-child': { 
		   paddingBottom: '16px', 
		}}},
		MuiList:{padding:{paddingTop:'0px',paddingBottom:'0px'}},
		MuiListItemIcon:{root:{'min-width':'34px'}},
		
		   MuiTimelineItem: {
			  missingOppositeContent: {
				"&:before": {
				  display: "none"
				}
			  }
		},
		

		MuiDialog:{paperWidthXs :{ 'border-radius': '8px'}},
		MuiDialogTitle: {
		root: {'& h6': {fontWeight: '700'}}},
	//	MuiDialogContent:{root:{'padding':'16px'}},
		
		MuiSkeleton:{text:{'transform-origin': '0 0%'}},
		
		//MuiAvatar:{img:{'object-fit': 'contain'}},
		//MuiTabPanel:{root:{'padding':'0px'}},
	//	MuiListItem:{gutters:{'padding-top': '0.85px','padding-bottom': '0.85px'}},
		MuiButton : {root :{padding: '7.3px 16px',textTransform: 'capitalize'},
		containedPrimary:{'box-shadow': 'rgb(37 56 124 / 24%) 0px 8px 16px 0px'},
		containedSecondary:{'box-shadow': 'rgb(255 193 7 / 24%) 0px 8px 16px 0px'},},
	
		MuiTab: {textColorInherit: {    'opacity': '0.3'}},
		/*MuiTab: {
		root: {
        "&:hover": {
          backgroundColor: 'red',
          color: 'blue'
        }
      },
      selected: {
        backgroundColor: 'yellow',
        color: 'green',
        "&:hover": {
          backgroundColor: 'white',
          color:'pink'
        }
		}}
		 */
		/*MuiTab: {
			wrapper: {flexDirection: "row",textTransform: 'capitalize',},
			labelIcon: {minHeight: "48px",wrapper: {  '& > :first-child': {           'margin-bottom': '0px'     },'margin-bottom': '0px'   },
		}}*/
	},

	typography: { fontFamily: ['IBM Plex Sans',"-apple-system,BlinkMacSystemFont","sans-serif"].join(','),
	fontWeight: 500
	}, 
	button: { textTransform: 'capitalize','box-shadow': 'rgba(255, 193, 7, 0.24) 0px 8px 16px 0px'
}
});
export const BeforeLoginStyles = makeStyles((theme) => ({

	Link: {
    color: '#25387c',
    cursor: 'pointer',
    '&:hover': {
		color: '#394a88',
		'text-decoration':'underline',
	}
	},

	grow: {flexGrow: 1},
    
    logo:{
        display:'block',
        [theme.breakpoints.down('xs')]: {
           display:'none'
         },
    }
   
  }));
export const constStyles ={
	list:{'padding-top': '2px',
	'padding-bottom': '2px',},
	link:{
    color: '#25387c',
    '&:hover': {
		color: '#394a88',
		'text-decoration':'underline',
	}
	}
}
						
						
export const image_useStyles = makeStyles(theme => ({
  speakerButton: {
    backgroundColor: '#e0e0e0',
	color:'black',
	textTransform: 'capitalize',
	margin:'8px',
	'&:hover':{backgroundColor:'#d5d5d5'}
  },
  startButton: {
    backgroundColor: '#43A941',
	color:'white',
	textTransform: 'capitalize',
	margin:'8px',

	'&:hover':{backgroundColor:'rgb(57 140 55)'}
  },
  endButton: {
    backgroundColor: '#F44C3D',
	color:'white',
	textTransform: 'capitalize',
	margin:'8px',

	'&:hover':{backgroundColor:'#d8473a'}
  },
  small: {
    width: '40px !important',
    height: '36px !important',
	border: '2px solid #d8d8d8 !important'
	
  },root: {
    flexGrow: 1,
	padding:'0%',
	margin:'0%',
	},
  paper: {
  'border-radius':"12px",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: 
  {
	'border-radius':"12px",
	position:'relative',
	backgroundColor:'rgb(0 0 0 / 75%)',
	width:"100%",height:"520px",
	position:'relative'
  },
  webcam:{position:'absolute',
  top:'20px',right:'20px',borderRadius:'12px'},
  img: {	
	'border-radius':'12px',
	margin: 'auto',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	width: 'inherit',
	height: 'inherit',
  },
  media: {
	'border-radius':'12px'
  },
 
}));
						
export const courseSort_useStyles = makeStyles(theme => ({
formControl: {
	maxWidth: '100% !important',
	minWidth: '100% !important',
  },
   select: 
    {
		width: 'inherit',
      border: "1px solid black",
      borderRadius: "5%",
      backgroundColor:'lightgrey',
    },
  
   
    chip: {
	fontSize:'12px',
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '4px',
    },
  },
}));

export const useStyles = makeStyles(theme => ({
   large: {
    width: '116px !important',
    height: '116px !important',
  //  height: theme.spacing(15) ,
	border: '2px solid #d8d8d8 !important'
  },
  medium: {
    //width: theme.spacing(10) , height: theme.spacing(10) ,	
	width: '80px !important',
    height: '80px !important',
	cursor:'pointer'
  }, 
  small: {
    //width: theme.spacing(4) ,height: theme.spacing(4) ,	
	width: '64px !important',
    height: '64px !important',
	border: '2px solid #d8d8d8 !important'

  },
 root: {
    flexGrow: 1,height:"100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
	'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
  },
	tab: { '& .MuiBox-root': {padding: '0px'}},
	playerpaper: {  '& .MuiPaper-root': {'box-shadow': 'unset'} },
 
 
	 chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
	backgroundColor:'#fbfbfb',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
	green_menu:
	{backgroundColor:"rgb(40 124 37 / 8%) !important",color:"rgb(0, 171, 85) !important",border:'transparent'},
	
	yellow_menu:
	{backgroundColor:"rgb(255 198 30 / 10%) !important",color:"rgb(253, 169, 45) !important",border:'transparent'},
	
	blue_menu:
	{backgroundColor:"rgba(37, 56, 124, 0.04) !important",color:"rgba(37, 56, 124, 1) !important"},
 
        
    display:{
        display:'block',
        [theme.breakpoints.down('xs')]: {
           display:'none'
         },
    },
 
	cardh:{height:'calc(100vh - 128px)'},
   
	mentors_about:{ height:'88px' ,width:'336px'},
	dialog_paper: { marginLeft:(window.innerWidth)>600?'5.5%':'' },
	notched: {borderRadius:"7px"},
	zoom:{ "&:hover": {
		margin:'8px',
			transform: 'scale(1.02)',
			//'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
			'box-shadow': '0px 5px 3px -2px rgb(0 0 0 / 20%), 0px 5px 4px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
	}},
	elevate:{ "&:hover": {
		// margin:'0.5px',
			'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
	}},
		reverse_primary:{
		cursor:'pointer',
		backgroundColor:"#25387c1f",
		color:'#253B7C',
		'&:hover':{
			backgroundColor:'#253B7C',
			color:"white",			
		},
	},

}));
//linear-gradient(to right bottom, rgb(142 184 226 / 93%), #25387c 120%)
	
	//linear-gradient(to right bottom, rgb(142 184 226 / 93%), #4e5f9be0 120%)
	

export const community_useStyles = makeStyles((theme) => ({
  small: {
    width: '100px',
    height: '100px',
  },
  name: {
    color: '#25387c',
    fontWeight: 700,
    fontSize: '18px',
    cursor: 'pointer',
    marginTop: '4px',
  },
  about: {
    fontSize: '14px',
  },
  hovercard: {
    position: 'relative',
    margin: '8px',
  },
  mentorcard: {
    padding: '0px',
  },
}));