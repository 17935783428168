
import {
	Box,Button, Grid,CardContent,Card,CardHeader, Container ,CardActionArea, Avatar, makeStyles, Typography, ThemeProvider
} from '@material-ui/core';
import React, { Component, Suspense , useContext } from 'react';
import Sticky from 'react-sticky-el';
import {
	Account_Skeleton,
	Course_Progress_Skeleton, Graph_Skeleton, Horizontal_Course_Skeleton,
	Quiz_Skeleton, Recommendation_Skeleton, Timeline_Skeleton,
	User_Course_Skeleton
} from '../../functions/Skeleton';
import { Academytheme } from "../../functions/useStyles";
import { getCacheDetails } from '../../functions/caching';
import { UserContext } from '../../functions/courses/UserContext';
import {api} from '../../functions/Helper';
import axios from 'axios';
import ShowMoreText from "react-show-more-text";
import { useHistory, useLocation } from 'react-router-dom';


let student_id=null
let status=null
let is_mentor=null;
let mentor_req=null;
if(["admin",null,"webinar"].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_req']
	is_mentor=JSON.parse(localStorage.getItem('ac_login_user'))['is_mentor']
}
let is_mentor_count=1;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  sm: {
    height: 100,
    width: 100,
	border:'2px solid #eeeeee'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center'
},
center: {
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column'
}
}));
class Speakers extends Component {
	
	constructor(props) {
    super(props);
	this.state={msg:false}
	this.state={
		      msg:false,msg_type:false,
            course_show:[],start:0,end:20,hasMore: true,record_len:0}
    }
	getAllSpeakers=()=>
    {
        axios.get(api+'/getAllSpeakers', 
		{search:'',
		start:0,
		end:3
		})
			.then((response) => {
			this.setState({course_show:response.data})
            //console.log(response.data.length)
			if(response.data.length<this.state.end)
            {this.state.hasMore=false;
           	this.setState({hasMore:false})
            }	
			//console.log(response.data)
			});
    }
	
	executeOnClick(isExpanded) {
        //console.log(isExpanded);
    }
	
  	componentDidMount()
	{
		
		this.getAllSpeakers()	
	}
	
render()
{   
	const classes = this.props.classes;
	
	const ProfileCard=()=>{
		let arr=window.location.pathname=="/dashboard-webinars"?(this.state.course_show).slice(0,4):this.state.course_show
		return (
		<Grid container spacing={2}>
		{
            
         arr.map((e, index) => (
		 
		 <Grid item xs={12} sm={6}>
		 
		 <Box position="relative" paddingTop={5}>
		 <Box    
			position="absolute"
		   marginTop='-35px'
		   marginLeft='16px'
		   display='flex'
		   alignItems='flex-start'
		   >
			 <Avatar  className={classes.sm} 
					src={'assets/images/speakers/'+e['speaker_img']} >
			</Avatar>
		   </Box>
		   
		 <Card elevation={3}>
		   
		   <CardContent>
							
				<Box
				paddingLeft="100px"
				display="flex"  
				textAlign="center"
				justifyContent="center" >
				
					<Box>
					<Typography  variant="subtitle1" color="primary" > 
					 <Box fontWeight="fontWeightBold" >
					 {e['speaker_name']}
					 </Box>
					 </Typography> 
					 
					  <Typography  variant="caption" color="textPrimary" >
					 <Box 
					 lineHeight={1.25}  
					 fontWeight="fontWeightBold"
					 height={"24px"}>
					 {e['speaker_desc']}
					 </Box>
					 </Typography> 
					 
					 </Box> 
				
				</Box>
				
				<Box marginTop={2} display="block" textAlign="center" justifyContent="center" >
					
					 <Typography  variant="subtitle2" color="textSecondary" >
					 <Box lineHeight={1.25} minHeight={"52.5px"}>
					 <ShowMoreText
                        lines={3}
                        more="Know more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        onClick={this.executeOnClick}
                        expanded={false}
                        truncatedEndingComponent={" ..."}
                    >
                     
                     {e['speaker_desc2']}
                                 
                     </ShowMoreText>
					 </Box>
					 </Typography> 
					 
					
					</Box>
					</CardContent>
		</Card>
		 </Box>
		</Grid>
		))}
		
		
		<Grid item xs={12}>
		<Box 
		display={window.location.pathname=="/dashboard-webinars"?"flex":"none"} 
		m={0} 
		justifyContent='flex-end'>
					<Button
					size="small"  
					color="primary" 
					onClick={()=>{this.props.history.push("/all-speakers")}} 
					>
					View Experts
					</Button>
				</Box>
		</Grid>
		
		</Grid>
		)
	}
	return (
	<Card>
	<CardHeader title="Speakers" />
	<CardContent>
	<ProfileCard
	img="abc.png"
	title="Ramesh Sippi"
	subtitle="HR Minister"
	href=""
	index=""
	/>
	</CardContent>
	</Card>

  );
}}

export default (props) => {
		const user = useContext(UserContext);
	    const classes = useStyles();
	    const history= useHistory();

    return (
        <Speakers classes={classes} history={history}  />
    )
}

  