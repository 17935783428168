import { ThemeProvider,Box,Grid } from "@material-ui/core";
import React, { Component, Suspense } from 'react';
import { Academytheme, useStyles } from "../../functions/useStyles";



const setButtonText="Login";
const location = window.location.pathname;
let cls="";



const RegisterForm = React.lazy(() => import('./RegisterForm'));
const CarouselCard = React.lazy(() => import('../login/CarouselCard'));



class Login extends Component {

    constructor(props) {
    super(props);
  }


  
 
    render() {
	const classes = this.props.classes;

	return(
    <ThemeProvider theme={Academytheme}>
	
	<Grid container 
			  direction="row"
			  alignItems="center"
			  justify="center"
			  spacing={0}>
  
        <Grid item xs={12} sm={12} lg={8}>
           <Suspense fallback={<div> </div>}>
			<CarouselCard /> 
			</Suspense>
        </Grid>
		
		
        <Grid item xs={12} sm={12} lg={4}>
            <Suspense fallback={<div> </div>}>
			<RegisterForm /> 
			</Suspense>
        </Grid>
        
        
     </Grid>
    
	</ThemeProvider>  
	)
  
    }
}
export default () => {
    const classes = useStyles();
    return (
        <Login classes={classes} />
    )
}



