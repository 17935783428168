import { Box, Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";



const useStyles = makeStyles((theme) => ({
  back:{backgroundColor:"#25387C",'min-height':"233px"}
}));

class Scroll extends Component {
constructor(props) {
    super(props);

	this.state={webinar_list:[],speaker_list:[]}}

	
    render()
	{
		const classes = this.props.classes;

	const ProgressCard = () => {
		
		return (
		<Grid item xs={12} container direction="row"
                    alignItems="center"
                    justify="center"  className={classes.back}>
		<Grid item xs={12}  >		
				
							
					  <Box padding={4}>
					   <Box display="flex" textAlign="center" fontWeight="600" >
					   <Typography >
					  <Box fontWeight="600" lineHeight={1.25} fontSize="28px" 
					  color="white" paddingLeft='20%' paddingRight='20%'>					
					  Be a part of this great initiative in building a better society for women and encourage youth for Innovation & Entrepreneurship.
					   </Box>
					   </Typography>
					   </Box>
					   </Box>
					
		</Grid>
		</Grid>
	)}
	
		return ( 
		<Box marginBottom={6} marginTop={8}>
		<ProgressCard/>
		</Box>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
