
import {
	Button,Box, Chip, CircularProgress, 
	Grid, Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import axios from 'axios';
import SearchBar from "material-ui-search-bar";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
//import { LocalizationProvider ,AdapterDateFns  } from "@material-ui/lab/LocalizationProvider";
import "react-sweet-progress/lib/style.css";
import AlertBox from "../../AlertBox";
import { deleteLocal, getCacheDetails } from "../../caching";
import { api, findCourse, get_name_slug,generateProfileUrl, mentors_category } from "../../Helper";
import { ViewMentors_Skeleton } from '../../Skeleton';
import { useStyles } from "../../useStyles";
import RenderCard from './RenderCard';
import VerticalRenderCard from './VerticalRenderCard';






let student_id=null;
let email=null;

let token=localStorage.getItem('login_token')
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	}
if(token=="admin")
{
	student_id="admin"
}
class Scroll extends Component {

    constructor(props) {
    super(props);
	this.findCourse = findCourse.bind(this);
	this.get_name_slug = get_name_slug.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	course_list:[],mentors_list:[],
	progress_com:0,progress_left:9,loading:true,hasMore:true,
	start:0,end:10,
	msg:false,msg_type:"success",
	alert_msg:false,
	profile_popop:false,
	apply_popop:false,start_time:"",end_time:'',
	slots:null,date:null,
	search:'',search_with:false,
	btn_loading:false,
	hover:false,active_card:null,
	profile_details:[],mentor_id:null,
	
	mentorship_popop:false,
	dashboard_mentor_arr:[]
	}
    }
	
		
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(student_id+"_all_mentors_")
		    let keys_2=this.deleteLocal(student_id+"_mentors_")
		    let keys_4=this.deleteLocal(student_id+"_my_mentors_")
			let keys_3=this.deleteLocal(student_id+"_upcoming_mentorship_")		
			let keys_5=this.deleteLocal(student_id+"_all_mentors_0_to_")		
				let keys_6="user_"+student_id+"_mentor_slot_"
				let keys_7="mentor"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6,keys_7]
				return keys
	}
	
	
	
	setCustomState=(state,val)=>{
		this.setState({[state]:val},()=>{console.log(this.state[state])})
	}
	
	
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		this.setCustomState("btn_loading",true);
		setTimeout(() => {
			
			this.setCustomState("start",this.state.start+this.state.end)
			if(this.state.search!='')
			{
			
				this.get_search_record(this.state.start,true,this.state.search,this.state.search_with)
			}
			else 
			{
				this.getCachePlay(this.state.start,this.state.end,true)
			}
			
		}, 1500);
	}}
	componentDidMount()
	{
		//this.getCachePlay(this.state.start,this.state.end,false)	
		setTimeout(() => {
		//this.setState({ mentors_list: k })
			if(this.props.location.state==undefined)
			{
				this.getCachePlay(this.state.start,this.state.end,false)	
			}
			else 
			{
				if(this.props.location.state['action']=="slot")
				{
					this.getCachePlay(this.state.start,this.state.end,false)
					let name=this.props.location.state['mentor_name']
					name=name.trim()
					this.setCustomState("search",name)
					this.search_mentors(this.state.search,"name")
					
				}
				else
				{
					let tags=this.props.location.state['industry']
					this.search_mentors(tags,"tags")					
				}

			}
			
		}, 500);
	}
	

	handleCacheCallback = (callback_data) => {
		if(![undefined,null,''].includes(callback_data))
		{
			let arr=callback_data.split(",")
			this.search_mentors(arr[1],"tags")
		}
		else
		{
			this.setState({start:0},()=>{console.log(this.state.start)})
			//this.getCachePlay(this.state.start,this.state.end,false)
			this.getCachePlay(0,5,false)	
		}

	}
	
	get_search_record = (start,fetch,search,search_with) => {

		let payload={
			'email':email,
			'start':start,
			'end':this.state.end,
			'key':start+"_popular_"+search_with+"_"+search,
			'search':search,
			'search_with':search_with,
			'action':'',
			'token':token,
			
		}
		//alert(this.state.start)
		//alert(payload.key)
		//console.log(payload)
		axios.post(api+'/search_mentor',
		payload)
		.then((response) => {
			
				let data=response.data
				//console.log(data)

				this.setCustomState("loading",false)
				if(data.length<this.state.end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					
					this.setState({ mentors_list: data })
				}	
				else
				{
					if (data.length>0)
					{
						this.setState({
						 mentors_list: [...this.state.mentors_list,...data]
						})
					}
					else
					{
						this.setState({ msg: "You have seen it all!" })
					
					}

				}
				//console.log(this.state.mentors_list)
		});	
	}
	handleParent=()=>{
		//alert("date - "+e);
		//e.preventDefault();
		//alert(new date("2022-06-27 04:51:17"))

		this.setCustomState("apply_popop",false)			
		this.setCustomState("start",0);
		this.getCachePlay(this.state.start,this.state.end,false)
		this.setCustomState("hasMore",true)

	}	
	getCachePlay = (start,end,fetch) => {
		
		//alert(end)
		//alert(fetch)
		this.getCacheDetails(
		student_id+"_all_mentors_"+start+"_to_"+end,
		"get_all_mentors",
		[email,start,end])
		.then(data => {	
			localStorage.removeItem(student_id+"_all_mentors_"+start+"_to_"+end)	
			//alert(data.length)
				
				this.setCustomState("loading",false)
				if(data.length<end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					
					this.setState({ mentors_list: data })
				}	
				else
				{
					if (data.length>0)
					{
						this.setState({
						 mentors_list: [...this.state.mentors_list,...data]
						})
						//console.log(this.state.mentors_list)

					}
					else
					{
						this.setState({ msg: "You have seen it all!" })
					}

				}
				this.setCustomState("btn_loading",false);
				
		});	
	}

	
	handleClose = () => {
		this.setCustomState("profile_popop",false)
		this.setCustomState("apply_popop",false)
		this.setCustomState("mentorship_popop",false)
	}
	
	
	
	
	remove_key = () => {
		this.setCustomState("search_with",false);
		this.setCustomState("search",'');
		this.setCustomState("hasMore",true);
		this.setCustomState("start",0);
		this.getCachePlay(0,this.state.end,false)
	}
	

	handleSlot = (event) => {
		this.setCustomState("slot_value",event.target.value)
	    this.search_mentors(event.target.value,"slots")
	}
		
	search_mentors = (tag,search_with) => {
		
		this.setCustomState("search",tag)
		this.setCustomState("start",0)
		
		this.setState({start:0},()=>{console.log(this.state.start)})
		//alert("set to 0 "+this.state.start)
		this.setCustomState("hasMore",true)
		this.setCustomState("search_with",search_with)
		
		this.get_search_record(0,false,tag,search_with)
	}

	
    render()
	{
		const classes = this.props.classes;

	
		
		
		
	const MentorsProfile = () => {
		let arr=this.state.mentors_list;
		arr=window.location.pathname=="/mentors-dashboard"?arr.slice(0,2):arr
		
		return (
		<InfiniteScroll
		  style={{ overflow: 'hidden' }}
		  dataLength={this.state.mentors_list.length}
		  hasMore={this.state.hasMore}
		  next={this.fetchMoreData}
		  loader={
			this.state.hasMore ? (
			  <Box marginTop={2} marginBottom={2} 
			  alignItems='center'
			display='flex'
			flexDirection='column'>
				{this.state.btn_loading ? (
				  <CircularProgress disableShrink />
				) : (
				  <Button
					variant="outlined"
					size="medium"
					color="primary"
					disabled={this.state.btn_loading}
					onClick={this.fetchMoreData}
				  >
					Load More
				  </Button>
				)}
			  </Box>
			) : (
			  <CircularProgress disableShrink />
			)
		  }


			endMessage={
				<>
				{window.location.pathname=="/mentors-and-experts"?'':
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>}
				</>
			}>
				<Box >
				<Grid container spacing={2}>
				{
		
				arr.map((e) => (
				<>
				{
					(e['has_global_request']== "1" && e['professor_id']==student_id) ||	
					(e['status']== "0" && e['professor_id']==student_id) ||
					e['status']== "1" ||
					token=="admin"
					
					?
						this.props.action=="tab" 
						?						
							<Grid item xs={12} md={6} >
							<VerticalRenderCard 
							user={e}
							action={this.props.action}
							parentCacheCallback={this.handleCacheCallback} />
							</Grid>
						:
							<Grid item xs={12} md={6} >
							<RenderCard 
							user={e}
							action={this.props.action}
							parentCacheCallback={this.handleCacheCallback} />
							</Grid>
					:""
				}
				</>
							
			))}
			</Grid>
			</Box>
		</InfiniteScroll>		
		)
	}
	{/*style={{'box-shadow': 'rgb(140 152 164 / 25%) 2px -3px 6px 0px',
		'border-bottom-left-radius':'0px','border-bottom-right-radius':'0px'}}
	*/}
			
			
  				
    const SlotsChip = () =>
	{ 
			let slot_chip=[
			{"id":"all","title":"All"},
			{"id":"this-week","title":"This Week"},
			{"id":"next-week","title":"Next Week"},
			{"id":"this-month","title":"This Month"},
			{"id":"next-month","title":"Next Month"}]
			
			if(localStorage.getItem('login_token')=="admin")
			{
				slot_chip.push({"id":"request","title":"Activation request"})
			}
			return(	
			<Box>
			<Box>
					  <Typography variant="h6" color="textPrimary" >
						<Box fontWeight="600" marginBottom={1}>Available Slots</Box>
					 </Typography>
					 
					 
					 <Box display="flex">
					   <Grid container spacing={2}>
					   {slot_chip.map((e) => (
							
							<Grid item xs="auto">
							<Chip label={e['title']} 
							clickable onClick={() => {this.search_mentors(e['id'],"slots")}}
							color={e['id']==this.state.search?"secondary":"primary"}  />
							</Grid>
							
						))}
						</Grid>
						</Box>
					
			</Box>
			</Box>
			)
	}			
    const CategoriesCard = () =>
	{ 
			
			return(	
			<Box padding={0}>
					  <Typography 
					  variant={this.props.action=="tab"?"subtitle1":"h6"}
					  color="textPrimary" >
						<Box fontWeight="600" marginBottom={2}>Popular Experts Category</Box>
					 </Typography>
					 
					   <Box display="flex" marginBottom={2}>
					   <Grid container spacing={2}>
					   {mentors_category.map((e,index) => (
							
							<Grid item xs="auto" key={index}>
							
							<Chip label={<Box fontWeight="bold" fontSize="12px">{e}</Box>} 
							 variant={e==this.state.search?"contained":"outlined"}
							 color={e==this.state.search?"primary":""}
							clickable onClick={() => {this.search_mentors(e,"tags")}}
							/>
							</Grid>
							
						))}
						</Grid>
						</Box>

			</Box>
			)
	}
	return ( 
		
	
		<Box>
		{
			this.state.alert_msg!=false
			? <AlertBox show_status={true} msg={this.state.alert_msg} msg_type={this.state.msg_type} />
			:''
		}
		
			<Grid container  spacing={3}>
			{this.props.action!="tab" &&(
			<Grid item xs={12} sm={7}>
			<Box marginBottom={2} marginTop={2}>
			<SearchBar
				value={this.state.search}  
				onChange={(newValue) => {this.setState({ search: newValue })}}
				onRequestSearch={() => {this.search_mentors(this.state.search,"name")}}
				style={ {
					margin: "0 auto",
					width: '100%',
					'box-shadow': '0px 0px 0px 1px rgb(0 0 0 / 20%)',
					'border-radius': '4px',
					'fontFamily':"Be Vietnam, sans-serif"
				}} />
			<Box marginTop={2}  marginBottom={4} >
			<SlotsChip />
			</Box>
			</Box>
			</Grid>
			)}
			
			<Grid item xs={12} sm={this.props.action=="tab" ? 12 : 5}>
				<CategoriesCard />
			</Grid>
			
			<Grid item xs={12} sm={this.props.action=="tab" ? 12 : 5}>
				
				
			{
			this.state.search!='' &&(
			<Box marginTop={0}  margin={4} display={"block"}>
			<Typography variant="subtitle1" >
			<Box fontWeight="bold">Searched keys</Box>
			</Typography> 
			<Box display="flex" direction="row" className={classes.chip}   borderColor="grey.300"  
			style={{backgroundColor: '#fbfbfb'}}
			border={1}  margin={1} padding={2}  borderRadius={12} > 
			{
				<Chip label={this.state.search} 
				onDelete={()=>{this.remove_key()}} 
				variant="outlined"
				deleteIcon={<CancelIcon style={{color:"#25387c"}} />}/>
			}
			</Box>	
			</Box>)
			}
			</Grid>
			
			</Grid>
			
			
			<Box marginTop={2}>
			{
				this.state.loading==true
				?<ViewMentors_Skeleton action="mentors" />
				:
					this.state.mentors_list.length>0
					?
						<MentorsProfile />
					:
						<Typography 
						variant="subtitle1" 
						color="textSecondary" 
						align="center">
						Experts cannot be found.
						</Typography>
			}
			</Box>
			</Box>
		
				
	);
  }
}


export default (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
	return (
        <Scroll  
		classes={classes} 
		location={location}  
		history={history}
		parent={props.parent}
		action={props.action}
		/>
    )
}
