import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import React, { Component } from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useHistory,useLocation } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { api } from "../../Helper";
import { deleteLocal } from "../../caching";
import './index.css';
//import quizQuestions from './quizQuestions';
import Result from './Result';

let student_id=null
let status=null
let lname=null
let fname=null
let email=null
if([null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
	lname=JSON.parse(localStorage.getItem('ac_login_user'))['lname']
	
	lname=lname==null || lname=="null" ?"":lname

}	

//alert(student_id)
let quizQuestions=[];
let quizAnswers=[];
let questionLimit=2
class QuizRender extends Component {
  constructor(props) {
    super(props);

	this.deleteLocal = deleteLocal.bind(this);
    this.state = {
	  null_state:this.assign_state(),
      currentDateTime:new Date().getTime(),
	  incorrect:'',incorrect_ans:'', checkedA: false,
	  skipped_ans:'',skipped:'',
      result: '',
	  correct:0,msg:null,msg_type:'warning',
	 // duration:JSON.parse(this.init_state("quizQuestions")).length*60,
	 duration:JSON.parse(this.init_state("quiz_timer"))*60,
	  marked : new Array(quizQuestions.length).fill(0),
	  value:null,quiz:'started',
	  quizShow:[],
	  steps:quizQuestions.length==1?0:Math.ceil(quizQuestions.length/questionLimit)-1,
	  currentStep:0,
	  start:0,end:quizQuestions.length>questionLimit?questionLimit:quizQuestions.length

    };

  }
  
  init_state=(variable)=>{
		
		let k=null
		if((window.location.pathname).startsWith("/quiz")==true)
		{
			k=this.props.location.state[variable]				
		}
		else
		{
			k=this.props.quiz_data[variable]
		}
		return k;
	}
	
	assign_state=()=>{

		console.clear();
		quizAnswers = (this.init_state('quizAnswers')).split(",");
		//console.log(quizAnswers);	

		
		quizQuestions = this.init_state("quizQuestions");
		quizQuestions = JSON.parse(quizQuestions)
		//console.log(quizQuestions);		
		//console.log(this.props.location.state);		
		
	
	}
  componentDidMount() {

	//console.log(this.props.location.state)
	this.setState({quizShow:quizQuestions.slice(this.state.start,this.state.end) });
    const shuffledAnswerOptions = quizQuestions.map(question =>
      this.shuffleArray(question.answers)
    );
		//console.log(quizQuestions)

    this.setState({
      question: quizQuestions[0].question,
      answerOptions: shuffledAnswerOptions[0]
    });
	
	
		document.addEventListener("contextmenu", (event) => {
		event.preventDefault();
	});
	
  }

  shuffleArray(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }
	diff_minutes(dt2, dt1) 
	{

	  let diff = dt2 - dt1;
	  let seconds = Math.floor(diff / 1000 % 60);
	  let hours   = Math.floor(seconds / 3600); 
	  let minutes = Math.floor((seconds - (hours * 3600)) / 60); 
	  if (hours   < 10) {hours   = "0"+hours;}
	  if (minutes < 10) {minutes = "0"+minutes;}
	  if (seconds < 10) {seconds = "0"+seconds;}
	  return hours+':'+minutes+':'+seconds;  
	  
	}
	checkHonor() 
	{
		if(this.state.checkedA==false)
		{
			this.setState({msg:"Please mark the declaration "})
			setTimeout(() => {this.setState({msg:null})}, 2000);
		}
		else
		{
			this.setState({result:'true'})
			this.getResults(); 
		}
	}
	getResults() 
	{
	   	this.setState({quiz:'done'})
		let correct=0;
		let skipped=[]; let skipped_ans=[];
		let incorrect=[]; let incorrect_ans=[];
		let completed_on=this.diff_minutes(new Date().getTime(),this.state.currentDateTime)
		this.setState({currentDateTime:completed_on})
		this.state.duration=86400
			
		for(let i=0;i<quizAnswers.length;i++)
		{
			if (this.state.marked[i]==quizAnswers[i])
			{
				correct++;
						
			}	
			else
			{
				if (this.state.marked[i]==0)
				{
					skipped.push(i);
					this.setState({ skipped: skipped });
							
					skipped_ans.push(quizAnswers[i]);
					this.setState({ skipped_ans: skipped_ans });
				}
				else
				{
					incorrect.push(i);	
					this.setState({ incorrect: incorrect });
				
					incorrect_ans.push(quizAnswers[i]);
					this.setState({ incorrect_ans: incorrect_ans });
				}	
			}
		}
		
		this.setState({correct:correct})
		this.renderResult();
		
		if(status=='student')
		{
			let keys=this.deleteKeys();
			let payload = 
			{	'quiz_id':this.init_state('quiz_id'),
				'quiz_name':this.init_state('quiz_name'),
				'student_id':student_id,
				'course_id':this.init_state('course_quiz_id'),
				'email':email,
				'name':fname,
				'total_quest': quizQuestions.length,
				'score':  Math.floor( correct/quizQuestions.length * 100),
				'grade':Math.floor( correct/quizQuestions.length * 100)<40?"C":Math.floor( correct/quizQuestions.length * 100)<70?"B":"A",
				'correct':correct,
				'completed_on':completed_on,
				'search_by':'insert',
				'operation':'Student Quiz',
				'keys':keys.toString()
			}
				
			axios.post(api+'/addUpDeQuiz',payload)
			.then((response) => {
			//console.log(response.data);
			})
		}
	}
	deleteKeys=()=>{
			
		let progress_keys=this.deleteLocal(student_id+"_quizProgress_")
		let graph_keys=this.deleteLocal(student_id+"_quizGraph")
		
		let keys=progress_keys.concat(graph_keys)
		return keys
	}
	setPara(currentStep)
	{
		let start=currentStep*questionLimit;
		let end=start+questionLimit;
		this.setState({
			currentStep:currentStep,
			start:start,
			end:end,
		})
		this.setState({quizShow:quizQuestions.slice(start,end) });
		//alert('currentStep'+currentStep)
		//alert('start'+start)
		//alert('end'+end)  
	}
	
	setSteps(functionCall) 
	{
		if(functionCall=='next')
		{
			this.setPara(this.state.currentStep+1);
		}
		else if(functionCall=='prev')
		{
			 this.setPara(this.state.currentStep-1);
		}
		else
		{		

			this.checkHonor();
		}
	}
  
	renderResult()
	{
		////console.log(quizQuestions);
		return <Result quizResult={this.state.result} 
		total={quizQuestions.length} 
		correct={this.state.correct} 
		grade={Math.floor( this.state.correct/quizQuestions.length * 100)} 
		duration={this.state.currentDateTime}
		incorrect={this.state.incorrect}
		incorrect_ans={this.state.incorrect_ans}
		skipped={this.state.skipped}
		skipped_ans={this.state.skipped_ans}
		quizQuestions={quizQuestions}
		/>;
		
	 }

	getIndex(index)
	{
		let d=index
		if(this.state.currentStep!=0)
		{
			d=this.state.currentStep*questionLimit+index+1;
			if(index==0)
			{
				d=this.state.currentStep*questionLimit+1;
			}
		}
		else 
		{
			d=d+1
		}
		return d
	}
	render()
	{

		const formatRemainingTime = time => {
		let  minutes = Math.floor((time % 3600) / 60);
		let seconds = time % 60;
 		return `${minutes}:${seconds}`;
		};

		const renderTime = ({ remainingTime }) => {
			if (remainingTime === 0)
			{
				let t="Time Out ! Better luck next time "
				//this.setState({msg:t})
				this.forceUpdate();
				//alert(t)
				setTimeout(() => {
					window.location.pathname="/dashboard-course"
				//	this.props.history.goBack();
					/*if(window.location.pathname=="/course-modules")
					{
						//this.props.parentCallback();
						this.props.history.push({
							pathname: '/dashboard-course'
							})
					}
					else
					{
						//this.props.history.goBack();
						this.props.history.push({
							pathname: '/dashboard-course'
							})
					}*/
					// window.location.pathname="/dashboard-course"
			        //alert("redirect")
			   
				}, 2000);
				return <div className="timer">Too late...</div>;
			}
			return (
				<Box marginTop={3}  paddingRight={5} >
				<Typography  variant="subtitle1" color="textPrimary">
				{formatRemainingTime(remainingTime)}
				</Typography>
				</Box>
			  );
		};
	
	const RenderQuestion = ({question,index}) => {
        let arr=String(question)
        arr=(arr).split("<br>");
	    return (
			<Box  display="block" textAlign="left"  >
            <Box display="flex" textAlign="left" marginBottom={1}>
			  <Box fontWeight={600}>{index+". "}&nbsp;</Box>
			  <div dangerouslySetInnerHTML={{ __html: question }} />
			 </Box>
			</Box>
	)}	
		const RenderQuiz = () => {
			
			let buttonText='';
			let functionCall='';
			if(this.state.currentStep==this.state.steps)
			{
				buttonText='Submit';
				functionCall='submit';
			}
			else
			{
				buttonText='Save & Next';
				functionCall='next';
			}
				
			return (
			<>
				{
					this.state.quizShow.map((e, index) => (
						
					<Box padding={4}>
						
					<RenderQuestion question={e['question']} index={this.getIndex(index)} />
					
						<Box marginTop={1} marginBottom={1}  marginLeft={3} >
						{e['answers'].map((ex, i) => (
							<>
							<RadioGroup aria-label={e['answers']} name="gender1" 
							value={this.state.marked[this.getIndex(index)-1]}
							onChange={ (event) => {
									
								let a = this.state.marked.slice(); 
								a[this.getIndex(index)-1] = event.target.value;
								this.setState({marked: a});
									 
							}}   >
							<FormControlLabel value={ex['type']} control={<Radio  size="small"  color="primary" />} 
							label={<Typography    color="textSecondary"  variant="subtitle1" >
							{ex['content']}
							</Typography>} />
							</RadioGroup>
							{/*	<Divider />*/}
							</>
						))}
						</Box>

						
						</Box>
						
					))
				}
				
			
				{ this.state.currentStep==this.state.steps ?
				<Box paddingLeft={4} paddingRight={4}>
				<Divider />
				
				<Box  marginTop={2}  marginBottom={2} paddingLeft={4} paddingRight={4} >
 
				
				<FormControlLabel
					control={
					<Box paddingBottom={2} paddingRight={2}>
					<Checkbox
						checked={this.state.checkedA}
						onChange={(event) => {this.setState({ checkedA: event.target.checked }) }}
						name="checkedA"
						color="primary"
					  />
					</Box>
					}
					labelPlacement="end"
					label=
					{<Typography  variant="subtitle1" style={{color:'green'}}>
					<Box lineHeight={1.5}>
					{"I, "+fname+" "+lname+", understand that submitting another’s work as my own can result in zero credit for this assignment. Repeated violations of the Opengrowth Academy Honor Code may result in removal from this course or deactivation of my Opengrowth Academy account. "}
					</Box>
					</Typography>}
				 />
				</Box>
				</Box>:''
				}
				
				
				<Box display="flex" justifyContent="flex-end" >
				
				{
					this.state.currentStep!=0 
					?<Box marginRight={1}><Button variant="contained" size="large"
					color="secondary"
					onClick={()=>{this.setSteps('prev')}}>Previous
					</Button></Box>
					:''
				}
					<Box><Button variant="contained" size="large" 
					color="primary"
					onClick={()=>{this.setSteps(functionCall)}} >{buttonText}</Button>
					</Box>
				</Box>
			</>
			);
		}
		
		
			
		return(
		<>
		{ 	
					this.state.msg!=null 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type="warning" />
					: ''
					
		}
		{
			(window.location.pathname).startsWith("/quiz")==true
			?<Card   style={{height: 'unset'}}>
			<CardHeader title={this.init_state('quiz_name')} />
			<CardContent >
			<>
			
			<Box  display={this.state.quiz=='done'?'none':'flex'}>
			<Box flexGrow={1} >
			 	
			<Typography component="div" variant="subtitle1" color="textPrimary">
			<Box display="inline"  fontWeight="fontWeightBold">Total Question : </Box>
			{quizQuestions.length}  
			</Typography>
			
			</Box>
			<Box>
			  	
			<Box display="flex" paddingRight={3}  >
			
			<Box display="inline"  fontWeight="fontWeightBold"  fontSize="subtitle1.fontSize">
			 Remaining Time :
			</Box>
			
			<Box  marginLeft={5}>
			<CountdownCircleTimer
				  isPlaying
				  duration={this.state.duration}
				  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
				  onComplete={() => [false, 1000]}
				  size={0} strokeWidth={0} strokeLinecap='square' 
				>
				{renderTime}
			</CountdownCircleTimer>	
			</Box>
			
			</Box>
			</Box>
			
			<Box marginTop={2} marginBottom={2}  >
			<Divider />
			</Box>
			
			</Box>
				
			
			
			
			{this.state.result ? this.renderResult() : <RenderQuiz />}
			
			</>
			</CardContent>
			</Card>
			:<>
			
			<Box  display={this.state.quiz=='done'?'none':'flex'}>
			<Box flexGrow={1} >
			 	
			<Typography component="div" variant="subtitle1" color="textPrimary">
			<Box display="inline"  fontWeight="fontWeightBold">Total Question : </Box>
			{quizQuestions.length}  
			</Typography>
			
			</Box>
			<Box>
			  	
			<Box display="flex" paddingRight={3}  >
			
			<Box display="inline"  fontWeight="fontWeightBold"  fontSize="subtitle1.fontSize">
			 Remaining Time :
			</Box>
			
			<Box  marginLeft={5}>
			<CountdownCircleTimer
				  isPlaying
				  duration={this.state.duration}
				  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
				  onComplete={() => [false, 1000]}
				  size={0} strokeWidth={0} strokeLinecap='square' 
				>
				{renderTime}
			</CountdownCircleTimer>	
			</Box>
			
			</Box>
			</Box>
			
			<Box marginTop={2} marginBottom={2}  >
			<Divider />
			</Box>
			
			</Box>
				
			
			
			
			{this.state.result ? this.renderResult() : <RenderQuiz />}
			
			</>
		}
		</>
		
    );
  }
}
export default (props) => {
    const history = useHistory();
    const location = useLocation();
    return (
        <QuizRender 
		history={history}
		parentCallback={props.parentCallback}
		quiz_data={props.quiz_data}
		location={location} />
    )
}

