
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import {
	createTheme
} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";








export default class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
		reasons:null,webinar_id:this.props.webinar_id,
		reg:false,reasons_list:[],
		errors: {reasons:"Initalize",webinar_id:"Initalize"}
	}
    }
	
		
	componentDidMount()
	{
		
		if(localStorage.getItem('login_token')!='admin')
		{
			window.location='/webinars'
		}
	
		this.getReasonsList();

		
	}
	validForm=()=>
	{
		const registerList = ['reasons'];
		let allFilled=false;
		this.setState({reg:false});
		for (let i = 0; i < registerList.length; i++)
		{
			let value=registerList[i];
			allFilled=this.checkError(value,this.state[value]);
		    if (allFilled==false)
			{
				break;
		    }
		}
		return allFilled
	}
    checkError=(name,value)=>
    {
   		this.state.errors[name]=value==null||value==''?" All fields are required":"Initalize";
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
	}
	  
     
    
	handleSubmit = (e) =>
	{
		e.preventDefault();
		let isValidate=this.validForm()
		
       
		if(isValidate==true)
		{
                    this.setState({loading: true});
					axios.post('/api/addToReasonList',
					{
						'webinar_id': this.state.webinar_id,
						'reasons': this.state.reasons,
                        'key':"webinar_reason_"+this.state.webinar_id

					})
					.then((response) => 
					{
                        //console.log(response.data);
						if(response.data[0]['status']=="success")
						{
							this.setState({reg: "Reason added to the list successfully"});
                            this.getReasonsList()
						}
						else
						{
							this.state.reg="Try Again";
						}
					}, (error) => 
					{
						//console.log(error.data);
					});
					this.setState({loading: false});
					setTimeout(() => {this.setState({reg:false})}, 2000);
		}
       
   
	}
	
	
	getReasonsList=()=>
	{
		//alert()
		axios.post('/api/getReasonsList',
		{'webinar_id': this.state.webinar_id,
         'key':"webinar_reason_"+this.state.webinar_id
        })
		.then((response) => 
		{
			this.setState({reasons_list:response.data})
			//console.log(response.data)
		})
	}
	
	
	
	handleDel=(id)=>
	{
		axios.post('/api/deleteReasons',
		{'id': id,
         'key':"webinar_reason_"+this.state.webinar_id})
		.then((response) => 
		{
			this.setState({reg:response.data['msg']});
		})
        this.getReasonsList()
	}

	
  	render()
  	{
		

		const { errors } = this.state;

		
		
		const GetReasons = () => {
		return (
		<>
            {
            
            this.state.reasons_list.map((e) => (
            
             <Box padding={2} display="flex" marginBottom={2} >
				
     			<Box  flexGrow={1} >
				<Typography variant="subtitle1"  color="textPrimary">
				{e['reasons']}
				</Typography>
				</Box>
				
				
				<Box display={e['status']=='found'?"block":'none'}>
				 <IconButton  onClick={()=>{this.handleDel(e['id'])}}>
						<CloseIcon color="grey"   />
				  </IconButton> 
				</Box>
				
			 </Box>	
            ))}
        
        </>
		  
		)}

		
	
		return(
		<Box marginTop={2} marginBottom={2}>
        <Card style={{height:'fit-content'}} elevation={3}>
		
		{ this.state.reg!=false  
			? <AlertBox show_status={true} 
			msg={this.state.reg}
			msg_type="success" />
		: ''}
		
		
		<CardHeader title={
			
		<Box display="flex">
				
				 
                <Box flexGrow={1}>
				<Typography variant="h6"  color="textPrimary">
				{this.props.webinar_id +' - '} Why Should I Attend (Reasons)
				</Typography>
				</Box>	
				
		</Box>}
		/>
		<CardContent>
		<Grid container direction="row" spacing={0}>
		
		<Grid  xs={12}>
			 <Box padding={0} >
			
				
				<form role="form" >
				<Grid container spacing={2}>
				
                                
								<Grid item xs={12} sm={6}>
									<TextField label="Reasons" color="primary" variant="outlined"  name="reasons" type="text"  onChange={event=>{this.setState({reasons: event.target.value}) }} 
									error={ errors.reasons!="Initalize" ? errors.reasons: ''} value={this.state.reasons} fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.reasons!="Initalize" ? errors.reasons: ''}
									</Typography>
									</Box>
								</Grid>
								
              
								<Grid item xs={12} sm={2}>
								<Box display="flex" flexDirection="row-reverse" >
								
								<Button variant="contained" size="medium" 
								style={{padding:'11px'}}
								color="primary"  onClick={this.handleSubmit} 
								fullWidth
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>
                                   Add reason</span>}
								</Button>
								
								</Box>
                                
                                
								</Grid>
								
								
							
							</Grid>
							</form>
				
				
			 </Box>		
		</Grid>    
        
        		    
		<Grid  xs={12}>
        <GetReasons/>
		</Grid>
        
		
		</Grid>
		</CardContent>
		</Card>
        
		
		</Box>
	    );
	
  	}
}

