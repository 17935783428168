
import {
	Avatar, Box, Button, Card, CardActionArea, CardHeader, CircularProgress, Grid,Switch, Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory, useLocation } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { deleteLocal } from "../../caching";
import { api, translateText, userProfile_path } from "../../Helper";
import axios from 'axios';
import { UserContext } from '../../courses/UserContext';
import React, { Component, useContext } from 'react';

//import {useStyles} from "../useStyles";
const styles = {

  root: {width:'100%'  },
  button: {
    color: 'white',
    backgroundColor: '#25387c',
    margin: 10
  },
  large :{height:'150px',width:'150px',border:'3px solid #e5e5e5'}
};

let user_email=null;
let user_img=null;
let id=null;
let status=null;
let user_visible=0;
if([null,"admin","webinar"].includes(localStorage.getItem('login_token'))==false)
{
	user_email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	user_img=JSON.parse(localStorage.getItem('ac_login_user'))['img']
	id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	user_visible=JSON.parse(localStorage.getItem('ac_login_user'))['profile_visible']
}

class UserImg extends Component {

    constructor(props) {
    super(props);
    this.deleteLocal  = deleteLocal.bind(this);
    this.translateText = translateText.bind(this);
	this.state={checkedA: true,
    checkedB: true,
	    profileImg:this.get_img(),
	    visible:user_visible,
		upload_status:false,
		upload:false,
		loading:false,
		imgDetails:false,changesFound:false,
	}
    }
	get_img = () => {
		let img=null
		if([null,"admin","webinar"].includes(localStorage.getItem('login_token'))==false)
		{
				img=JSON.parse(localStorage.getItem('ac_login_user'))['img']
		}
		return img;
	}
	handleChecked = event => {
		let visible_state=!this.state.visible
		this.setState({visible: visible_state})
		this.update_profile_status(visible_state)
	}
	
    imageHandler = (type,event) => {
	this.setState({imgDetails: event.target.files[0]});
		let reader = new FileReader();
		reader.onloadend = () => {
		this.setState({profileImg: reader.result});
		}
		reader.readAsDataURL(event.target.files[0])
		         
		let file=event.target.files[0]
		console.log(file)
		let name = file.name;
				console.log(name)

       if(name.match(/\s/g))
		{
			this.setState({
				 upload_status:'Filename cannot contain whitespaces.',
				 profileImg:false})
				 
		}
		else if(name.length>20)
		{
			this.setState({
				 upload_status:'Filename length must be <=20 characters.',
				 profileImg:false})
		}
		else if (file.size > 200 * 1024) 
		{
			this.setState({
				upload_status: 'File size must be less than 200KB.',
				profileImg: false
			});
		} 
		else
		{  
			  if(!name.match(/\.(jpg|JPG|jpeg|png|PNG|gif)$/)) 
			  {
				 this.setState({upload_status:'Please upload an image file.',
				 profileImg:false})				
			  }
			  else
			  {
				  		this.setState({changesFound:true});

			  }

		}
 	    setTimeout(() => {this.setState({upload_status:false})}, 2000); 

	}
    checkIfImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
	}
	update_profile_status = (visible_state) => {
		
		let visible=visible_state==true?1:0;
		let payload = {
		'table_access': status,
		'id':id,
		'email':user_email,
		'profile_visible':visible}
		
		axios.post(api+'/update_profile_visibility',payload)
		.then((response) => {
		
		if(response.data['msg_type']=="success")
		{
			let t=JSON.parse(localStorage.getItem('ac_login_user'))
			localStorage.removeItem('ac_login_user')
			t['profile_visible']=visible_state
			localStorage.setItem('ac_login_user',JSON.stringify(t));
			
		}
		this.setState({upload_status:response.data['msg']})
			
		//console.log(response.data);
		setTimeout(() => {
			this.setState({upload_status:false})
		},3000);	
		
		});
		
		setTimeout(() => { this.setState({upload_status:false})	},2000);	
	}
	
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(id+"_all_mentors_")
		    let keys_2=this.deleteLocal(id+"_mentors_")
		    let keys_4=this.deleteLocal(id+"_my_mentors_")
			let keys_3=this.deleteLocal(id+"_upcoming_mentorship_")		
			let keys_5=this.deleteLocal(id+"_Professor_recommendation_dashboard")		
			let keys_6=this.deleteLocal(id+"_all_mentors_0_to_")		
			let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6]
			return keys
	}
	
	handleSubmit = (e) =>
	{
		//alert(visible)

		if(this.state.changesFound==true)
		{
			this.setState({ loading: true});
		
		
			e.preventDefault();
			 const header = {}; //headers
			 let data = new FormData();
			 header.Accept = 'application/json';
			 if(this.state.imgDetails!=false )
			 {
				
				//alert(this.state.imgDetails['name'])
				let boolValue=this.checkIfImage(this.state.imgDetails)
				if(boolValue==true)	
				{	
					let n=(this.state.imgDetails['name']).substring(0, (this.state.imgDetails['name']).indexOf('.'))
					let img_name='user_'+id+'_'+status.toLowerCase()+'_'+n+'.png'
					
					this.setState({ upload: "Starts"});	
					data.append( 'file', this.state.imgDetails);
					data.append( 'image',this.state.imgDetails['name'] );
					data.append( 'imageFor',"profile" );
					data.append( 'table_access',status);
					data.append( 'id',id);
					data.append( 'email',user_email);
					data.append( 'image_name',img_name.toString());
					fetch(api+'/uploadfile',{
					method:"post",
					headers: header,
					body:data}).then((resp)=>{
						
						if(resp['status']==200)
						{
							this.setState({ loading: false});
							this.setState({ upload: "Uploaded"});
							
							let t=JSON.parse(localStorage.getItem('ac_login_user'))
							localStorage.removeItem('ac_login_user')
							localStorage.removeItem(user_email)
							localStorage.removeItem(user_email+"_profileData")
							
							t['img']=img_name
							//alert(this.props.user)
							//this.props.user.set_user_info(img_name)
							this.props.user.set_user_info((user) => ({
							   img: img_name
							}));
							
							
							//console.log(t)
							localStorage.setItem('ac_login_user',JSON.stringify(t));
							localStorage.setItem(user_email,JSON.stringify(t));
							localStorage.setItem(user_email+"_profileData",JSON.stringify(t));
							
							this.setState({upload_status:"Profile image updated successfully"});
							
							
							let keys=(this.deleteKeys()).toString()      
							axios.post(api+'/update_mentor',{
								'email':user_email
								})
							.then((response) => {
								//alert(response.data)
								//console.log(response.data)
							})
		


							
							
							setTimeout(() => {
								
							//window.location.pathname = '/dashboard';
									
							this.props.history.push({
							pathname: '/profile',
							  state: {
									profile_mail:user_email,
									profile_type:status,
									profile_id:id,
							  } })	
							}, 2000);
						}
						else
						{
							this.setState({upload_status:"Profile image update operation failed"});
						}	
						
						
					})
					.catch((error) => {
						this.setState({ loading: false});
					    this.setState({upload_status:error});
						console.error('Fetch error:', error);
					});
					this.setState({ changesFound: false});
				
				}
				else
				{
					this.setState({ loading: false});
					this.setState({upload_status:"Only image file are accepted"});
				}

			}
			else
			{
				this.setState({ loading: false});
				this.setState({upload_status:"Profile image upload failed"});
			}

		}
		else
		{
			this.setState({upload_status:"Profile image / Public profile has not been updated"});
		}
		setTimeout(() => {this.setState({upload_status:false})}, 3000);

	}
	
  	  	render()
  	{
		const classes = this.props.classes;
		return (
	       

		<div style={styles.root}>
       {
				this.state.upload_status!=false  
				? <AlertBox show_status={true} 
				msg={this.state.upload_status} 
				msg_type="success" />
				: ''
		}
		<Card >
		<CardHeader title="Upload profile image here !" />
		 <CardActionArea >
			<Grid
			  container
			  direction="column"
			  justifyContent="flex-start"
			  alignItems="center"
			>      
 
		
		<Grid item xs={12}>
		
		<Box marginTop={3} marginBottom={3}>
		{/*<Avatar    className={classes.large} src={this.state.selectedFile}  /> */}
		
		<label htmlFor="profile-image">
		  <input accept="image/*" id="profile-image" multiple type="file" 
		  onChange={(e) => this.imageHandler("Profile", e)}  hidden/>
			<Avatar style={styles.large} 
			src={this.state.imgDetails===false
			?userProfile_path+this.state.profileImg:this.state.profileImg} > 
		  </Avatar>
		</label>
		
		
		
        </Box>
		
        </Grid>
		
		<Grid item xs={12}>
		<Typography variant="subtitle2" color="textSecondary">
		Allowed *.jpeg, *.jpg, .*png, *.gif</Typography>
		
		{
			this.state.upload==='Starts'
			?
				<Typography  variant="subtitle2"  color="secondary">
				{
					this.state.upload_status===false
					?""
					:
						this.state.upload_status==="Image update operation failed"
						?"Uploading failed"
						:"Uploading ..."
				}
				</Typography> 
			:
				<Typography  variant="subtitle2"  color="secondary">
				{this.state.imgDetails['name']}
				</Typography> 
		}
		
        </Grid>
		
        <Grid item xs={12}>
        <Box marginTop={2} >
		<Typography component="div" variant="subtitle2" color="textPrimary">
		<Box fontWeight="fontWeightBold" display="inline">Public Profile </Box>
		<Switch
          color="primary"
          inputProps={{ 'aria-label': 'checkbox with default color' }}
		  checked={this.state.visible==1 || this.state.visible=='true' || this.state.visible==true ?true:false}
		  onChange={this.handleChecked}
		 />
		 </Typography>
		 </Box>
		</Grid>
        <Grid item xs={12}>
		
		
	<Box marginTop={1} marginBottom={5}>
	<Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
		onClick={this.handleSubmit}  disabled={this.state.loading}>
		{this.state.loading && <CircularProgress size={24} />}
		 {!this.state.loading &&  <span>Save image</span>}
		</Button>
		
		</Box>
		</Grid>
		 
		  </Grid>
        
		
		 </CardActionArea>
		</Card>
        </div>



	    );
  	}
}
export default () => {
    const location = useLocation();
    const history = useHistory();
	const user = useContext(UserContext);

    return (
        <UserImg  location={location}  history={history}  user={user} />
    )
}


 