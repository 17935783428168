import {
	Avatar, Box, Button ,  Card, CardContent, CardHeader, Chip, CircularProgress, Grid,
	List, ListItem, ListItemAvatar, ListItemText, Typography
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import axios from 'axios';
import clsx from 'clsx';

import React, { useEffect, useRef, useState ,useContext} from 'react';
import Autolink from 'react-native-autolink';
import { api, userProfile_path } from "../../Helper";
//import ConnectionStatus from "../../chat/ConnectionSatus";
import MessagesForm from './MessagesForm';
import { chatStyle } from './styles/chatStyle';
//import { UserContext } from '../../courses/UserContext';


var sender_email='';
var sender_img='';
var sender_name='';

if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	sender_email=JSON.parse(localStorage.getItem('ac_login_user'))['email'];
	sender_name=JSON.parse(localStorage.getItem('ac_login_user'))['fname'];
	sender_img=JSON.parse(localStorage.getItem('ac_login_user'))['img']
}

export default function App({ receiver_info, parentDBCallback }) {
  const [hasMore, setHasMore] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [is_active, setIsActive] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [len, setLen] = useState(0);
  const [start, setStart] = useState(0);
  const [, forceUpdate] = useState(0);
  const [showFetchPrevButton, setShowFetchPrevButton] = useState(false);
//	const context_ref = useContext(UserContext);

  const end = 8;
  const chats = useRef([]);
  const messagesEndRef = useRef(null);
  const listInnerRef = useRef(null);
  const classes = chatStyle();

  const showd = (t,len) => {
    const chatId = receiver_info["chat_id"];
	if(len == undefined )
	{
		len = dataList.length
	}
	//alert(dataList.length)
    axios
      .post(api + "/ac_getMsg", { chat_id: chatId, length: len, start: t, end: end })
      .then((response) => {
        const k = response.data.reverse();
        chats.current = k;
        setDataList(k);
        setLen(k.length);
		if(len == 0 )
		{
			setHasMore(true)
		}
        scrollToBottom();
      });
  };


	
	  const fetchPrevMsg = () => {
		  setBtnLoading(true)
		  setStart(start+end)
		const chatId = receiver_info["chat_id"];
		let payload = { chat_id: chatId, length: dataList.length,start: start+end, end: end }
		axios
		  .post(api + "/ac_getMsg",payload)
		  .then((response) => {
			const res = response.data.reverse();
			const da = dataList;
			const r = res.concat(da);
			console.log(payload)
			console.log(res)
			if(res.length<end)
			{
				setHasMore(false)
			}
			else
			{
				setHasMore(true)
			}
			setDataList(r);
			chats.current = r;
			setLen(r.length);
		    setBtnLoading(false)
			forceUpdate((n) => !n);
		  });
	  };

	const handleScroll = () => {
	  if (listInnerRef.current) {
		const { scrollTop } = listInnerRef.current;
		if (scrollTop === 0) {
		  setShowFetchPrevButton(true);
		} else {
		  setShowFetchPrevButton(false);
		}
	  }
	};

	 useEffect(() => {
		setStart(0);
		setDataList([]);
		showd(0);
	}, [receiver_info]);
	
	 useEffect(() => {
	  const interval = setInterval(() => {
		showd(0);
	  }, 2 * 50 * 1000); 

	  return () => {
		clearInterval(interval);
	  };
	}, [receiver_info]);

	
    /*
	useEffect(() => {
			//console.log("------------- socket chat messages fetched from server  --------------- ");
			(context_ref.socket_info).on("data", (data) => {
			let k=chats.current
			k=[...k, data.data];
			chats.current=k;
			setDataList(k);
			scrollToBottom();
			});
		return () => {
		  (context_ref.socket_info).off("data", () => {
			  console.log("data event was removed");
			});
		};
    }, [context_ref.socket_info]);
	*/
 
  const handleDBParent = () => {
	setHasMore(true)
	setStart(0)
	//setDataList((prevState) => []);
	showd(0,0)
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };

 const get_msg_user = (db_sender_email, data) => {
  const user = (db_sender_email === sender_email) ? 'sender' : 'receiver';
  const k = (user === 'sender') ? sender_img : receiver_info['img'];
  return (data === 'img') ? k : (user === 'sender') ? sender_name : receiver_info['name'];
}

	
	const FetchPrevMsgButton = () => {
	  return (
		<Box display="flex" justifyContent="center">
		{
			  hasMore  && (<Box 
					  fontSize="14px" 
					  fontWeight={700} 
					  color="#253B7C" 
					  style={{cursor:'pointer'}}
					  disable={btnLoading}
					  onClick={fetchPrevMsg}>
					  {btnLoading?<CircularProgress />:"Fetch Previous Messages"}
					 </Box>)
		}
		{
			  !hasMore  && (
				  <Box marginBottom={2}>
				  <Typography 
					  variant="subtitle2" 
					  color="textSecondary"> 
					  There are no new messages discovered!
					 </Typography>
					 </Box>)
		}
		</Box>
	  );
	};
      
        const Message = ({text}) =>
        {return (
           <Autolink
            style={{color:"#637381"}}
            text={text}
            email
            hashtag="instagram"
            mention="twitter"
            phone="sms"
            url
          />
        )}
        
          
        
        const ChatLists = ({chats}) =>
        {
			let arr=chats;
			return (
			<Box>
			{showFetchPrevButton && <FetchPrevMsgButton />}
            <List>
			
			 {	
				arr.length>0 && arr.map((e, index) => (
				
                
                <>
				{
					receiver_info['chat_id']==e['chat_id']					
					?
						e['sender']==sender_email					
						?
					
							<ListItem alignItems="flex-reverse" 
							style={{padding:"0px"}}>
							
							<ListItemText
							style={{marginLeft:'0px',marginTop:'0px',marginBottom:'0px',display:'flex',justifyContent:'end'}}
							primary={
								
							<Box className={classes.reply}>
							<Typography color="textPrimary" variant="caption">
								<Box display="flex">
								<Box lineHeight={1.1}  
								marginRight={1}
								color="text.secondary"> 
								{e['added_on']} 
								</Box>
								
							
							<Box flexGrow={1} display="block">
							<Box lineHeight={1.1}  textAlign="right" className={classes.title} fontWeight="bold"  > 
							{get_msg_user(e['sender'],"name")} 
							</Box>
							<Box marginTop={1} lineHeight={1.6} fontSize="caption.fontSize" color="text.secondary"
							 textAlign="right" > 
							<Message text={e['chat'] } />
							</Box>
							</Box>
							
						   
						</Box>
						</Typography>	
						</Box>					 
							}/>
							
							<ListItemAvatar
							className={classes.avatar}>
							<Box margin={1}> <Avatar  style={{height:'35px',width:'35px'}} src={userProfile_path+get_msg_user(e['sender'],"img")} /></Box>
							</ListItemAvatar>
							
							</ListItem>
					:
					
				
						<ListItem alignItems="flex-start"  style={{padding:'0px'}}>
						<ListItemAvatar
						className={classes.avatar}>
						<Avatar  style={{height:'35px',width:'35px'}} src={userProfile_path+get_msg_user(e['sender'],"img")} />
						</ListItemAvatar>
						<ListItemText
						  primary={
							<Box  padding={1} 
							className={dataList.length-index<=100?
							clsx(classes.question,classes.unread):
							clsx(classes.question,classes.read)}>
							 <Typography color="textPrimary" variant="caption">
							<Box display="flex">
								<Box lineHeight={1.1} flexGrow={1} className={classes.title} fontWeight="bold" > 
								{get_msg_user(e['sender'],"name")} 
								</Box>
								<Box marginLeft={1} lineHeight={1.1} color="text.secondary" > 
								{e['added_on']+" ago"} 
								</Box>
							</Box>
							<Box marginTop={1} lineHeight={1.6} fontSize="caption.fontSize" color="text.secondary"> 
							<Message text={e['chat'] } /> </Box>
							 </Typography>
							
							</Box>
							}
						/>
						</ListItem>

					:""
				}
			  </>
			))}
			
             <ListItem>
			 <ListItemText primary={<div 
			 style={{ float:"left", clear: "both" }}
			  ref={messagesEndRef} ></div>} />
			</ListItem>
			
			</List>
			</Box>

        )}
        
    	const ReceiverData=()=>{
		return(
		     
						 <Box 
						 paddingBottom={0}
							marginTop={2}
						 display="flex">
						 
						 
						 <Box marginRight={2}>
						 <Avatar 
						style={{height:'50px',width:'50px'}}
						src={userProfile_path+receiver_info['img']} />
						 </Box>
						 
						 
						 {/* borderBottom="1.5px solid #13171f21"*/}
						<Box>
						<Typography 
						color="textPrimary"
						variant="h6">
						<Box lineHeight={1.5}
						className={classes.title} > 
						{receiver_info['name']} 
						</Box>
						</Typography>
						
						 
						  
						 <Typography>
						 <Box lineHeight={1.25} fontSize="caption.fontSize" color="text.secondary">
						 {receiver_info['user_is']=="student"?"Learner":"Mentor"}
						 </Box>
						</Typography>
						 </Box>
						
							
						<Box>
						<ArrowRightIcon fontSize="large" />
						</Box>
						</Box>
		)
	}
	let col=is_active==true ? "#39BF6F" : "#ff9800"
	return (
 	<React.Fragment>
	{/*<ConnectionStatus  user={receiver_info} 	
	parentCallback={()=>{console.log("in list callback")}}
	/>*/}
	
	
      <Card elevation={3} >
		<CardHeader title={<ReceiverData />}/>

		<CardContent className={classes.card} 
		style={{backgroundColor:"rgb(253 253 254)"}}>
		<Grid container >
			
				
                 <Grid  item xs={12}>
		
					{/* list data */}
						<Grid container spacing={0} >
						<Box display="none">
						<Chip
							avatar={<FiberManualRecordIcon style={{color:col,height:'1.1rem'}} />}
							label={is_active==true ? 'Active' : 'Idle'}
							clickable
							variant="outlined"
						/>
						</Box>
				
				{/*
				style={(this.receiver_info).hasOwnProperty('session')?{height:"53vh"}:{height:"50vh"}}
				*/}
							<Grid item xs={12}
							style={{height:'52vh'}}
							 className={classes.scrollroot} id="scrollDiv" 
							  onScroll={handleScroll} ref={listInnerRef}>
							 {
								 
								 len==null?
									<Box marginTop={2} marginBottom={2} sx={{ alignItems: 'center', display: 'flex',flexDirection: 'column'}}>
									 <CircularProgress color="primary" size={24} />
									</Box>
								:<ChatLists chats={dataList} />
							 }
							</Grid>
							
							
							<Grid item xs={12}
							style={{height:'7vh'}}>
							
							
							{
								is_active==true 
								?
									<MessagesForm 
									receiver_info={receiver_info}
									parentCallback={handleDBParent}
									/>
																		 
								:
									<Box marginTop={1} >
									<Card style={{ backgroundColor:"#f3f6f9"}}>
									<Box padding={1}>
									<Typography variant="subtitle2" color="textPrimary" align="center">
									You are offline.		
									</Typography>
									</Box>
									</Card>
									</Box>
							}
							</Grid>
					</Grid>
					
			</Grid>
		</Grid>
		</CardContent>
		</Card>
	 </React.Fragment>

  );
}

