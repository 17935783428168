import React, { Component, Suspense } from 'react';
import CouponAdd from './CouponAdd';
import CouponList from './CouponList';


class Coupon extends Component {

    constructor(props) {
    super(props);
    this.state={
	
    }
	 
  	}
  	render()
  	{
			
		

	  return (
	    < >

		{
			window.location.pathname=='/coupon/add' 
				?<Suspense fallback={<div> </div>}><CouponAdd />  </Suspense>
				:
					window.location.pathname=='/coupon/view'
					?<Suspense fallback={<div> </div>}><CouponList /></Suspense>
					:<Suspense fallback={<div> </div>}><CouponAdd /></Suspense>
		}
		</  >  
	

	    );
  	}
}

export default () => {
    return (
        <Coupon  />
    )
}
  