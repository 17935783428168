
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, Grid, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../functions/AlertBox";
import { api } from "../functions/Helper";
import { deleteLocal,getKeys } from "../functions/caching";
import { image_useStyles } from "./useStyles";
import { useHistory, useLocation } from 'react-router-dom';
import convertSize from "convert-size";
import ReactPlayer from 'react-player';

const location = window.location.pathname;
 const videoConstraints = {
      facingMode: "user"
};
const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
	MuiPaper:{rounded: {
    'border-radius': '12px'}}
  },
})
class CourseBlock extends Component {

    constructor(props) {
    super(props);
    this.deleteLocal = deleteLocal.bind(this);

    this.state={
            id:this.props.id,
            loading:false,imgDetails:false,
            
            upload:false,
            db_upload:false,
            folder_upload:false,
			
			type:"image",
			size:0,
            
            
            img_msg:false,img_msg_type:false,
            img:this.props.img,isFileUpdated:false,
		    errors: {id:"Initalize"}
	}
    }
	
	imageHandler = (e) => {

		console.log(e.target.files)
		this.setState({loading: true});
		this.setState({imgDetails: e.target.files[0]});
		this.setState({size: e.target.files[0]['size']},()=>console.log(this.state.size));
		this.setState({type: (e.target.files[0]['type']).toString()},()=>console.log(this.state.type));
		
        let name=e.target.files[0]['name']

		let reader = new FileReader();
			reader.onloadend = () => {
			this.setState({img: reader.result});	
		}
        this.setState({isFileUpdated:true})
		reader.readAsDataURL(e.target.files[0]);	
		this.setState({upload:false})
        
                 
                
        if(name.match(/\s/g))
		{
			this.setState({
				 img_msg:'Filename cannot contain whitespaces.',
				 msg:'Filename cannot contain whitespaces.',
				 img_msg_type:'warning',
				 imgDetails:false})
				 
		}
		else
		{    
			if (name.match(/\.(jpg|jpeg|png|gif)$/) && this.props.table=='chapter') 
			  {
				 this.setState({img_msg:'Please upload a video file.',
				 img_msg_type:'warning',
				 imgDetails:false})
				 
				  setTimeout(() => {
					this.setState({img_msg:false})
				 }, 3000); 
			  }
		}
				setTimeout(() => {this.setState({img_msg:false})}, 2000); 

		this.setState({loading: false});
	}
	handleSubmit = (e) =>
	{
        //alert()
		
		
		let key=this.props.keys
		if(typeof(this.props.keys)=="object")
		{
			key=(this.props.keys).toString()
			//alert("vhgf")
		}	
		
		
		if(key.length!=0)
		{
			if(key.includes(",")==true)
			{
				let arr=key.split(",")
				for(let i=0;i<arr.length;i++)
				{
					
					this.deleteLocal(arr[i])
				}	
			}
			else
			{
				this.deleteLocal(key)
			}
				
		}
		else 
		{
			console.log("hjkh")
		}	

		e.preventDefault();
        this.setState({
            db_upload:false,
            folder_upload:false
        })

		if(this.state.imgDetails==false)
        {
            let file='image'
            if (this.props.table=='chapter')
            {
                file='video'
            }
            this.setState({img_msg:'Please upload '+file+'.'})
            this.setState({img_msg_type:'warning'})
            this.state.loading=false;
        } 
        
        else
        {
			   this.setState({loading: true});
               let t=true;
			   let limit=2
			   let size=Math.round(convertSize(this.state.size, "MB"));
				if(this.state.type.includes("image") && size>2 )
				{
					t=false;
				}
				
				
				if(this.state.type.includes("video") && size>25 )
				{
					t=false;
					limit=25
					
				}
				
				
				if(t==false) 
				{
					this.setState({img_msg: this.state.type+" file ("+size+" mb) must be less than "+limit+" mb."});
					this.setState({db_upload: this.state.type+" file ("+size+" mb) must be less than "+limit+" mb."});
                    this.setState({img_msg_type:"warning"})
				    this.setState({loading: false});

					setTimeout(() => {
					 
						this.setState({img_msg:false});
					 
					}, 5000); 
				}	
				else
			    {
				   
                     this.setState({db_upload:'Updating databse ....'})
                     let payload={
                            'img':this.state.imgDetails['name'],
                            'id':this.props.id,
                            'table':this.props.table,
                            'upload_for':this.props.upload_for,
                            'keys':key
                        }
                        console.log(payload)
                        axios.post('/api/update_db_img',payload)
                        .then((response) => 
                        {
                            console.log(response.data);
                            if(response.data=="updated")
                            {
                                this.setState({db_upload:'database updated successfully'})
                                this.setState({img_msg: "Image updated successfully"});
                                this.setState({img_msg_type:"success"})
                                this.setState({loading: false});

                                 if(this.state.imgDetails!=false && response.data=="updated")
                                {
                                     console.log("inside")
                                    const header = {}; //headers
                                     let data = new FormData();
                                     header.Accept = 'application/json';
                                    data.append( 'file',this.state.imgDetails);
                                    data.append( 'table',this.props.table);
                                    data.append( 'upload_for',this.props.upload_for);
                                    data.append( 'id',this.props.id);
                                    data.append( 'keys',key);
                                  
                                    this.setState({folder_upload:'Uploading starts ....'})
                                    
                                    axios.post(api+'/upload_folder_img',data)
                                    .then((response) => {
                                        
                                    console.log("called")
                                    console.log(response.data)
                                        this.setState({isFileUpdated:true})
                                        this.setState({img_msg:response.data['msg']})
										this.state.img_msg=response.data['msg']
                                        this.setState({folder_upload:response.data['msg']})
                                       
                                        this.back();

                                    })
									

                                }
                                
                            
                            }
                            else
                            {

                                this.setState({upload:'Database update operation failed ....'})
                                this.setState({img_msg: "Try Again with non-existing record"});
                            }
                        }, (error) => 
                        {
                            console.log(error.data);
                        });
						
						      

			    }
                        
        }
	}
	
	back=()=>{
		setTimeout(() => {
		 
			this.props.history.goBack();
		 
          }, 5000); 
	}
	componentDidMount()
	{
		console.log(this.props.keys)
		let key=this.props.keys
	}
  	render()
  	{
		

		const classes = this.props.classes;	
		const { errors } = this.state;
        let route=this.props.route
		
		const UploadImg = () =>
		{
			const styles={  large :{height:'150px',width:'150px',border:'3px solid #e5e5e5',
			    'border-radius':'50%'}}
			let file_src=this.state.isFileUpdated==false?
						route+this.props.img
						:this.state.img
			let is_image=this.state.isFileUpdated==false?
						 this.props.img==null?true:(this.props.img).match(/\.(jpg|jpeg|png|gif)$/)==null?false:true
						:this.state.type.includes("image")
			return(
		
			<Grid container direction="column"   justifyContent="flex-start"  alignItems="center" spacing={0}>      

			<Grid item xs={12}>
			<Box marginTop={3} marginBottom={3}>

				<Typography  variant="subtitle2"  color="secondary">
                {this.state.db_upload}
                </Typography> 
                
				<Typography  variant="subtitle2"  color="secondary">
                {this.state.folder_upload}
                </Typography> 
                
			</Box>
			</Grid>
			
			<Grid item xs={12}>
			<Box marginTop={3}>
			<label htmlFor="photo-upload">
			  <input accept="image/*" id="photo-upload" multiple type="file" 
			  onChange={this.imageHandler}  hidden/>
				
				{
					is_image==true
					?
						<Avatar style={styles.large} 
						src={file_src}> 
						</Avatar>
						
					:
						<video src={file_src}
						style={styles.large} alt="complex"  />
			  
				}
			</label>
			</Box>
			</Grid>
			
			<Grid item xs={12} align = "center" justify = "center" alignItems = "center" >
			<Box marginBottom={3} marginTop={3}>
			<Typography variant="subtitle2" color="textSecondary">
			Allowed *.jpeg, *.jpg, .*png, *.gif</Typography>	
            <Typography variant="subtitle2" color="textSecondary">
			            {route+this.props.img}
            </Typography>
				
                <Typography  variant="subtitle2"  color="secondary">
				{this.state.isFileUpdated==false?
                this.props.img
                :this.state.imgDetails['name']}
                </Typography> 
			
			</Box>
			</Grid>
			
			</Grid>
			)
			
		}
		
		

		return(
		 <Card>
		<CardHeader title={"Update "+this.props.table+" image ("+this.props.id+")"} />
					
        <Grid container spacing={2}
         container direction="column"
                    alignItems="center"
                    justify="center">
        { 	
					this.state.img_msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.img_msg}
					msg_type="success" />
					: ''	
		}	 
		
		
		<Grid  xs={12} >
        <Box padding={1}>
       
				
				<UploadImg />
			
		</Box>
        </Grid>
        
        
        
	    <Grid item xs={12}>
								<Box display="flex" flexDirection="row-reverse"   marginBottom={4} >
								
								<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} 
								
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>Upload {this.props.table} image</span>}
								</Button>
								
								</Box>
	    </Grid>
								
		  
		</Grid>
        </Card>

            
            
	    );
	
  	}
}
export default (props) => {
    const classes = image_useStyles();

	const location = useLocation();
    const history = useHistory();
    return (
        <CourseBlock 
        classes={classes}
        location={location}  
        history={history}
        table={props.table}
        id={props.id}
        img={props.img}
        keys={props.keys}
        upload_for={props.upload_for}
        route={props.route} />
    )
}

