import { makeStyles } from '@material-ui/core/styles';
import cookie from 'react-cookies';
let config_res=cookie.load('config')	 

 export let menuItems = [
  {
    menu: 'Hire',
    submenu: [
      { label: 'Experts on demand', link: 'https://www.opengrowth.com/mentorship' },
    ],
  },
  {
    menu: 'Enable',
    submenu: [
      { label: 'OpenGrowth Hub', link: 'https://www.opengrowth.com/opengrowth-hub' },
      { label: 'Courses', link: 'https://www.opengrowth.com/courses' },
      { label: 'OpenGrowth Community', link: 'https://www.opengrowth.com/community' },
      { label: 'Resources', link: 'https://www.opengrowth.com/resources' },
    ],
  },
  {
    menu: 'Grow',
    submenu: [
      { label: 'Digital Marketing', link: 'https://www.opengrowth.com/services/digital-marketing' },
      { label: 'Social Media Marketing', link: 'https://www.opengrowth.com/services/smm' },
      { label: 'SEO Marketing', link: 'https://www.opengrowth.com/services/seo' },
      { label: 'Content Marketing', link: 'https://www.opengrowth.com/services/content-marketing' },
      { label: 'Website Development', link: 'https://www.opengrowth.com/services/website-development' },
      { label: 'UI/UX', link: 'https://www.opengrowth.com/services/ux-ui' },
    ],
  },
  {
    menu: 'About Us',
    link: 'https://www.opengrowth.com/about-us',
    submenu: [],
  },
  {
    menu: 'Contact',
    link: 'https://www.opengrowth.com/about-us',
    submenu: [],
  },
  {
  menu: window.location.pathname === '/' || window.location.pathname === '/login'  ?'Join Now': 'Login' ,
  link: window.location.pathname === '/' || window.location.pathname === '/login' ?'/register/user': '/login' ,
  submenu: [],
}

];
 export let cimpmenuItems = [
  
  {
  menu: window.location.pathname === '/' || window.location.pathname === '/login'  ?'Join Now': 'Login' ,
  link: window.location.pathname === '/' || window.location.pathname === '/login' ?'/register/user': '/login' ,
  submenu: [],
}

];