import React, { useState } from 'react';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfigForm from '../ConfigForm';
import {projects} from '../../../functions/Helper';

const useStyles = makeStyles((theme) => ({
  cardHeight: {
    minHeight: '62vh',
  },
}));

const ManageMentorship = () => {
  const classes = useStyles();
  const actions = [
    { key: 'mentorship', title: 'Basic Configuration' },
  ];

  const [selectedModule, setSelectedModule] = useState('mentorship');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('Manage Mentorship Module');
  const [selectedAction, setSelectedAction] = useState('');
  const [centerContent, setCenterContent] = useState('');

  const handleProjectChange = (event) => {
    const { value } = event.target;
    setSelectedProject(value);
  };

  const handleActionChange = (event) => {
    const { value } = event.target;
    const selectedAction = actions.find((action) => action.key === value);
    setSelectedAction(value);
    setSelectedTitle(selectedAction.title);
  };

  const handleActionButtonClick = () => {
    if (selectedProject && selectedAction) {
      const newCenterContent = `Center content for ${selectedProject} - ${selectedAction}`;
      setCenterContent(newCenterContent);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">

      <Grid item xs={12} lg={4}>
        <Card>
          <CardHeader title="Manage Mentorship Module" />
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={12}>
			  <FormControl variant="outlined" fullWidth >
			   <InputLabel>Select Project</InputLabel>
                  <Select label="Select Project" value={selectedProject} onChange={handleProjectChange}>
                    <MenuItem value="">Select Project</MenuItem>
                    {projects.map((project) => (
                      <MenuItem key={project.key} value={project.key}>
                        {project.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Select Action</InputLabel>
                  <Select  label="Select Action"  value={selectedAction} onChange={handleActionChange}>
                    <MenuItem value="">Select Action</MenuItem>
                    {actions.map((action) => (
                      <MenuItem key={action.key} value={action.key}>
                        {action.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} >
                <Button fullWidth variant="contained" color="primary" onClick={handleActionButtonClick}>
                  Get config structure
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
	  
	  <Grid item xs={12} lg={8}>
      <Card className={classes.cardHeight}>
	  <CardHeader title={selectedTitle} />
	  <CardContent>
		{
			  selectedAction && selectedProject ? (
				selectedAction === 'mentorship' ? (
				  <ConfigForm project={selectedProject} module={selectedModule} component={selectedAction} />
				) :null
			  ) :  (
				  <Box display="flex">
					<Typography variant="subtitle1" color="textSecondary">
					  Please select an action to proceed.
					</Typography>
				  </Box>
				)
		}
		</CardContent>
	</Card>
      </Grid>
	  
	  
    </Grid>
  );
};

export default ManageMentorship;
