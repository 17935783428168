import React, { useEffect, useState } from 'react';
import { FaUsers } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { api,userProfile_path  } from '../../Helper';
import axios from 'axios';
import { Skeleton } from '@material-ui/lab';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Avatar, Badge, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
var sender='';
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	sender=JSON.parse(localStorage.getItem('ac_login_user'))['email'];
}
const UserProfile = ({chat_id}) => {
  const [profileList, setProfileList] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProfileList(0);
  }, []);

  const fetchProfileList = (t) => {
    const payload = {
      chat_id:chat_id,
      email: sender,
      start: t,
      end: end
    };

    axios.post(api + "/get_chatbox_users", payload)
      .then((response) => {
        const res = response.data;
        setLoading(false);

        if (res.length < end) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }

        if (t === 0) {
          setProfileList(res);
        } else {
          setProfileList((prevProfileList) => [...prevProfileList, ...res]);
        }
      });
  };

  const fetchMoreData = () => {
    if (hasMore) {
      setLoading(true);
      setStart(start + end);
      fetchProfileList(start + end);
    }
  };

  const UserList = () => {
    return (
      <Box display={profileList.length === 0 ? "none" : "block"}>
        <div id="scrollableDiv" style={{ height: "57vh", overflow: "auto" }}>
          <InfiniteScroll
            dataLength={profileList.length}
            hasMore={hasMore}
            loader={
              hasMore ?
                <Box marginTop={2} marginBottom={2} alignItems='center' justifyContent='center' display='flex'>
                  {loading
                    ? <CircularProgress disableShrink />
                    : <Button
                      variant="outlined"
                      size="small"
                      color="primary"
                      disabled={loading}
                      onClick={fetchMoreData}>
                      Load More Users
                    </Button>
                  }
                </Box>
                : "No more data"
            }
            endMessage={
              <Box width="100%" display="flex" alignItems='center' justifyContent='center' marginTop={2} marginBottom={2}>
                <Typography variant="subtitle2" color="textSecondary" align="center">You have seen it all</Typography>
              </Box>
            }
            scrollableTarget="scrollableDiv"
          >
            <List>
              {profileList.map((e) => (
                <Button fullWidth >
                  <ListItem alignItems="flex-start" style={{ padding:"0px" }}>
                    <ListItemAvatar>
                       <Avatar style={{ height: '45px', width: '45px' }} src={userProfile_path + e['img']} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box margin={0.5}>
                          <Typography variant="subtitle2" color="primary">
                            <Box lineHeight={1.1}  fontWeight="600">
                              {e['name']}
                            </Box>
                            <Box lineHeight={1.6} fontSize="fontSize.caption" color="text.secondary">
                              {e['user_is'] === 'student' ? 'Learner' : 'Mentor'}
                            </Box>
                          </Typography>
                        </Box>
                      }
                    />
                  </ListItem>
                </Button>
              ))}
            </List>
          </InfiniteScroll>
        </div>
      </Box>
    );
  };
  const renderSkeletonCard = () => (
    <Box display="flex" alignItems="start" marginBottom={2}>
        <Skeleton variant="circle" width={45} height={45} />
      <Box paddingLeft={2}>
        <Box height="32px">
          <Skeleton width={100} height={"100%"} />
        </Box>
        <Skeleton width={50} height={25} />
      </Box>
    </Box>
  );

  const UsersCard = () => {
    return (
       
          <Box style={{ height: '68vh' }}>
            {profileList.length === 0  && loading?
             <Box marginTop={2} marginBottom={2} >
			 {(
				Array.from({ length: 3 }).map((_, index) => (
				  <div key={index}>
					{renderSkeletonCard()}
				  </div>
				))
			 )}
              </Box>
              : profileList.length === 0 ?
                <Box marginTop={1} marginBottom={1} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                  <Typography color="textSecondary" variant="subtitle1">
                    User profiles not discovered.
                  </Typography>
                </Box>
                : <UserList />
            }
          </Box>
      
    );
  };

  return (
  <Card>
  <CardContent>

	<Typography color="textPrimary" variant="subtitle1">
	  <Box fontWeight="600" display="flex" alignItems="center" marginBottom={2}>
		<FaUsers fontSize="large" />
		<Box marginLeft={2} >Engaged members</Box>
	  </Box>
	</Typography>

    <UsersCard />
	</CardContent>
	</Card>
  );
};

export default UserProfile;
