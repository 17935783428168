import {
	Box,
	Grid, 
	ThemeProvider, Typography
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";
import CommonCard from './CommonCard';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	
		let points=[
		{'text':"OpenGrowth Academy may display interest-based advertising using the information you make available to us while interacting with us."},
		{'text':"We follow the Indian laws while displaying interest-based ads to you and the self-code regulation in advertising by the Advertising Standards Council of India and Self Regulatory Principles for Online Behavioral Advertising developed by the Digital Advertising Alliance."}, 
		{'text':"Like other online ad networks, we use cookies, web beacons (also known as action tags or single-pixel gifs), and other technologies (collectively, “cookies”). Cookies enable us to learn about what ads You see, what ads You click, and other actions You take on our sites and other sites. This allows us to provide You with more useful and relevant ads. For example, if we know what ads You are shown we can be careful not to show You the same ones repeatedly. We do not associate Your interaction with unaffiliated sites with Your identity in providing You with interest-based ads."},
		{'text':"We do not provide any personal information to advertisers or third-party users. However, the advertisers may get your IP address through technology. For any such query, please contact them directly. OpenGrowth Academy is not responsible for such access to information."}]
		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  >

					
	
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 Advertisements 
					 </Box>
					 </Typography> 
					
					<CommonCard points={points} />
					  
		</Box>			
		</Grid>
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
