import { Box, List, ListItem, ListItemIcon, ListItemText, ThemeProvider, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Academytheme } from "../../../functions/useStyles";
import { getCacheDetails } from '../../caching';



const cookies = new Cookies();


let webinar_id='';
let seconds='';

export default class AccordionBlock extends Component {
    constructor(props) {
    super(props);

	this.state={
        is_webinar_found:'no',        
        reasons:[],
    }}
    
  
	componentDidMount()
	{
       if(this.props.webinar_id!=undefined) 
       {this.getResDetails()}
        
    }   
    getResDetails = () => {
        
                
        let param={
            'key':"webinar_reason_"+this.props.webinar_id,
            'webinar_id':this.props.webinar_id
        }
        
        getCacheDetails("getReasonsList",param)
		.then(data =>  {
            
           // alert(data.length)
            this.setState({
                    reasons:data
                })
            
            })
        
			
	};
   
    
	render(){
	const classes = this.props.classes;
    const history = this.props.history;
    const cookies = this.props.cookies;

	const reasons = [
	 {label: "Learn how to get more people to join a community?",icon: DoneIcon},
	 {label: "Get insights on how to engage a thriving online community",icon: DoneIcon},
	 {label: "Find how to keep members active over time",icon: DoneIcon},
	 {label: "Perks of winning super exciting Freebies",icon: DoneIcon},
	 {label: "A chance to interact with leading experts",icon: DoneIcon}
	 ];

    const WhyToAttend = () => {
        
        let para=String(this.props.attend_content)
        para=(para).split("<br>");
        
	return (
	<Box padding={4} marginTop={4} paddingBottom={0}  textAlign="center" justifyContent="center"
    display={this.props.attend_content==undefined?"none":"block"}>
			<Box display="flex" justifyContent="center" alignItems="center"
            marginBottom={2}>
			 <Typography  variant="h5" color="primary"> 
                Why Should I Attend?
			</Typography>
                
			</Box>
            
			<Box  display="block" textAlign="center" justifyContent="center" >
            {
              para.map((e,index) => (
                <Typography  variant="subtitle1" color="textSecondary" style={{lineHeight:'1.5rem'}}> 
                 {e}
                </Typography>    
            ))}
                
			</Box>
            
            
            {this.state.reasons.length>0?                
            
            <Box justifyContent="center" alignItems="center"
            marginBottom={2} marginTop={2} display="flex">
			 	  
            <List>		  
              {this.state.reasons.map((e,index) => (
                    <ListItem >
                      <ListItemIcon>
                      {e['reasons']!=null? <DoneIcon    />:''}
                      </ListItemIcon>
                      <ListItemText primary={
                           <Typography  variant="subtitle1" color="textSecondary" style={{lineHeight:'1rem'}}> 
                             {e['reasons']}
                            </Typography>
            
                      }/>
                    </ListItem>
              ))}
            </List>
           </Box>
            :''}
       </Box>
        )} 


   
       return(
         <ThemeProvider theme={Academytheme}>
         
         <WhyToAttend />
         
         </ThemeProvider >

        );
}
}


  