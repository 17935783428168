import {
    Avatar,alpha,
	Box, Button,
	Card, CardContent, CardHeader,CircularProgress,
	FormControl,
	InputLabel,InputBase,IconButton,
	MenuItem,makeStyles,
	Paper,
	Select,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { api, translateText } from "../../functions/Helper";
import EditImage from "./EditImage";
  







const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
     search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
	     backgroundColor: 'transparent',

    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	color:'primary'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize:'14px',
	padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },

}));

class Logs extends Component {

    constructor(props) {
	super(props);
	this.translateText = translateText.bind(this);
	this.state={
		    msg:false,msg_type:false,img:false,
            user_show:[],user_count:0,start:0,end:10,
			hasMore: true,record_len:0,
			search_for:'All',search:null,email:null}
    }
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
			this.getUsers("scroll")			
			}, 1500);
		}
	};	
	manageModerators = (email,user,role,called_for) => {
    
		this.setState({search_for:'All'})
        let state=user
        if (called_for=="video")
        {
           state=user=='member'?'moderator':'member'
           state=state+","+role
        }
        else
        {
            state=role==''?'team':''
            state=user+","+state
        }
        
        
        axios.post(api+'/manageModerators',{
			'email':email,
			'state':state,
		})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.state.msg_type=response.data['type']	
		//console.log(response.data);
		//alert(response.data['type']);
        
        this.getUsers('initial')
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	};
	
	manageAdmin = (email,state) => {
    
		this.setState({search_for:'All'})
        //alert(state==1?0:1)
        axios.post(api+'/manageAdmin',{
			'email':email,
			'state':state==1?0:1,
		})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.state.msg_type=response.data['type']	
		//console.log(response.data);
		//alert(response.data['type']);
        
        this.getUsers('initial')
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	};
	deleteWebinarUser = (email) => {
    
        axios.post(api+'/delete_with_admin',{
			'email':email,
			'platform':'webinar'
		})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.state.msg_type=response.data['type']	
		//console.log(response.data);
		//alert(response.data['type']);
        
        this.getUsers('initial')
        this.getUsersCount()
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	};	
	getData = () => {
    
		
		/*this.setState({
			hasMore:true,
			start:0,
			end:10,
		})*/
		this.state.hasMore=true
		this.state.start=0
		this.state.end=10
		this.getUsers("initial")
		
	};	
	keyPress = (e) => {
		if(e.keyCode == 13)
		{
			/*this.setState({
				search_for:'name',
				hasMore:true,
				start:0,
				end:10,
			})*/
			this.state.search_for='name'
			this.state.hasMore=true
			this.state.start=0
			this.state.end=10
			this.getUsers("initial")
		}
	}
	/*deleteUser = (email) => {
    
		axios.post(api+'/deleteUser',{'email':email})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        this.getUsers()
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}*/
	getUsers=(state)=>
    {
			axios.post('/api/getRegisteredUser', {
				'start':this.state.start,
				'end':this.state.end,
				'search_for':this.state.search_for,
				'search':this.state.search,
			})
            .then((response) => {
			//console.log(response.data)
			if(state=="initial")
			{
				this.setState({user_show:response.data})

			}
			else
			{
				this.setState({ user_show: [...this.state.user_show,...response.data]})

			}
			
			if(response.data.length<this.state.end)
            {
				this.state.hasMore=false;
				this.setState({hasMore:false})
            }	
			
			}); 
    }
    getUsersCount=()=>
    {
			axios.post('/api/getRegisteredUserCount')
            .then((response) => {
			//console.log(response.data)
			this.setState({user_count:response.data['len']})			
			}); 
    }
	
	updateImg=(email)=>
    {
            //alert("hii")		
            this.setState({email:email})		
			this.setState({img:!this.state.img})			
    }
	
	
	componentDidMount()
	{
		
		this.getUsers("inital")
		this.getUsersCount()
         
	}
  	render()
  	{
	
		const classes = this.props.classes;
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
			<StyledTableCell  >
			 <Box display="flex" flexDirection="row" p={1} m={1}>
				<Box p={1} onClick={()=>this.updateImg(e['email'])} >
				 <Avatar src={'assets/images/users/'+e['img']}  />
				</Box>
				<Box p={1} >
					{e['email']}
				</Box>
			  </Box> 
			</StyledTableCell>
             <StyledTableCell  >{e['name']}</StyledTableCell>
			  <StyledTableCell  >{e['edu']}</StyledTableCell>
			  <StyledTableCell  >{e['country']}</StyledTableCell>
             {/*  <StyledTableCell  >{e['group']}</StyledTableCell> */}
			
			{/*<StyledTableCell  >
               <IconButton  onClick={()=>{this.deleteUser(e['email'])}}>
						<CloseIcon color="grey"   />
				  </IconButton>
             </StyledTableCell>*/}
			 
			<StyledTableCell  >
               <Button  
				variant="outlined" 
				color="primary"  
				onClick={()=>{this.manageModerators(e['email'],e['group'],e['role'],"video")}}
				 >
				{e['group']=='member'?'Member':'Moderators'}
				</Button>
            </StyledTableCell>
			 
             {/*<StyledTableCell  >{e['user']==1?'Admin':''}</StyledTableCell>*/}
 
             
			<StyledTableCell  >
               <Button  
				variant="outlined" 
				color="primary"  
				onClick={()=>{this.manageAdmin(e['email'],e['user'])}}
				 >
				{e['user']==1?'Admin':'-'}
				</Button>
             </StyledTableCell>
			 
             
			<StyledTableCell  >
               <Button  
				variant="outlined" 
				color="primary" 
                size="small"                
				onClick={()=>{this.manageModerators(e['email'],e['group'],e['role'],"role")}}
				 >
				{e['role']==''?'-':'OG Team'}
				</Button>
             </StyledTableCell>
			 
             {/*<StyledTableCell  >
             
             {email_list.includes(e['email'])==true
                ?
                      <IconButton  onClick={()=>{this.deleteWebinarUser(e['email'])}}>
						<CloseIcon color="grey"   />
                      </IconButton>
             :''}
             </StyledTableCell>*/}
             
             <StyledTableCell  >
             <IconButton  onClick={()=>{this.deleteWebinarUser(e['email'])}}>
					<CloseIcon color="grey"   />
             </IconButton>
             </StyledTableCell>
			 
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
        {
            this.state.img==true?
            <EditImage email={this.state.email} />
            :''
        }
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				Webinar : Registered users ({this.state.user_show.length})
				</Typography> 
                <Typography variant="subtitle1"
                className={classes.iconstext} color="textSecondary">
				Total registeration  ({this.state.user_count})
				</Typography>
				</Box>
				
				
					
				<Box display="flex" >
                <Box marginRight={1} minWidth="200px">
                <FormControl variant="outlined" fullWidth>
					<InputLabel>Search</InputLabel>
					<Select name="search_for"
					onChange={event=>this.setState({search_for:event.target.value})}
					label="Search"
					value={this.state.search_for}
                    >
					<MenuItem value={'All'}>All</MenuItem>
					<MenuItem value={'Moderators'}>Moderators</MenuItem>
					<MenuItem value={"admin"}>Admin</MenuItem>
                    <MenuItem value={"team"}>OG Team</MenuItem>
					</Select>
				</FormControl>
				 </Box>
				
                <Box>
                <Button  
				variant="contained" 
				color="primary" 
                size="large"
				onClick={this.getData}>
				Get users
				</Button>
				</Box>
				</Box>
              
				
		   </Box>			
			}/>
	  
		<CardContent>
		
		<Box className={classes.search}
		marginTop={2} marginBottom={2}>
            <Box className={classes.searchIcon}>
              <SearchIcon />
            </Box>
            <InputBase
              placeholder="Search Courses…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
			  onChange={e => this.setState({search:e.target.value})}
			  onKeyDown={this.keyPress}
            />
          </Box>

		  <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.user_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
					  				   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					  
					}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Name</StyledTableCell>
						<StyledTableCell >Qualification</StyledTableCell>
						<StyledTableCell >Country</StyledTableCell>
						<StyledTableCell >Group</StyledTableCell>
						<StyledTableCell >User</StyledTableCell>
						<StyledTableCell >Team</StyledTableCell>
						<StyledTableCell ></StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
				 
				
				
				
			
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}