import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FileViewer from 'react-file-viewer';
import { chapter_path, videopath, quiz_path, is_local, community_path ,path} from "../../Helper";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

export default function AnyFileViewer(props) {
  const [file, setFile] = useState(props.src);
  const [extension, setExtension] = useState(null);
  const [type, setType] = useState(null);
  const [filepath, setPath] = useState(null);
  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    if (file) {
      const re = /(?:\.([^.]+))?$/;
      const ext = re.exec(file)[1];
      setExtension(ext);

      const imageExtensions = /\.(png|jpg|jpeg|svg|gif)$/;
      const videoExtensions = /\.(mp4|mov|ogg|avi|webm)$/;
      const pdfExtension = /\.(pdf)$/;
      const pptExtensions = /\.(ppt|pptx)$/;
      const docExtensions = /\.(doc|docx)$/;
      const txtExtension = /\.(txt)$/;

      let choice = null;
      let cpath = null;

      if (imageExtensions.test(file)) {
        setType("image");
        choice = "image";
        cpath = props.info === "quiz" ? quiz_path : props.info === "community" ? community_path : path;
      		console.log(cpath)

	  } else if (videoExtensions.test(file)) {
        setType("video");
        choice = "video";
        cpath = props.info === "course" ? videopath : null;
      } else if (pdfExtension.test(file)) {
        setType("pdf");
        choice = "pdf";
      } else if (pptExtensions.test(file)) {
        setType("ppt");
        choice = "ppt";
      } else if (docExtensions.test(file)) {
        setType("doc");
        choice = "doc";
      } else if (txtExtension.test(file)) {
        setType("txt");
        choice = "txt";
      }
		console.log(cpath)
      if (!["course", "quiz", "community"].includes(props.info)) {
        cpath = chapter_path + (props.info === "transcript" ? "transcript/" : `media/${choice}/`);
      }

      setPath((filepath)=>cpath);
    }
  }, [file]);

  const onError = () => {
    setFile(file + " : Unable to open the requested file!");
    setLoadingError(true);
  };

  const renderError = () => {
    return (
      <Box textAlign="center" justifyContent="center">
        <Typography variant="subtitle1" color="textPrimary">
          <Box fontWeight="600" margin={2}>
            Failed to load the file: {props.src}
          </Box>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      {loadingError ? (
        renderError()
      ) : (
        <Box
          display={[null, undefined, '', 0, '0'].includes(props.src) ? 'none' : 'flex'}
          textAlign="center"
          justifyContent="center"
        >
          <Typography variant="subtitle1" color="textPrimary">
            <Box fontWeight="600" margin={2}>
              Media: {file}
            </Box>
          </Typography>
        </Box>
      )}

      <Box
        display={[null, undefined, ''].includes(props.src) ? 'block' : 'none'}
        textAlign="center"
        justifyContent="center"
      >
        <Typography variant="subtitle1" color="textPrimary">
          <Box fontWeight="600" margin={2}>
            Source not found: {props.src}
          </Box>
        </Typography>
      </Box>

      <Box
        padding={0}
        minHeight="400px"
        height="100vh"
        width="100%"
        borderRadius="12px"
        style={{ border: "2px solid #ebebeb" }}
      >
        {type === null ? (
          <Box height="350px" width="100%">
            <img
              src={"https://cdn3.iconfinder.com/data/icons/modifiers-add-on-1/48/v-17-512.png"}
              height="100%"
              width="100%"
              style={{ borderRadius: '12px', backgroundSize: 'cover' }}
              onError={onError}
            />
          </Box>
        ) : (
          <>
            {props.info === "media" && file.startsWith("http") ? (
              <DocViewer
                pluginRenderers={DocViewerRenderers}
                documents={[{ uri: file, fileType: extension }]}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: false,
                    retainURLParams: false
                  }
                }}
                style={{ height: '100%', borderRadius: '12px' }}
                onError={onError}
              />
            ) : (
              <>
                {["image", "video"].includes(type) ? (
                  <>
				  <FileViewer
                    fileType={extension}
                    filePath={filepath + file}
                    onError={onError}
                    style={{ height: '100%', width: '100%', borderRadius: '12px' }}
                  /></>
                ) : (
                  <DocViewer
                    onError={onError}
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: filepath + file, fileType: extension }]}
                    config={{
                      header: {
                        disableHeader: false,
                        disableFileName: false,
                        retainURLParams: false
                      }
                    }}
                    style={{ height: '100%', borderRadius: '12px' }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
