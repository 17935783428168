import React, { useEffect, useState, Suspense } from 'react';
import {
  Grid, Button, Box, Paper, Typography, Dialog, DialogContent, DialogTitle, DialogContentText
} from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import { getCacheDetails } from "../../caching";
import { Course_Module_Skeleton, Course_Top_Skeleton, Course_Mid_Skeleton } from "../../Skeleton";
import { useStyles } from "../../useStyles";
import { generateCourseUrl } from "../../Helper";
import Blur from 'react-css-blur';

const CourseModule = React.lazy(() => import('./CourseModule'));
const CourseViewBot = React.lazy(() => import('./CourseViewBot'));
const CourseViewMid = React.lazy(() => import('./CourseViewMid'));
const CourseViewRight = React.lazy(() => import('./CourseViewRight'));

// Function to convert a string to sentence case
function toSentenceCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


let course_slug = '';
let course_id = '';

if (window.location.href.includes('/courses/')) {
  const parts = window.location.href.split('/courses/');
  if (parts.length > 1) {
    const courseParts = parts[1].split('/');
    if (courseParts.length > 1) {
      course_slug = courseParts[0];
      course_id = courseParts[1];
      console.log(parts);
    }
  }
}

console.log(course_slug);
console.log(course_id);
const course_name = toSentenceCase(course_slug.split('-').join(' ')); 
console.log(course_name);

function CourseView() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [courseDetails, setCourseDetails] = useState([]);
  const [resOpen, setResOpen] = useState(false);
  const [open, setOpen] = useState(!location.state);

  const init_state = (variable) => {
    try {
	  if(location.state[variable]==undefined){
      history.push("/dashboard?status=undefined"); 
      } else {
       return location.state[variable];
      }
    } catch (ex) {
      history.push("/dashboard?status=error"); 
    }
  }

  const verifyCoursesExistence = () => {
    getCacheDetails(`${course_id}_course_id`, "getCourseDetails", course_id)
      .then((data) => {
        console.log(data)
		if (Array.isArray(data) && data.length > 0 && data[0].hasOwnProperty('course_id')) {
          let courseUrl=generateCourseUrl(data[0]['course_name'])
		  let redirect_info={
            pathname: `/courses/${courseUrl}`,
            state: {
              course_searched_name: data[0]['course_name'],
              course_searched: course_id,
            },
          }
		 history.push(redirect_info);
        } else {
          setResOpen(true);
        }
      });
  };

  useEffect(() => {
    if (open) {
      verifyCoursesExistence();
    } else {
      getCacheDetails(init_state('course_searched')+"_course_id", "getCourseDetails", init_state('course_searched'))
        .then((data) => {
			console.log(data)
          setCourseDetails(data);
        });
    }
  }, [window.location]);

  const styles = {
    border: {
      // Your border styles here
    },
    height: {
      // Your height styles here
    },
  };

  return (
    <React.Fragment>
      <Dialog open={resOpen} fullWidth style={styles.border} maxWidth="xs">
        <DialogContent>
          <DialogTitle style={{ padding: '0px' }}>
          <Box marginBottom={1} >
		  <Typography variant="h6" align="center" color="textPrimary">
              {course_name}
            </Typography>
            </Box>
          </DialogTitle>
          <DialogContentText>
            <Typography variant="subtitle1" align="center" color="textSecondary" style={styles.height}>
              The course you requested is not available, indicating it may be unavailable or doesn't exist.
            </Typography>
			<Box marginTop={2} display="flex" justifyContent="center">
			<Button
			  size="medium"
			  color="primary"
			  variant="contained"
			  onClick={()=>{history.goBack()}}
			>
          Go Back
          </Button>
		  </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Blur radius={open ? '5px' : '0px'} transition="400ms">
		<>
		{ location.state && (
		<div className={classes.root}>
          <Grid container spacing={3} style={{ backgroundColor: '#F3F6F9' }}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Paper className={classes.paper} square style={{ height: '100%' }}>
                    <Box textAlign="left">
                      <Typography variant="h6" color="primary">
                        <Box fontWeight="600" lineHeight={1.25} marginBottom={2} textAlign="left">
                          {location.state && location.state.course_searched_name}
                        </Box>
                      </Typography>
                      <Box>
                        <Suspense fallback={<div> <Course_Module_Skeleton /></div>}>
                          <Box textAlign="left"><CourseModule /></Box>
                        </Suspense>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Suspense fallback={<div> <Course_Top_Skeleton /></div>}>
                    <CourseViewRight />
                  </Suspense>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Suspense fallback={<div> <Course_Mid_Skeleton /></div>}>
                <CourseViewMid />
              </Suspense>
            </Grid>
            <Grid item xs={12}>
              <Suspense fallback={<div> </div>}>
                <CourseViewBot 
				university= {courseDetails.map((e, index) => (e['university']))} 
				/>
              </Suspense>
            </Grid>
          </Grid>
        </div>
		)}
		</>
      </Blur>
    </React.Fragment>
  );
}

export default CourseView;
