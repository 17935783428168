import { Box, Card,CardContent,Container,Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
   card:{padding:"0px !important",
		backgroundColor:"#25387C",borderRadius:"4px",
		'border-bottom-left-radius': '60px',
		'border-top-left-radius': '60px'},
		img:{backgroundColor:"white",borderRadius:"55px"},
		overlay:{width:"400px"},
		
		desktop:{bottom:'15%',position:"absolute",right:"26%"},
		mobile:{bottom:'15%',position:"absolute",right:"20%"},
		center:{position:'absolute',left:isMobile?'50%':'60%',transform:'translate(-50%, -127%)'}
}));

class Scroll extends Component {
constructor(props) {
    super(props);

	this.state={webinar_list:[],speaker_list:[]}}

	
    render()
	{
		const classes = this.props.classes;
		
		const Title = () => {
		return (		
		<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={4} 
					style={{backgroundColor:'white'}}
					>
		
		<Grid item  xs={12} sm={8} >
			<Typography >
					  <Box fontWeight="700" lineHeight={1.25} fontSize="56px" marginBottom={3}
					  style={{color:'rgb(90 97 104)'}} textAlign="center" padding={2}>	
						About Event
					  </Box>
			</Typography>
		</Grid>
		</Grid>
		)}
		
		const ImgGrid = () => {
		return (
			<Grid container direction="row"
						alignItems="center"
						justify="center"
						spacing={4} 
						style={{backgroundColor:'white'}}
						>	
			<Grid item  xs={12} lg={10}
			container className={classes.card}>
					
					<Grid item xs={12} lg={7} >
						<Card elevation={0} className={classes.img}>
						<CardContent >
						<Box padding={3} >
						<img src="https://opengrowthacademy.com/assets/images/courses/11.png" height="420px" width="100%" style={{borderRadius:'20px'}} />
						</Box>
						
						</CardContent>
						</Card>
						</Grid>
			</Grid>
			</Grid>
		)}	
	
	const Overlay = () => {
	return (
		<Card className={classes.overlay}>
		<CardContent>
		<Typography>
			  <Box  lineHeight={1.25} color="text.primary" 
			  textAlign="center"
			  fontSize="30px" padding={3}>
				  {"This Women's day, We are all set to "}
				<Box display="inline" fontWeight="600"  style={{color:"#2B579A"}}>#BreakTheBias!</Box>
				{" We take this chance to love, respect, and appreciate women!"}
			  </Box>
			  </Typography>
		</CardContent>
		</Card>
	)}

		return ( 
		
		<Box marginTop={4}  marginBottom={1} >
		<Container>
			
		
		<Box width="100%" height="100%" p={1} position="relative">
		
		<Box >
		<Title/>
		<ImgGrid />					   
	   </Box>
	   
	   <Box className={classes.center}>
	   <Overlay />	
	   </Box>
	   
	    </Box>
		</Container>
	    </Box>

    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
