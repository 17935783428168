import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useStyles } from "../../useStyles";
import './index.css';






function Result(props) {
const classes = useStyles();
const color={ 'font-weight': '500','white-space':'unset'}
var questions =   props.quizQuestions
//var answers =   JSON.parse(localStorage.getItem("quizAnswers"))

//var incorrect=(props.incorrect).split(',')
//var skipped=(props.skipped).split(',')
//incorrect=incorrect.replace(",", "")


  //console.log(props.quizResult)
  //console.log(props.total)
  //console.log(props.correct)
  //console.log(props.grade)
  //console.log(props.duration)
  //console.log(props.incorrect)
  //console.log(props.incorrect_ans)
  //console.log(props.skipped)
  //console.log(props.skipped_ans)
  //console.log(props.quizQuestions)
	
	const GetRes = ({quest_id,ans_type}) =>
	{
		let ans=questions[quest_id]['answers']
		let found_ans='';
		
		for (let i=0;i<ans.length;i++)
		{
			if(ans[i]['type']==ans_type)
			{
				found_ans=ans[i]['content']
				break;
			}	
		}	
		
		return(
		<>
		{" "+found_ans}
		</>
		)
	}
	const RenderQuestion = ({question,index}) => {
        let arr=String(question)
        arr=(arr).split("<br>");
	    return (
			<Box  display="block" textAlign="left"  >
            <Box display="flex" textAlign="left" marginBottom={1}>
			  <Box fontWeight={600}>{index+". "}&nbsp;</Box>
			  <div dangerouslySetInnerHTML={{ __html: question }} />
			 </Box>
			</Box>
	)}
	
	
	return (
    <CSSTransition
      className="container result px-2"
      component="div"
      transitionName="fade"
      transitionEnterTimeout={800}
      transitionLeaveTimeout={500}
      transitionAppear
      transitionAppearTimeout={500}
    >
		
	<Card elevation={0}>		
	<CardContent>
       {/*props.quizResult*/}
		
		
		<Paper
		style={
			props.grade<40?{backgrounddColor:'#ffccc9',color:'#f44336'}
			:props.grade<70?{backgrounddColor:'#c6e6ff',color:'#2196f3'}
			:{backgrounddColor:'#b6f8b9',color:'#4caf50'}
			} {...color} p={2}>
			
			<Box  justifyContent="center" p={2} textAlign="center">

			<Box component="span" display="block"  	 >
			<Typography variant="subtitle1" >
			{props.grade<40?"Better luck next time !":props.grade<70?"Congratulations! You passed !":"Congratulations! You passed !"}
			</Typography>
			</Box>
			
			<Box component="span" display="block" >
			<Typography variant="subtitle1" >
			Your score : {props.grade}%
			</Typography>
			</Box>
			
			<Box component="span" display="block"  >
			<Typography variant="subtitle1" >
			Grade  : {props.grade<40?"C":props.grade<70?"B":"A"}
			</Typography>
			</Box>
			
			</Box>
			
		</Paper>
				
		<Box marginTop={2} marginBottom={3}>
		<Typography component="div" variant="h6">
			<Box display="inline" variant="h6" fontWeight="fontWeightBold">
			Quiz result
			</Box>
			</Typography>
			
			<Box marginTop={1} marginBottom={1}>
			<Divider />
			</Box>
			
			 <TableContainer component={Paper}>
			  <Table className={classes.table} aria-label="simple table">
				<TableBody>
					
					<TableRow >
					  <TableCell component="th" scope="row">
					  Total Question
					  </TableCell>
					  <TableCell align="right">
					  {props.total}
					  </TableCell>
					</TableRow>
					
					<TableRow >
					  <TableCell component="th" scope="row">
					  Incorrect answer
					  </TableCell>
					  <TableCell align="right">
					  {props.incorrect.length}
					  </TableCell>
					</TableRow>
					
					<TableRow >
					  <TableCell component="th" scope="row">
					  Skipped questions
					  </TableCell>
					  <TableCell align="right">
					  {props.skipped.length}
					  </TableCell>
					</TableRow>
					
					<TableRow >
					  <TableCell component="th" scope="row">
					   Duration
					  </TableCell>
					  <TableCell align="right">
					  {props.duration}
					  </TableCell>
					</TableRow>
					
				</TableBody>
			  </Table>
			</TableContainer>
			</Box>

			
		
		{
			props.incorrect.length!=0?
			<Box marginTop={2} marginBottom={2}>
			<Typography component="div" variant="h6">
				<Box display="inline" variant="h6" fontWeight="fontWeightBold">
				Incorrect Answers
				</Box>
			</Typography>
			
			<Box marginTop={1} marginBottom={1}>
			<Divider />
			</Box>
			
			<List>
			{
					props.incorrect.map((e, index) => (
					<>
					{
						props.incorrect[index]==','?'':
						 <ListItem>
						 <ListItemIcon>
						  <CloseIcon color="error" />
						</ListItemIcon>
						
						<ListItemText 
						primary={
						<RenderQuestion 
						question={questions[props.incorrect[index]]['question']} 
						index={index+1} />
						}
						/*secondary={ 	
						<Typography component="div" variant="subtitle2">
						<Box display="inline" variant="subtitle2" 
						fontWeight="fontWeightBold">
						Answer :
						</Box>
						<GetRes quest_id={props.incorrect[index]} ans_type={props.incorrect_ans[index]} />
						</Typography>}
						*/
						/>
					  </ListItem>
						
					}	
					</>))
			}
			</List>
			</Box>
			:''
		}
		
				
		{
			props.skipped.length!=0?
			<Box marginTop={2} marginBottom={2}>
			<Typography component="div" variant="h6">
				<Box display="inline" variant="h6" fontWeight="fontWeightBold">
				Skipped Questins
				</Box>
			</Typography>
			
			<Box marginTop={1} marginBottom={1}>
			<Divider />
			</Box>
			
			<List>
			{
					props.skipped.map((e, index) => (
					<>
					{
						props.skipped[index]==','?'':
						<ListItem>
						
						<ListItemIcon>
						 <KeyboardArrowRightIcon color="primary" />
						</ListItemIcon>
						
						<ListItemText 
						primary={
						<RenderQuestion 
						question={questions[props.skipped[index]]['question']} 
						index={index+1} />}
						/*secondary={	
						<Typography component="div" variant="subtitle2">
						<Box display="inline" variant="subtitle2" 
						fontWeight="fontWeightBold">
						Answer :
						</Box>
						<GetRes quest_id={props.skipped[index]} ans_type={props.skipped_ans[index]} />
						</Typography>
						}*/
						/>
					  </ListItem>
						
					}	
					</>))
			}
			</List>
			</Box>
			:''
		}
			
		</CardContent>
		</Card>
    </CSSTransition>
  );
}

Result.propTypes = {
  quizResult: PropTypes.string.isRequired
};

export default Result;
