import {
	 Grid
} from '@material-ui/core';
import React, { Suspense, useEffect, useState, useContext } from 'react';
import {CommunitySkeleton,User_Course_Skeleton,AttendSkeleton,ArticleSkeleton} from '../Skeleton';
const JoinedCommunityCard = React.lazy(() => import('./JoinedCommunityCard'));
const RecommendedCommunityCard = React.lazy(() => import('./RecommendedCommunityCard'));
//const RecommendedCommunityCard = React.lazy(() => import('../blogs/PopularBlogs'));

const JoinedCommunity = () => {
  return (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} md={8}  >
            <Suspense fallback={<div><CommunitySkeleton action="vertical" /></div>}>
              <JoinedCommunityCard />
			</Suspense>
      </Grid>
	  <Grid item xs={12} md={4}  >
            <Suspense fallback={<div><ArticleSkeleton/></div>}>
              <RecommendedCommunityCard />
			</Suspense>
	  </Grid>	
	</Grid>
  )
}

export default JoinedCommunity;