
import { Box,Typography } from '@material-ui/core';
import React,{Component} from "react";
import SmartSlider from "react-smart-slider";
import "react-multi-carousel/lib/styles.css";
import {Skeleton} from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
  back:{
    position: 'relative',
    backgroundColor:'#363732b0',
	height:"inherit",
	width:"100%",
  },
  center:{
	top: '50%',
    left: '50%',
	width: '80%',
    transform: 'translate(-50%, -50%)',
    transition: 'height .4s ease-in-out',
    bottom: '25%',
    padding: '0px 28px',
    position: 'absolute'
  }
}));

class Simple extends Component {

    constructor(props) {
    super(props);
	this.state={is_image:true}}
	
	componentDidMount()
	{
		setTimeout(() => {
			//alert("jhgjhg")
			this.setState({is_image:false})
			this.forceUpdate();
		}, 2000);
			
	}
	
	render()
    {
		const classes = this.props.classes;
		const DummyCaption = ({ name,desc }) => (
		  <div className={classes.back}>
		
		<Box className={classes.center} >
	   <Box textAlign="center" lineHeight={1.5}
	   sx={{ fontStyle: 'italic' }} display="flex" justifyContent="center" alignItems="center" >
	   <Typography variant="h6"  style={{color:'#ebe8e8'}}>
	   {"\""+desc+"\""}
	   </Typography>
	   </Box>
	   
	   {
		   isMobile==false?
		   <Box marginTop={2} display="flex" justifyContent="center" alignItems="center" >
		   <Typography variant="h6"  style={{color:'#ebe8e8'}}>
		   {name}
		   </Typography>
			</Box>:""
		}
	   
	   </Box>
   
  
  </div>
		)
		 		let path="/assets/images/homepage/images/events/"

		const slidesArray = [
			  {
				url:path+"car1.jpg",
				// (Optional) Set if you want to add any content on your slide
				childrenElem: <DummyCaption name="Anne Sweeney, President, Walt Disney" desc="Define success on your own terms, achieve it by your own rules, and build a life you’re proud to live." />
			  },
			  {
				url:path+"car2.jpeg",
				childrenElem: <DummyCaption  name="Martha Stewart, American businesswoman" desc="Build your business success around something that you love - something that is inherently and endlessly interesting to you." />
			  },
			  {
				url:path+"car3.jpg",
				childrenElem: <DummyCaption  name="Sheryl Sandberg, Chief Operating Officer of Facebook" desc="True leadership stems from individuality that is honestly and sometimes imperfectly expressed… Leaders should strive for authenticity over perfection." />
			  },
			];
		return (
		<Box padding={0} marginTop={4}  marginBottom={4} >
		{
			
				  this.state.is_image==true
				  ?
				  
						<Skeleton variant="rectangular"
										 height="45vh" width="100%" 
										style={{borderRadius:'12px'}} />
				  
				  
				  :  
				  <SmartSlider
					slides={slidesArray}
					buttonShape="round" // round or square
					autoSlide={true} 
				  />
		}
		</Box>
		);
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Simple classes={classes} />
    )
}
