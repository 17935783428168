import { Box,Button, Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
btn:{fontWeight:"700",color:'white',
padding:"5px 20px",fontSize:"18px"}
}));

class Scroll extends Component {
	constructor(props) {
    super(props);}
	
    render()
	{
		const classes = this.props.classes;
		
		const Title = () => {
		let wd=isMobile==true? (70*(window.innerWidth)/100)+"px":'525px';
		return (		
		<Grid container spacing={0}
		direction="row" style={{backgroundColor:'#25387C'}}>
		
		
	
			<Grid item xs={1} >
			</Grid>
		
	
			
		<Grid item  sm={8} lg={5} 
		 container 
		  spacing={0}
		  direction="row"
		  alignItems="center"
		  justify="center"
		  >
		  
		 <Grid item xs={12} >
		 <Box
			padding={2} 
			margin={"auto"} 
			display="flex" 
			alignItems="center" 
			justifyContent="center">
			<Typography>
					  <Box fontWeight="700" lineHeight={1.5} fontSize="40px" 
					  sx={{color:"white"}} textAlign="left" padding={1}>	
						Event Name
					  </Box>	
					  <Box lineHeight={1.5} fontSize="16px"
					  width={"inherit"}
					  textAlign="left" padding={1} sx={{color:'white'}}>	
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget enim orci vitae integer convallis nulla tristique nulla. Curabitur vitae purus proin dignissim vulputate accumsan, ut nibh morbi.
					  </Box>
					  
					  <Box textAlign="left" 
					  padding={1} marginTop={2}>	
					 <Button
					 className={classes.btn}
					 variant="contained" 
					 size="large"  
					 color="secondary">Learn more
					</Button>
					</Box>
			</Typography>
			</Box>
	
		</Grid>	
		</Grid>	
		

		
		{
			isMobile==false?
			<Grid item  xs={12} lg={5} >
		   <Box  display="flex" alignItems="center" justifyContent="center" marginBottom={8}>
			<img src="/assets/images/homepage/images/events/event.png" height="90%" width="inheit"  />
			</Box>
			</Grid>
			:""
		}
		
		</Grid>
		)}

				
		
		return ( 
		<>
		<Title/>
		</>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
