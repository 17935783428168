import React, { Component } from 'react';
import {
  Avatar,
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  IconButton,
  MenuItem,
  makeStyles,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  TextField,
  InputBase,
} from '@material-ui/core';
import SearchBar from "material-ui-search-bar";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertBox from '../../functions/AlertBox';
import { translateText, api } from '../../functions/Helper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#497ba7',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
      backgroundColor: 'transparent',
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'primary',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(1.5, 1.5, 1.5, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },
}));

class Logs extends Component {
  constructor(props) {
    super(props);
    this.translateText = translateText.bind(this);
    this.state = {
      msg: false,
      msg_type: false,
      img: false,
      user_show: [],
      user_count: 0,
      start: 0,
      count: 0,
      end: 5,
      hasMore: true,
      loading: true,
      record_len: 0,
      search_for: 'student',
      search: null,
      email: null,
    };
  }

  fetchMoreData = () => {
    if (this.state.hasMore !== false) {
      setTimeout(() => {
        this.setState({ start: this.state.start + this.state.end });
        this.getUsers(this.state.start + this.state.end );
      }, 1500);
    }
  };

  delete_academy_user = (email) => {
    axios
      .post(api + '/delete_with_admin', {
        user: this.state.search_for,
        email: email,
        platform: 'academy',
      })
      .then((response) => {
        this.setState({ msg: response.data.msg });
        this.state.msg_type = response.data.type;
        this.getUsers(0);
        setTimeout(() => {
          this.setState({ msg: false });
        }, 3000);
      });
  };

  getData = () => {
    this.setState({
      hasMore: true,
      start: 0,
      end: this.state.end,
    }, () => {
      this.getUsers(0);
      this.getCount();
    });
  };

  getUsers = (start) => {
	if (start === 0) 
	{
		this.setState({ user_show:[]})
	}	
	this.setState({ loading: true });
    axios
      .post('/api/get_academy_user', {
        start: start,
        end: this.state.end,
        user: this.state.search_for,
        search: this.state.search,
      })
      .then((response) => {
        if (start === 0) {
			if((response.data).length==0)
			{
				if(this.state.search!=null)
				{
					this.setState({ msg: `No '${this.state.search_for}' found for the searched text '${this.state.search}'` });
					 setTimeout(() => {
					  this.setState({ msg: false });
					}, 2000);
				}
			}
            else
		    {
			  this.setState({ user_show: response.data });
			}
        } else {
          this.setState({ user_show: [...this.state.user_show, ...response.data] });
        }

        if (response.data.length < this.state.end) {
          this.setState({ hasMore: false });
        }else{
          this.setState({ hasMore: true });
        }
		this.setState({ loading: false });
		
      });
  };

  getCount = () => {
    axios
      .post('/api/get_academy_user_count', {
        user: this.state.search_for
      })
      .then((response) => {
       this.setState({ count: response.data });
      });
  };

  handleSearchChange = (event) => {
    this.setState({ search: event.target.value });
  };

  clearSearch = () => {
    this.setState({ search: null });
  };

  handleSearchSubmit = () => {
    this.getData();
  };

  componentDidMount() {
    this.getUsers(0);
	this.getData();
	this.getCount();
  }

  render() {
    const classes = this.props.classes;

    const Tables = () => (
      <>
        {this.state.user_show.map((e, index) => (
          <StyledTableRow key={index}>
            <StyledTableCell>
              <Box display="flex" flexDirection="row" p={1} m={1}>
                <Box p={1}>
                  <Avatar src={`assets/images/users/${e['img']}`} />
                </Box>
                <Box p={1}>{e['email']}</Box>
              </Box>
            </StyledTableCell>
            <StyledTableCell>{this.state.search_for === 'professor' ? 'Mentor' : 'Learner'}</StyledTableCell>
            <StyledTableCell>{e['name']}</StyledTableCell>
            <StyledTableCell>{e['interest']}</StyledTableCell>
            <StyledTableCell>{e['edu']}</StyledTableCell>
            <StyledTableCell>{e['industry']}</StyledTableCell>
            <StyledTableCell>
              {e['added_on']}
              <IconButton onClick={() => this.delete_academy_user(e['email'])}>
                <CloseIcon color="grey" />
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </>
    );

    return (
      <Box padding={1}>
        <Card>
          {this.state.msg !== false ? (
            <AlertBox
              show_status={true}
              msg={this.state.msg}
              msg_type={this.state.msg_type === 'success' ? 'success' : 'warning'}
            />
          ) : (
            ''
          )}
          <CardHeader
            title={
              <Box display="flex" >
                <Box flexGrow={1}>
                  <Box fontSize="16px" fontWeight="700" >
                    Academy users
                  </Box>
                  <Typography variant="subtitle2"  color='textSecondary' className={classes.iconstext}>
                    Total {this.state.search_for === 'student' ? 'Learner' : 'Mentor'} : {this.state.count}
                  </Typography>
				   <Box marginTop={2} fontSize="16px"  fontWeight="400" >
					   {this.state.msg}
                  </Box>
                </Box>
                <Box>
					<Box  display="flex" justifyContent="end" alignItems="center">
                    <Box marginRight={1} minWidth="200px" marginBottom ={1}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel>Search</InputLabel>
                      <Select
					    size= 'medium'
                        name="search_for"
                        onChange={(event) => this.setState({ search_for: event.target.value })}
                        label="Search"
                        value={this.state.search_for}
                      >
                        <MenuItem value={'student'}>Learner</MenuItem>
                        <MenuItem value={'professor'}>Mentor</MenuItem>
                      </Select>
                    </FormControl>
                    </Box>
                    <Box>
				     <Button 
					 variant="contained"
					 color="primary"
					 size="medium" onClick={this.getData}>
                      Get users
                    </Button>
                    </Box>
                    </Box>
				  
                  <Box>
				  <SearchBar
					value={this.state.search!=null?this.state.search:""}
					onChange={(newValue) => this.setState({ search: newValue })}
					onRequestSearch={() => this.handleSearchSubmit(this.state.value)}
					onCancelSearch={this.clearSearch}
					placeholder="Search with email or name"
				  />
                  </Box>
                </Box>
              </Box>
            }
          />

          <CardContent>
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={this.state.user_show.length}
              hasMore={this.state.hasMore}
              loader={
                this.state.hasMore === true ? (
                 <Box marginTop={2} 
						marginBottom={2}
						justifyContent='center'
						alignItems='center'
						display='flex'>
						<Button 
					 variant="outlined"
					 color="primary"
					 size="medium" onClick={this.fetchMoreData}>
				     {
						this.state.loading === true ? (
						<CircularProgress disableShrink size={20} color='primary' />
						)
					  :"Know More"
					 }
                  </Button>
                  </Box>
                ):""
              }
              endMessage={
                <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} marginTop={2} marginBottom={2}>
                  <Typography variant="subtitle2" color="textPrimary">
                    You have seen it all
                  </Typography>
                </Box>
              }
            >
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>User</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>Interest</StyledTableCell>
                      <StyledTableCell>Qualification</StyledTableCell>
                      <StyledTableCell>Industry</StyledTableCell>
                      <StyledTableCell>Added On</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Tables />
                  </TableBody>
                </Table>
              </TableContainer>
            </InfiniteScroll>
          </CardContent>
        </Card>
      </Box>
    );
  }
}

export default () => {
  const classes = useStyles();
  return <Logs classes={classes} />;
};
