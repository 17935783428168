import { Box, Container,Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({
		icon:{position:'relative',top:'-12vh',padding:'0px 16px'}	,
		center:{position:'relative',bottom:isMobile?'':'15vh',left:isMobile?'':'15vh'},
		img:{height:"auto",width:"calc(100% - 100px)"}
}));

class Scroll extends Component {
constructor(props) {
    super(props);

	this.state={webinar_list:[],speaker_list:[]}}

	
    render()
	{
		const classes = this.props.classes;
		
		const Title = () => {
		return (		
		<Grid container spacing={0}
		direction="row"
                    alignItems="center"
                    justify="center"
			
					style={{backgroundColor:'#25387C'}}
					>
		
	
			
		<Grid item  xs={12} lg={10} >
		   <Container>
		   <Box marginTop={8} display="flex" alignItems="center" justifyContent="center">
			<Typography>
					  <Box fontWeight="700" lineHeight={1.5} fontSize="48px" 
					  sx={{color:'#F9BB02'}} textAlign="center" padding={1}>	
						Celebrating International Women's Day <br/>#IAmTheChange
					  </Box>	
					  <Box lineHeight={1.25} fontSize="16px" width="100%"
					  textAlign="center" padding={1} sx={{color:'white'}}
					  display="flex" alignItems="center" justifyContent="center">
					  <Box width="70%" >
						This Women's day, OpenGrowth Academy is all set to #BreakTheBias! We take this chance to love, respect, and appreciate women! Be a part of this great initiative in building a better society for women and encourage youth for Innovation & Entrepreneurship.
					  </Box>
					  </Box>
			</Typography>
			</Box>
			
				
			{
				isMobile==false
				?
				<Box className={classes.center}>
				<img src="/assets/images/homepage/images/events/aapturea.jpg" height="inherit" width="inheit"  />
				</Box>
				:''
			}
	
			</Container>
		</Grid>	
		
		
				<Grid container direction="row"
					spacing={0} 
					style={{backgroundColor:'#25387C'}}
					>
		
			
		<Grid item  xs={0} lg={1} >
			
		</Grid>	
		
		<Grid item  xs={12} lg={4} >
			<Container className={classes.icon}>
			{
				isMobile==false
				?
				<img 
				src="/assets/images/homepage/images/events/tcapturepr.png" 
				height="70px" width="100%"  />
				:<Box marginTop={20} padding={4}>
				<img 
				src="/assets/images/homepage/images/events/tcapturepr.png" 
				className={classes.img}  />
				</Box>
			}
		
			</Container>	
		</Grid>	
		</Grid>
		
		</Grid>
		)}

		
		return ( 
	
		<Box position="relative" zIndex="999">
		<Title/>
		</Box>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
