import {
	Box,
	Grid, 
	
	ThemeProvider, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	

		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center">
		
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 1.	Introduction
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" color="textSecondary"> 
					 <Box  marginTop={1.5} lineHeight={1.5}> OpenGrowth Academy’s Privacy policy applies to all our users, students, teachers and visitors visiting the OpenGrowth Academy website through the internet and have access to the Academy through computers, mobiles, laptops or any other electronic devices. The words ‘we’, ‘us’ and ‘our’ refer to OpenGrowth Academy and the policy identifies the personally identifiable or personal information that may be collected and how much information is used and the choices made. The term ‘you’, ‘user’, may be referred to any individual major or minor who has visited OpenGrowth Academy or subscribed to any of its services/courses.  </Box>
					 </Typography> 
					
					</Box>			
		</Grid>
		</Grid>
														
		

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
