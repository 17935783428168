import React, { useState, useEffect,useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Avatar , IconButton, Button, Box, Card, CardMedia , CardContent, Chip, CardHeader, CircularProgress, Grid, Typography,makeStyles } from '@material-ui/core';
import { getCacheDetails } from '../caching';
import { interest_list, get_name_slug,generateProfileUrl, user_id,user_status_id, api,userProfile_path } from '../Helper';
import AlertBox from '../AlertBox';
import CancelIcon from '@material-ui/icons/Cancel';
import { Action } from '../Skeleton';
import {
  ViewMentors_Skeleton
} from '../Skeleton';
import ProfilePopup from '../mentors/dashboard/ProfilePopup';
const FollowButton = React.lazy(() => import('../profile/FollowButton'));
const WriteMessage = React.lazy(() => import('../profile/WriteMessage'));

let user=user_status_id
let email=null;
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: theme.breakpoints.up('xs') ? '100%' : '225.46px',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
	 transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
	 marginTop:'3px',
      transform: 'translateY(-2px)',
    },
  },
  cover: {
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      width: 200,
      height: 'auto',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
  },
  name: {
    fontWeight: 700,
    cursor: 'pointer',
    color: '#25387c',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  industry: {
    color: '#25cb37',
  },
  about: {
	  
    'line-height': '1.5',
    marginTop: '8px',
    color: '#5a6978',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
	marginBottom:'8px'
  },
  imageBox: {
    width: '100%',
	maxWidth:'-webkit-fill-available',
   [theme.breakpoints.up('sm')]: {
    width: '22vw',
    },
   [theme.breakpoints.up('md')]: {
    width: '14vw',
    },
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  viewchip:{
	color:'white',
    backgroundColor: '#25387c',
	'&:hover': {
		backgroundColor:'white',
		color: '#25387c'
	  }
  },
  interest:{
	  backgroundColor:'rgba(37, 56, 124, 0.04)',
	  color: '#25387c',
	  fontWeight: 700,
	  border: '1px solid #d3d5db'
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '0px'
  },
  link:{cursor:'pointer'},
  mr:{marginRight:'12px'},
  overflow:{overflow:'hidden !important'},
  maxh:{ height: '100%' },
  bg:{backgroundColor: '#fbfbfb'},
  col:{color:"#25387c"} 
}));



function MembersTab(props) {
  const [renderList, setRenderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [, forceUpdate] = useState(null);
  const [msg, setMsg] = useState(false);
  const [followee, setFollowee] = useState([]);
  const [msgType, setMsgType] = useState('success');
  const [search, setSearch] = useState(null);
  const [searchWith, setSearchWith] = useState("category");
  	const [profile_popop, setPopop] = React.useState(false);
	const [profile_details, setDetails] = React.useState(null);

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  let community_id = ''
 
	if(location.state)
	{
		 community_id =location.state.id
	}
	else
	{
		community_id=(props.community)['id']
	}

  useEffect(() => {
	   console.log(props)
  console.log(location)
	  removeKey();
	  getFollow();
  }, [props.isMember]);

  const handleParent = () => {
	  getFollow();
  }
  const getFollow = () => {
	  
	 let payload = {
        follower_id:user_id,
        follower_status:user_status_id
      };

      //console.log(payload);

      axios.post(api + "/get_user_followee", payload).then((response) => {
        let res = response.data;
        //console.log(res);
          setFollowee(res);
		 // alert("test")
      });
  }; 
  
  const fetchMoreData = () => {
    if (hasMore !== false) {
      setLoading(true);
      setTimeout(() => {
        setStart(start => start + end);
        if (search !== null) {
          getSearchRecord(start + end, search);
        } else {
          getCachePlay(start + end, true);
        }
      }, 2000);
    }
  };
  
	const closeCallback = () => {
		setPopop(false)
	}	
	
	const handleFullProfile = (mentor) => {	
	//console.log(mentor)
	let name=get_name_slug(mentor['name']);
    let mail_url=generateProfileUrl(mentor['email'])

	history.push({
	  pathname: '/profile/'+mail_url,
	  state: {
			profile_details:mentor,
			profile_mail:mentor['email'],
			profile_type:mentor['user'],
			profile_id:mentor['user_id'],
			course_author_id:mentor['user']=='professor'?mentor['user_id']:null
	  },
	})
	}
	

	const handleInstructorProfile = (mentor) => {
		//alert(mentor['id'])
		setPopop(true)
		setDetails(mentor)

	};
  const getCachePlay = (start, fetch) => {
	  let payload={
		  start,
		  end,
		  community_id
	  }
	  //console.log(payload)
    const cacheKey = 'list_community_members';
	getCacheDetails(cacheKey, 'list_community_members', payload)
  .then(data => {
    //console.log(data);
	localStorage.removeItem(cacheKey);
	setLoading(false);
	if (data.length < end) {
	  setHasMore(false);
	} else {
	  setHasMore(true);
	}
	if (start === 0) {
	  if (data.length === 0) {
		setMsg('Members are not found!');
	  } else {
		setRenderList(data);
	  }
	}
	else {
	  if (data.length > 0) {
		setRenderList([...renderList, ...data]);
	  } else {
		setHasMore(false);
		setMsg('You have seen it all!');
	  }
	}	  
      setTimeout(() => {setMsg(false); }, 2000);
  })
  .catch(error => {
    console.error(error);
  });

    
  };

  const manageNewSearch = key => {
	setRenderList([])
	  setStart(0)
	  setHasMore(true)
    setSearch(key);
    forceUpdate(n => !n);
    getSearchRecord(0, key);
    forceUpdate(n => !n);
  };

  const getSearchRecord = (start, key) => {
	  console.clear()
	  //console.log(renderList)
    const param = {
      search_by: searchWith,
      keyname: key,
      start,
      end,
	  community_id
    };
    //console.log(param)
    axios.post(api + '/list_community_members', param).then(response => {
    	  
	const data = response.data;
	//console.log(data)
	setLoading(false);
	if (data.length < end) {
	  setHasMore(false);
	} else {
	  setHasMore(true);
	}
	if (start === 0) {
	  if (data.length === 0) {
		setMsg('Members are not available under this category!');
	  } else {
		setRenderList(data);
	  }
	}
	else {
	  if (data.length > 0) {
		setRenderList([...renderList, ...data]);
	  } else {
		setHasMore(false);
		setMsg('You have seen it all!');
	  }
	}

      forceUpdate(n => !n);
    });
    setTimeout(() => {setMsg(false); }, 2000);
	//console.log(renderList)
  };



  const removeKey = () => {
	setRenderList([])
	  setStart(0)
	  setSearch(null)
	  setHasMore(true)
	  getCachePlay(0, false);
  };


		const IconCard = ({ member }) => {
			const profile = {
			profile_id: member.user_id,
			profile_type: member.user,
			profile_mail: member.email,
		  }
		  const isCurrentUser = user_id==member.user_id && user_status_id==member.user;
		  
		  return (
			<Box display="flex" justifyContent="end">
			  {!isCurrentUser && (
				<Box>
				  <FollowButton 
				  profile={profile} 
				  followee={followee} 
				  parentCallback={handleParent} />
				</Box>
			  )}
			  {!isCurrentUser && (	
			  <WriteMessage profile={profile} />
				)}
			</Box>
		  );
		};
     const InterestCard = ({ member, interest }) => {
  const [isClicked, setIsClicked] = useState(false);
  const interestArr = interest ? interest.split(',') : [interest || ''];
  const hoverCardRef = useRef(null);

  const handleChipClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <React.Fragment>
      {!isClicked && (
        <Typography variant="subtitle2" className={classes.about}>
          {member.about}
        </Typography>
      )}
      <Box className={classes.link} onClick={() => handleInstructorProfile(member)}>
        <Action title={"Know more"} />
      </Box>
      <Box className={classes.iconWrapper} marginTop="16px">
        <Grid container spacing={1} alignItems="center">
          {interestArr.length > 0 && (
            <Grid item xs="auto">
              <Chip
                onClick={() => { manageNewSearch(interestArr[0].trim()) }}
                className={classes.interest}
                label={interestArr[0].trim()}
                size="medium"
                variant="outlined"
              />
            </Grid>
          )}

          {interestArr.length > 1 && !isClicked && (
            <Grid item xs="auto">
              <Chip
                onClick={handleChipClick}
                className={classes.interest}
                label={`+${interestArr.length - 1}`}
                size="medium"
                variant="outlined"
              />
            </Grid>
          )}

          {isClicked && (
            <Grid item xs="auto">
              <div className={classes.hoverCard} ref={hoverCardRef}>
                {interestArr.map((e, index) =>
                  index !== 0 && (
                    <Chip
                      key={index}
                      onClick={() => {
                        manageNewSearch(e.trim());
                      }}
                      className={classes.interest + ' ' + classes.mr}
                      label={e.trim()}
                      size="medium"
                      variant="outlined"
                    />
                  )
                )}
                {interestArr.length > 1 && (
                  <IconButton className={classes.closeButton} onClick={handleChipClick}>
                    <CancelIcon color="textSecondary" fontSize="small" />
                  </IconButton>
                )}
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};






	const RenderCard = ({ member }) => {
	  return (
		<Card className={classes.root}>
		  <CardMedia className={classes.cover}>
			<Box onClick={() => handleFullProfile(member)} className={classes.imageBox}>
				  <Avatar
					src={member.img ? userProfile_path + member.img : ''}
					alt={member.name}
					className={classes.avatar}
				  />
			</Box>
		  </CardMedia>
		  <Box className={classes.details}>
		  <Box className={classes.iconWrapper}  
			height='34px'>
			  <IconCard member={member} />
			</Box>
			<Typography variant="subtitle1" onClick={() => handleFullProfile(member)}
			className={classes.name}>
			  {member.name} 
			</Typography>
			<React.Fragment>
			<InterestCard member={member} interest={member.interest} />
			</React.Fragment>
		  </Box>
		</Card>
	  );
	};


	const MembersList = () => {

		return (
			<InfiniteScroll className={classes.overflow}
			dataLength={renderList}
			hasMore={hasMore}
			loader={ hasMore==true?
							<Box marginTop={2} marginBottom={2}
							alignItems='center'
			display='flex'
			flexDirection='column'>
							{
								loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={loading}
									onClick={fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
							:<CircularProgress disableShrink />
				}
			endMessage={
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>
			}>
						<Grid container spacing={2}>
						 {
							renderList.map((e, index) => (
								 
								 
								<Grid item xs={12} md={6}>
								<RenderCard 
								key={index} 
								member={e} />
								</Grid>
							
						))}
						</Grid>
		 </InfiniteScroll>		
		)
	}
	
    const CategoriesCard = () =>
	{ 
		
			return(	
			<Box padding={0}>
					  <Typography 
					  variant="subtitle1"
					  color="textPrimary" >
						<Box fontWeight="600" marginBottom={2}>Popular Category</Box>
					 </Typography>
					 
					   <Box display="flex" marginBottom={1}>
					   <Grid container spacing={2}>
					   {interest_list.map((e, index) => (
							
							<Grid item xs="auto" key={index}>
							
							<Chip label={<Box fontWeight="bold" fontSize="12px">{e}</Box>} 
							 variant={e==search?"contained":"outlined"}
							 color={e==search?"primary":""}
							clickable onClick={()=>{manageNewSearch(e)}}
							/>
							</Grid>
							
						))}
						<Grid item xs="auto">
						<Chip 
						className={classes.viewchip}
						variant="contained"
						label={<Box fontWeight="bold" fontSize="12px">View All</Box>} 
							 variant="contained"
							 color="primary"
							clickable 
							onClick={removeKey}
							/>
						</Grid>
						</Grid>
						</Box>

			</Box>
			)
	}
	return ( 
					
	<Card variant="outlined"  className={classes.maxh}>
      <CardHeader title="Community Members" />
      <CardContent>
	 	  	{
				profile_popop
				?<ProfilePopup profile_details={profile_details} handleCallback={closeCallback} />
				:''
		}
	
		<Box>
		{
			msg!=""
			? <AlertBox 
			show_status={true} 
			msg={msg} 
			msg_type={msgType} />
			:''
		}
		
			<Grid container  spacing={3}>
			
			<Grid item xs={12} sm={12}>
				<CategoriesCard />
			</Grid>
			
			{search==null?"":
			<Grid item xs={12} sm={12}>
			<Box marginTop={0}  margin={4} >
			<Typography variant="subtitle1" >
			<Box fontWeight="bold">Searched keys</Box>
			</Typography> 
			<Box display="flex" direction="row" 
			className={classes.chipbox+" "+classes.bg}  borderColor="grey.300"  
			border={1}  margin={1} padding={2}  borderRadius={12} > 
			<Chip 
			label={search} 
			onDelete={removeKey} 
			variant="outlined"
			deleteIcon={<CancelIcon className={classes.col} />} />
			</Box>	
			</Box>
			</Grid>
			}
			
			</Grid>
			
			
			<Box display='none'>{renderList.length}</Box>
			<Box marginTop={2}>
			{
			  renderList.length === 0 ? (
				loading === true ? (
				  <ViewMentors_Skeleton action="mentors" />
				) : (
				  <Typography variant="subtitle1" color="textSecondary" align="center">
					Members are not found
				  </Typography>
				)
			  ) : (
				<MembersList />
			  )
			}

			</Box>
			</Box>
	
     
      </CardContent>
    </Card>
	);

}


export default MembersTab;