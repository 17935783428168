import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {Box, Button} from '@material-ui/core';
import { api,config } from "../../functions/Helper";
//import config from '../../../translation/config.json';

function PostExample() {

  const handleText = async (e) => {
    e.preventDefault();
    const response = await axios.post(api+"/getConstants");
    //console.log(response);
    ////console.log(data);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.post(api+"/build-config");
    //console.log(response);
    ////console.log(data);
  };

  useEffect(() => {
    //console.log(config['mentor']);
	alert("hj")
  },[]);


  return (
     
	    <Box display="flex" 
		alignItems="center"
		justifyContent="center" padding={4} height="600px">
		<Box padding={2}>
		<Button
        variant="contained"
        color="primary"
        size="large"
		style={{height:"fit-content"}}
		onClick={handleText}>
		Build Text Constant
		</Button>
		</Box>
		
		<Box padding={2}>
		<Button
        variant="contained"
        color="primary"
        size="large"
		style={{height:"fit-content"}}
		onClick={handleSubmit}>
		Build config
		</Button>
		</Box>
		
		
		</Box>
		);
}

export default PostExample;
