import React from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip, Box, Link, IconButton, Avatar, Card, CardContent, Typography, makeStyles } from '@material-ui/core';
import { getKeys} from '../caching';
import { user_id, user_status_id, community_path } from '../Helper';
import EditIcon from '@material-ui/icons/Edit';

const member_useStyles = makeStyles((theme) => ({
  small: {
    width: '100px',
    height: '100px'
  },
  chip: {
    backgroundColor: '#25387c !important',
    color: 'white !important'
  },
  hovercard: {
    margin: window.location.pathname=="/our-communities"?'8px':"8px",
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  name: {
    cursor: 'pointer',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  about: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  iconsbg: { backgroundColor: '#0000001f', padding: '8px', marginBottom: '8px' },
  mentorcard: { paddingTop: '56px', borderRadius: '12px',minHeight: '208px' },
}));

const CommunityRenderCard = (props) => {
const { e } = props;
//console.log(e);
  const history = useHistory();
  const classes = member_useStyles();

  const findCommunity = (data, action) => {
    let path = '/manage-community/update/';
    if (action === 'view') {
      path = '/community/';
    }

    let hosts_values = [];
    if (data['host_name'] !== null) {
      const inst_id = data['hosts'];
      const host_id = inst_id.split(',');

      const inst = data['host_name'];
      const host_val = inst.split(',');

      const ar = [];
      for (let i = 0; i < host_val.length; i++) {
        const t = { id: Number(host_id[i]), name: host_val[i] };
        ar.push(t);
      }
      //console.log(ar);
      //console.log(JSON.stringify(ar));
      hosts_values = JSON.stringify(ar);
    }

    const community_keys_1 = getKeys('_professor_community_');
    const community_keys_2 = getKeys('_professor_community_');
    const keys = community_keys_1.concat(community_keys_2);

    const community = {
      id: data['id'],
      title: data['title'],
      category: data['category'],
      description: data['description'],
      banner: data['banner'],
      hosts: data['hosts'],
      host_email: data['host_email'][0],
      host_name: data['host_name'][0],
      host_user: data['user'][0],
      host_image: data['host_image'][0],
      hosts_values,
      keys,
    };

    history.push({
      pathname: path + data['slug'],
      state: community
		})
	}
	
const MemberContent = ({ e, onClick }) => {
  const handleViewCommunity = () => {
    onClick(e, "view");
  };

  return (
    <Box padding={2}>
      <Box
        marginTop={4}
        onClick={handleViewCommunity}
        color="#25387c"
        fontWeight={700}
        fontSize="16px"
		 className={classes.name}
      >
        {e.title}
      </Box>

      <Typography variant="subtitle2" color="textSecondary" className={classes.about}>
        {e.description}
      </Typography>

      <Box marginTop="-2px" style={{ cursor: "pointer" }}>
        <Link color="primary" onClick={handleViewCommunity}>
          <Typography variant="caption">Know More</Typography>
        </Link>
      </Box>
    </Box>
  );
};

const MemberCard = ({ e, index, onClick }) => {
  const isProfessor = e.creater_id === user_id && user_status_id === 'professor';

  const handleManageCommunity = () => {
    onClick(e, "manage");
  };

  return (
    <Box position="relative" className={classes.hovercard}>
      <Box
        display="flex"
        padding="0px 24px"
        marginBottom="-80px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Avatar src={community_path + e.banner} alt={e.banner} className={classes.small} />
		 { isProfessor && (
			<Box display="flex" justifyContent="end" >
              <Tooltip  placement="right-start"  title="Manage Community">
                <IconButton color="primary" 
				onClick={()=>{findCommunity(e,"manage")}}
				size="small">
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
          )}

      </Box>

      <Card className={classes.mentorcard}>
        <CardContent style={{ padding: "0px" }}>
          <MemberContent e={e} onClick={onClick} />
        </CardContent>
      </Card>
    </Box>
  );
};


  const handleClick = (community, action) => {
    if (action === "view") {
      findCommunity(community, "view");
    } else if (action === "manage") {
      findCommunity(community, "manage");
    }
  };

  return <MemberCard {...props} onClick={handleClick} />;


}
export default CommunityRenderCard;