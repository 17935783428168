import {
  Box, Button,
  Card, CardContent, CardHeader,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";
import { translateText } from "../../functions/Helper";








const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
     search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
	     backgroundColor: 'transparent',

    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	color:'primary'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize:'14px',
	padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },

}));

class Logs extends Component {

    constructor(props) {
	super(props);
	this.translateText = translateText.bind(this);
	this.state={
		    msg:false,msg_type:false,
            user_show:[],user_count:0,start:0,end:10,
			hasMore: true,record_len:0,user_list:[],data_id:null,
			search_for:'All',search:null,data:'Admin'}
    }
	handleSubmit=()=>
    {
         if(this.state.data_id==null)
         {
             this.setState({msg:'Please choose the user.'})
             this.setState({msg_type:'warning'})
         }
         else
         {
           if(this.state.data_id=='Admin')
           {
               this.getAdminList();
               this.state.data="Admin"
           } 
           else
           {
               this.getModerators();
               this.state.data="Moderators"

           }
         }
         setTimeout(() => {this.setState({msg:false})}, 2000);

    }
   
	
	getAdminList=()=>
    {
			axios.post('/api/getAdminList')
            .then((response) => {
			//console.log(response.data)
			this.setState({user_list:response.data})
            //alert(this.state.user_list[0]['title'])
			}); 
    }
   
 	getModerators=()=>
    {
			axios.post('/api/getRegisteredUser', {
				'start':0,
				'end':50,
				'search_for':'Moderators'
			})
            .then((response) => {
			//console.log(response.data)
			this.setState({user_list:response.data})
			}); 
    }
  
	componentDidMount()
	{
		this.getAdminList()
	}
  	render()
  	{
	
		const classes = this.props.classes;
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.user_list.map((e, index) => (
		<StyledTableRow>
			
			  <StyledTableCell  >{e['email']}</StyledTableCell>
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				{this.state.data+" ("+this.state.user_list.length+")"}
				</Typography>
				</Box>
				
				
				<Box display="flex" >
                <Box marginRight={1} minWidth="200px">
                <FormControl variant="outlined" fullWidth>
					<InputLabel>Users</InputLabel>
					<Select name="data_id"
					onChange={event=>this.setState({data_id:event.target.value})}
					label="User"
					value={this.state.data_id}
                    >
					<MenuItem value="Admin">Admin</MenuItem>
					<MenuItem value="Moderators">Moderators</MenuItem>
					</Select>
				</FormControl>
				 </Box>
				
                <Box>
                <Button  
				variant="contained" 
				color="primary" 
                size="large"
				onClick={this.handleSubmit}>
				Get users status
				</Button>
				</Box>
				</Box>
 
				
		   </Box>			
			}/>
        
		<CardContent>
		
        <TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Email</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
            </Table>
		  </TableContainer>	
				
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}