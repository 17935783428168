import React, { useState,useEffect } from 'react';
import {
  Grid,
  Box,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfigForm from '../ConfigForm';
import CaptchaForm from '../CaptchaForm';
import OtpForm from '../OtpForm';
import {projects} from '../../../functions/Helper';
const useStyles = makeStyles((theme) => ({
  cardHeight: {
    minHeight: '62vh',
  },
}));

const ManageSignIn = () => {
  const classes = useStyles();


  const actions = [
    { key: 'signup-mentor', title: 'Mentor - Basic Configuration' },
    { key: 'signup-learner', title: 'Learner - Basic Configuration' },
    { key: 'profile', title: 'Profile Page' },
    { key: 'update-profile', title: 'Update Profile Page' },
	];

  const [selectedModule, setSelectedModule] = useState('signup');
  const [selectedProject, setSelectedProject] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('Manage SignUp');
  const [selectedAction, setSelectedAction] = useState('');
  const [centerContent, setCenterContent] = useState('');
  const [data, setData] = useState([]);
  const [updateFlag, setUpdateFlag] = useState(false);

  useEffect(() => {
    setUpdateFlag(prevFlag => !prevFlag);
	console.clear();
  }, [data]);
  
  const handleCallback = (ev) => {
    setData(ev);
	
  };

  const handleProjectChange = (event) => {
    const { value } = event.target;
    setSelectedProject(value);
  };

  const handleActionChange = (event) => {
    const { value } = event.target;
    const selectedAction = actions.find((action) => action.key === value);
    setSelectedAction(value);
    setSelectedTitle(selectedAction.title);
  };

  const handleActionButtonClick = () => {
    if (selectedProject && selectedAction) {
      const newCenterContent = `Center content for ${selectedProject} - ${selectedAction}`;
      setCenterContent(newCenterContent);
    }
  };

  return (
    <Grid container spacing={2} justifyContent="center">

      <Grid item xs={12} lg={4}>
        <Card>
          <CardHeader title="Manage SignUp" />
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={12}>
			  <FormControl variant="outlined" fullWidth >
			   <InputLabel>Select Project</InputLabel>
                  <Select label="Select Project" value={selectedProject} onChange={handleProjectChange}>
                    <MenuItem value="">Select Project</MenuItem>
                    {projects.map((project) => (
                      <MenuItem key={project.key} value={project.key}>
                        {project.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Select Action</InputLabel>
                  <Select  label="Select Action"  value={selectedAction} onChange={handleActionChange}>
                    <MenuItem value="">Select Action</MenuItem>
                    {actions.map((action) => (
                      <MenuItem key={action.key} value={action.key}>
                        {action.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} >
                <Button fullWidth variant="contained" color="primary" onClick={handleActionButtonClick}>
                  Get config structure
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
	  
	  <Grid item xs={12} lg={8}>
      <Card className={classes.cardHeight}>
	  <CardHeader title={selectedTitle} />
	  <CardContent>
		{
			  selectedAction && selectedProject ? (
				  <ConfigForm 
				  project={selectedProject}
				  module={selectedModule}
				  component={selectedAction} 
				  parentCallback={handleCallback} 
				  />
			  ) :  (
				  <Box display="flex">
					<Typography variant="subtitle1" color="textSecondary">
					  Please select an action to proceed.
					</Typography>
				  </Box>
				)
		}
		</CardContent>
	  </Card>
	  
	  
	  {selectedAction && selectedProject &&
	  (selectedAction === 'signup-mentor' || selectedAction === 'signup-learner' )	&&  (
	  <Box marginBottom={2} marginTop={2}>
      <Card>
	  <CardHeader title={"Enable OTP"} />
	  <CardContent>
	   <OtpForm data={data} />
	  </CardContent>
	  </Card>
	  </Box>
	  )}
	  
	  
	  {selectedAction && selectedProject &&
	  (selectedAction === 'signup-mentor' || selectedAction === 'signup-learner' )	&&  (
	  <Box marginBottom={2} marginTop={2}>
      <Card>
	  <CardHeader title={"Enable Captcha"} />
	  <CardContent>
	   <CaptchaForm data={data} />
	   </CardContent>
	  </Card>
	  </Box>
	  )}
	  
	  
      </Grid>
	  
	  
    </Grid>
  );
};

export default ManageSignIn;
