import { TextField, Avatar, Badge, Box, Button, Card, CardContent, CardHeader,  
Grid, IconButton,    Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";
import { api, translateText,folder_list,is_local } from "../../functions/Helper";









const useStyles = makeStyles((theme) => ({
  
  avatarInner: {
 
   height: '130px !important',
   width: '130px !important',
      border:'3px solid #eee !important',
  
  },
  
}));

class Logs extends Component {

    constructor(props) {
	super(props);
	    this.translateText = translateText.bind(this);
	this.state={
		      msg:false,
			  msg_type:false,img:null,status:'success',
			  start_with:is_local?'../public/':'../',
              files:[]}
    }
	

	delImages = (path,img) => 
    {
    
		axios.post(api+'/delImages',{'path':path,'img':img})
		.then((response) => {
        this.getImages(path);
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        });
        setTimeout(() => {this.setState({msg:false})}, 2000);

	}	
   
	
    getImages=(img)=>
    {
			axios.post('/api/getImages',
            {'path':img})
            .then((response) => {
			//console.log(response.data)
			this.setState({files:response.data})
            if(response.data.length>0)
            {
                this.setState({msg:'Below are the search result!'})
            } 
            else
            {
                this.setState({msg:'No result found!'})
            }    
			});
            setTimeout(() => {this.setState({msg:false})}, 2000);

    }
    handleSubmit=()=>
    {
         if(this.state.img==null)
         {
             this.setState({msg:'Please choose the file path.'})
             this.setState({msg_type:'warning'})
         }
         else
         {
			 //console.log(this.state.img)
			 //../assets/images/assignment/authors
           this.getImages(this.state.img)  
         }
         setTimeout(() => {this.setState({msg:false})}, 2000);

    }
   
	
	componentDidMount()
	{
		//console.log(folder_list)
	}
  	render()
  	{

		const classes = this.props.classes;
		const Images = () => {
		return (
		<Grid container 
		direction="row"
			  alignItems="center"
			  justify="center" spacing={0}>		
		{
            
         this.state.files.map((e, index) => (
		
           <Grid item xs={10} md={2} >
            
            <Box display="block" flexDirection="column" margin={2}>

            <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              badgeContent={                
                 <IconButton  onClick={()=>{this.delImages(this.state.img,e['file'])}}>
                            <CloseIcon color="grey"  fontSize="small" />
                 </IconButton>
              }
            >
              <Avatar  src={e['img_path']+e['file']}  className={classes.avatarInner} />
            </Badge>
            </Box>
            
            <Box>
            <Typography variant="subtitle1" color="textSecondary">{e['file']}</Typography>
            </Box>
            
            </Box>
            
            </Grid>
            
            
		))}
		</Grid>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
		
				<Grid container direction="column"  
				>      

				<Grid item xs={12} sm={12}>
				 <Box  marginBottom={2}>
                <Typography variant="h6" >
				Get Images
				</Typography>
				</Box>
				</Grid>
				
				<Grid item sm={8}>				 
				 <Box >
				   <TextField fullWidth label="Folder path" color="primary"
				   variant="outlined"  name="name" type="text" 
					onChange={event=>this.setState({img:event.target.value})}
					value={this.state.img} fullWidth/>
				 </Box>
				 <Box marginTop={0.5} marginBottom={2}>
				   <Typography variant="subtitle2" color="textSecondary">
					eg. {this.state.start_with}assets/images/
					</Typography>
				 </Box>
				</Grid>
				
				<Grid item xs={12} sm={3}>
				 <Box >
                <Button  
				fullWidth
				variant="contained" 
				color="primary" 
                size="large"
				onClick={this.handleSubmit}>
				Get users
				</Button>
				</Box>
				</Grid>
				</Grid>
				
               
			}/>
        
	  
		<CardContent>
		
		  <Images/>	
			
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}


export default () => {
    const classes = useStyles();
   
    return (
        <Logs classes={classes} />
    )
}

  