import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import Autolink from 'react-native-autolink';
import {
  Avatar, Box, CircularProgress, Grid, List, ListItem, ListItemAvatar, Divider,
  ListItemText, TextField, Typography, Button, IconButton ,   Menu, MenuItem, makeStyles
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import VisibilityIcon from '@material-ui/icons/Visibility'; // Icon for View
import AddIcon from '@material-ui/icons/Add'; // Icon for Add
import ReplyIcon from '@material-ui/icons/Reply'; // Icon for Add
import { useHistory,useLocation } from 'react-router-dom';

import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';

import { Icon } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People'; // Import the appropriate icon

import { api, userProfile_path,stringToColor } from '../../Helper';
import { chatStyle } from '../../chat/styles/chatStyle';
import AlertBox from "../../AlertBox";
import ReplyCard from './ReplyCard';
import clsx from 'clsx';

var senderEmail='';
var senderImg='';
var senderName='';
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	senderEmail=JSON.parse(localStorage.getItem('ac_login_user'))['email'];
	senderName=JSON.parse(localStorage.getItem('ac_login_user'))['fname'];
	senderImg=JSON.parse(localStorage.getItem('ac_login_user'))['img']
}

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
  },
  textfield:{borderRadius:"12px"},
  senderList:{paddingLeft:"16px",flexDirection:"row-reverse"},
  icon:{fontSize:"0.85rem",cursor:'pointer'},
  iconbtn:{backgroundColor:'rgb(187 174 174 / 12%)',padding:'8px',marginBottom:'8px'},
  reply: {
    fontSize: '12px',
    color: '#25387c',
	cursor:'pointer'
  },
  emptyAvatar: {
    height: '35px',
    width: '35px',
    backgroundColor: 'orange', 
  },
  senderBox:{
    float: 'left',
    marginLeft: 'auto',
    marginRight: '16px',
  },
  modelicon:{
	   padding:'0px 16px 0px 0px'
   },
  msg:{
	   lineHeight:'1rem',
	   color:'black'
   },
   scrollroot:{overflow: "auto"}

}));
export default function ChatApp({keyProp,parentCallback,chat_id,slug}) {
  const classes = chatStyle();
  const custom_classes = useStyles();

  const [, forceUpdate] = useState(0);
  const location = useLocation();

  const [hasMore, setHasMore] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [len, setLen] = useState(0);
  const [start, setStart] = useState(0);

  const [msg, setMsg] = useState(false);
  const [msg_type, setMsgType] = useState("success"); 
  
  const [active_msg_id, setActiveMessageID] = useState(null);
  const [reply_box, setReplyView] = useState(false);  
  const [replyText, setReplyText] = useState(""); 
  const [reply_action, setReplyAction] = useState(null); 
  const [anchorEl, setAnchorEl] = useState(null);

  const [showFetchPrevButton, setShowFetchPrevButton] = useState(false);

  const end = 8;
  const chats = useRef([]);
  const messagesEndRef = useRef(null);
  const listInnerRef = useRef(null);


	const handleMenuOpen = (event, chat) => {
		setAnchorEl(event.currentTarget);
		handleReplyView(false,chat.msg_id,null);
	  };

	  const handleMenuClose = () => {
		setReplyText("");
		setAnchorEl(null);
		//handleReplyView(false,null,null);
	  };

	  const isMenuOpen = Boolean(anchorEl);
	  const menuId = 'message-menu';


    const handleReply = () => {
			let sender=JSON.parse(localStorage.getItem('ac_login_user'))['email']
			let param={
			'chat':replyText,
			'chat_id':chat_id,
			'sender':sender,
			'receiver':chat_id,
			'parent_id':active_msg_id,
		  }
		  if(slug!=undefined)
		  {
			  param['community_id']=slug
		  }
		  		console.log(param)

		  let headers={
					'Accept': 'application/json',
					'Content-Type': 'application/json'}
			axios({
			method: 'post',
			url: '/api/ac_sendmsg',
			data:param,
			headers: headers
		})
		.then((response) => {
			setMsg((response.data).msg);
			setMsgType((response.data).type);
			setBtnLoading(false);
			setReplyText(null);
			//handleReplyView(false,null,null);
			handleReplyView(true, active_msg_id, 'view')
			parentCallback();
		});
    }
   
    const handleReplyTextChange = (event) => {
    setReplyText(event.target.value);
    };

	const handleEnterKeyPress = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
		  event.preventDefault();
		  handleReply();
		}
	};
    const handleReplyView = (view,msg_id,action) => {
		setReplyAction(action);
		setReplyView(view);
		setActiveMessageID(msg_id)
		if(view!=false)
		{
		  handleMenuClose();
		}
    };
    const fetch_reply = () => {
	  
    };
  
	  const showd = (t, len) => {

	  if (len === undefined) {
		len = dataList.length;
	  }
	  	
	  let param = { chat_id: chat_id, length: len, end: end };
		if(location.state)
		{
			param['community_id']=location.state.id
		}
	  if (len > 0) {
		const lastObject = dataList[dataList.length - 1];
		const lastMsgId = lastObject.id;
		param['last_msg_id'] = lastMsgId;
	  }

	  axios.post(api + "/ac_getMsg", param)
		.then((response) => {
		  console.log(response.data);
		  let res=response.data
		  if(res.length==0 || (res.length > 0 && res[0]?.status === "No result found"))
		  {
			  res=[]
		  }
		 
		 // alert(3)
			  const newData = res.reverse();
			  
			  if (t === 0) {
				setDataList(newData);
				console.log(newData)
			//	alert(1)
				setHasMore(true);
			  } else {
				  
				if(newData.length>0)
				{
					const updatedDataList = [...dataList, ...newData];
					console.log(updatedDataList)
					//alert(2)
					setDataList(updatedDataList);
				}
			  }

			  chats.current = newData;
			  setLen(newData.length);
			  forceUpdate(n => !n);
			  console.log(dataList);
			  scrollToBottom();
		  
		
		});
	};



	const fetchPrevMsg = () => {
	  setBtnLoading(true);
	  const newStart = start + end;
	  const payload = { chat_id: chat_id, length: dataList.length, end: end };

	  axios.post(api + "/ac_getMsg", payload)
		.then((response) => {
		  if (response.data.length === 1 && response.data[0].status === "No result found") {
			return;
		  }

		  const fetchedData = response.data.reverse();
		  setDataList([]);
		  const updatedDataList = [...dataList, ...fetchedData];
          updatedDataList.sort((a, b) => a.msg_id - b.msg_id);

		  setDataList(updatedDataList);
		  chats.current = updatedDataList;
		  setLen(updatedDataList.length);
		  setHasMore(fetchedData.length >= end);
		  setBtnLoading(false);
		})
		.catch(error => {
		  console.error("Error fetching messages:", error);
		  setBtnLoading(false);
		});
	};



	const handleScroll = (event) => {
		  event.preventDefault();

		console.log("coming inside")
	 		console.log(listInnerRef.current)
		if (listInnerRef.current) {
		console.log("test current")
		const { scrollTop } = listInnerRef.current;
		console.log(listInnerRef.current)
		if (scrollTop === 0) {
		  console.log("reached top")
		  setShowFetchPrevButton(true);
		} else {
		  console.log("didnt reached the top")
		  setShowFetchPrevButton(false);
		}
	  }
	};

	/* useEffect(() => {
		setStart(0);
		showd(0);
	}, []);
	*/

	useEffect(() => {
	  console.log(keyProp);
	  let d = [...dataList];
	  d.push(keyProp);
	  setDataList(d);
	  scrollToBottom()
	}, [keyProp]);

	
	 useEffect(() => {
		showd(0);
		const interval = setInterval(() => {
			showd(start);
		}, 2 * 60 * 1000); 

	  return () => {
		clearInterval(interval);
	  };
	}, []);


  const handleDBParent = () => {
	setHasMore(true)
	setStart(0)
	showd(0,0)
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };


	
	const FetchPrevMsgButton = () => {
	  return (
		<Box display="flex" justifyContent="" width="100%" marginTop="">
		{
			  hasMore  && (<Box 
					  fontSize="14px" 
					  fontWeight={700} 
					  color="#253B7C" 
					  style={{cursor:'pointer'}}
					  disable={btnLoading}
					  onClick={fetchPrevMsg}>
					  {btnLoading?<CircularProgress />:"Fetch Previous Messages"}
					 </Box>)
		}
		{
			  !hasMore  && (
				  <Box marginBottom={2}>
				  <Typography 
					  variant="subtitle2" 
					  color="textSecondary"> 
					  There are no new messages discovered!
					 </Typography>
					 </Box>)
		}
		</Box>
	  );
	};
      
        const Message = ({text}) =>
        {return (
           <Autolink
            className={classes.msg}
            text={text}
            email
            hashtag="instagram"
            mention="twitter"
            phone="sms"
            url
          />
        )}  

	

	const MenuCard = () => {
	  return (
		<Menu
		  anchorEl={anchorEl}
		  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
		  id={menuId}
		  keepMounted
		  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
		  open={isMenuOpen}
		  onClose={handleMenuClose}
		>
		  {/* View Reply MenuItem */}
		  <MenuItem onClick={() => handleReplyView(true, active_msg_id, 'view')}>
			<IconButton color="primary" className={custom_classes.modelicon}>
			  <VisibilityIcon />
			</IconButton>
			<Typography variant="subtitle2" color="textSecondary">View Reply</Typography>
		  </MenuItem>

		  {/* Add Reply MenuItem */}
		  <MenuItem onClick={() => handleReplyView(true, active_msg_id, 'add')}>
			<IconButton color="primary"   className={custom_classes.modelicon}>
			  <ReplyIcon />
			</IconButton>
			<Typography variant="subtitle2" color="textSecondary">Add Reply</Typography>
		  </MenuItem>
		</Menu>
	  );
	}
        
  return (
  <Box>
	{dataList.length === 0  ? (
	  <Box margin={2} height="335px">
		<Typography variant="subtitle2" color="textSecondary">
		  There are no new messages discovered!
		</Typography>
	  </Box>
	) : null}

 
  <MenuCard />
    <Grid container>
      {msg !== false && (
        <AlertBox 
		show_status={true} 
		msg={msg} 
		msg_type={msg_type}
		parentAlertCallback={()=>{setMsg(false)}}
		/>
      )}

      <Grid
        item
        xs={12}
      >
        {/* Display loading spinner */}
        {btnLoading && dataList.length === 0 && (
          <Box
            marginTop={2}
            marginBottom={2}
            sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
          >
            <CircularProgress color="primary" size={24} />
          </Box>
        )}

        {/* Display messages */}

		{dataList.length > 0 && (
          <React.Fragment>
 		<Box height="32px">
           {dataList.length > (end-1) && <FetchPrevMsgButton />}
          </Box>
          <Box 

		 >
		  <List
		 className={classes.scrollroot}
        id="scrollDivMessage"
        onScroll={handleScroll}
        ref={listInnerRef}
		style={{height:'356px'}}
		>   
              { 
			  dataList.map((chat, index) =>
                chat != undefined ? (
                  <ListItem
                    key={chat.id}
                    alignItems="flex-start"
                    style={
                      chat.sender !== senderEmail
                        ? { padding: '0px' }
                        : { padding: '0px', flexDirection: 'row-reverse' }
                    }
                  >
                    <ListItemAvatar className={classes.avatar}>
                      <Box marginLeft={chat.sender !== senderEmail?"8px":""}>
                      <Avatar
                        alt={chat.name}
                        className={chat.img === '' ? classes.emptyAvatar : ''}
                        src={userProfile_path + chat.img}
                      />
                      </Box>
                    </ListItemAvatar>
                    
					<ListItemText
					  primary={
						<Box
						  width="50%"
						  marginLeft="auto"
						  marginRight="16px"
						  paddingBottom="16px"
						  className={
							chat['sender'] !== senderEmail ? custom_classes.senderBox : ''
						  }
						>
						  <Box
							minWidth="70%"
							width="fit-content"
							marginLeft={
							  chat['sender'] !== senderEmail ? '16px' : 'auto'
							}
						  >
							<Box
							  padding={1}
							  className={clsx(
								chat['sender'] !== senderEmail
								  ? clsx(classes.reply, custom_classes.box)
								  : clsx(
									  classes.question,
									  custom_classes.box,
									  len - index <= 100 ? classes.unread : classes.read
									)
							  )}
							>
							  <Typography color="textPrimary" variant="caption">
								<Box display="flex">
								  <Box
									lineHeight={1.1}
									flexGrow={1}
									className={clsx(classes.title)}
									fontWeight="bold"
									color={stringToColor(chat.name)}
								  >
									 {chat.name}
								  </Box>
								  { chat.msg_id!=null && ( <Box marginLeft={1} lineHeight={1.1} color="text.secondary">
									  <KeyboardArrowDownIcon
									  color="primary" 
									  className={clsx(custom_classes.icon)}
									  onClick={(e) => handleMenuOpen(e, chat)}
									  />
								 </Box>)}
								</Box>
								<Box
								  marginTop={0.5}
								  lineHeight={1.6}
								  fontSize="caption.fontSize"
								  color="text.secondary"
								>
								  <Message text={chat.chat} />
								  <Box marginTop={0.5} display="flex" justifyContent="end" fontSize="11px" color="text.secondary" >
								  {chat.added_on}
								  </Box>
								</Box>
							  </Typography>
							</Box>

							{chat.replied_users > 0 && chat.msg_id != active_msg_id  && 
							(
							  <Box>
								<Typography variant="subtitle2" color="textSecondary">
								  {chat.replied_users > 9 ? "+9 users" : chat.replied_users > 1 ? chat.replied_users+" users" : "1 user"} replied to this message.
								</Typography>
							  </Box>
							)}

							  
							{reply_box && chat.msg_id === active_msg_id && (
							  <Box>
								{reply_action === 'view' && (
								  <ReplyCard
									msg_id={chat.msg_id}
									chat_id={chat.chat_id}
									handleReplyCallback={() => handleReplyView(false, null, null)}
								  />
								)}
								{reply_action === 'add' && (
								  <Box display="flex" marginTop={2} marginLeft={4} width="100%">
									<TextField
									  fullWidth
									  multiline
									  rows={2}
									  variant="outlined"
									  placeholder="Add a reply"
									  value={replyText}
									  onChange={handleReplyTextChange}
									  onKeyDown={handleEnterKeyPress}
									  InputProps={{
										className: custom_classes.textfield,
									  }}
									/>
									<Box paddingLeft={2}>
									  <IconButton
										onClick={() => handleReplyView(false, null, null)}
										className={custom_classes.iconbtn}
									  >
										<CloseIcon color="grey" className={custom_classes.icon} />
									  </IconButton>
									</Box>
								  </Box>
								)}
							  </Box>
							)}
						  </Box>
						</Box>
					  }
					/>

                  </ListItem>
                ) : ''
              )}

              <ListItem>
                <ListItemText
                  primary={<div style={{ float: 'right', clear: 'both' }} ref={messagesEndRef}></div>}
                />
              </ListItem>
            </List>
            </Box>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
    </Box>
  );
}