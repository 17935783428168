import { Box, Button, Card,CardContent,Container, Grid, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {speakers_path} from '../../Helper';
import prev from './images/b.png';
import './css/grid.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 40
  }
};


const styles = theme => ({
  card:{
	  borderRadius:'25px',
	  background:'url(' + prev + ')',
		'background-repeat':'no-repeat',
		'background-size':'100%',
		'background-position':'bottom',
	backgroundColor:'white'},
	color:{color:"white"},
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

let subtitle='Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.';
let path="/assets/images/homepage/images/offer/"

let data=[
		{'img':speakers_path+'niraj.png',
		'title':"E-Book Name 1",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_brittany.png',
		'title':"E-Book Name 4",
		'subtitle':subtitle,
		'href':""},
			{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
			{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		]
	

function Footer(props) {
  const { classes } = props;
    const history = useHistory();

	const ProfileCard=({img,title,subtitle,href})=>{
		return (
		<Box margin={1} marginBottom={6}>
				<Card elevation={3} className={classes.card}>
				<CardContent>
				<Box 
					display="block" 
					justifyContent="center"
					alignItems="center"
					textAlign="center">
					
					<Box>
					<Typography  variant="h6" color="textPrimary" > 
					 <Box fontWeight="fontWeightBold" lineHeight={1.5} >
					 {title}
					 </Box>
					 </Typography> 
					 </Box>
					
					<Box marginBottom={3}>
					 <Typography  variant="subtitle1" color="textSecondary"  style={{minHeight:'0px'}}> 
					 <Box marginTop={1} lineHeight={1.5} >
					 {subtitle}
					 </Box>
					 </Typography> 
					 </Box>
					
					<Box>
					<Button variant="contained" 
					size="medium" 
					color="primary" >
					Learn More
					</Button>
					</Box>
					
			</Box>	
			</CardContent>	
			</Card>	
			</Box>	
		)
	}
  return (
  <>
  <Box marginTop={4} marginBottom={1} className={"grid"}
  style={{backgroundColor:"#25387C"}}>
	<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={0} 
					>
		
	  <Grid item  xs={12} sm={10} >
	  <Typography >
					  <Box lineHeight={1.25} 
					  fontWeight="600"
					  fontSize="32px" marginBottom={3}
					  marginTop={3} className={classes.color}
					  textAlign="center" padding={2}>	
							Previous Webinars
						<Box className={classes.outline}></Box>

					  </Box>
			</Typography>
	  </Grid>
	  
	  <Grid item  xs={12} sm={10} >
	  <Container>
		<Carousel responsive={responsive}  >
			{
							
						data.map((e, index) => (
							
						<ProfileCard  
						img={e['img']}
						title={e['title']}
						subtitle={e['subtitle']}
						href={e['href']}/>
						
					))								
			}
			</Carousel>
			</Container>
	   </Grid>
	   
	      	<Grid item xs={12}>
			<Box
			  display="flex"
			  justifyContent="center"
			  alignItems="center"
			  marginTop={0} 
			  marginBottom={4} 
			>
			<Button variant="contained" size="large"   color="secondary" >
			View All
			</Button>
			</Box>
			</Grid>
	   
	   </Grid>
    </Box>
		
	</>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);