import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import CountdownTimer from "react-component-countdown-timer";
import './timer.css';
import { isMobile } from 'react-device-detect';


const useStyles = makeStyles((theme) => ({

  timer:{fontFamily:'Public Sans,sans-serif !important'},
  success:{
      'box-shadow': 'rgb(229 249 229) 0px 8px 16px 0px',
      color:'#fff',backgroundColor:'#4caf50',
      "&:hover":{backgroundColor:'#2e7d32'}
   },
   card:{borderRadius:"90px",width:"fit-content",padding:"0px 16px"}

}));
let msg='Webinar will start soon. Please stand by!';
let arr=[];
let t=0;

class AccordionBlock extends Component {
	
	
	constructor(props) {
    super(props);
	this.state={seconds:0,show:true}
	}

	
	UNSAFE_componentWillMount() {	
	
		var date1, date2,date3;
         date1 = new Date();
         date2 = new Date( "Mar 8, 2022 00:00:00" );
         var seconds = Math.floor(Math.abs(date1 - date2) / 1000);
		this.setState({seconds:seconds},()=>{console.log(this.state.seconds)})
		
		if(date1>date2 || isMobile==true)
		{
			this.setState({show:false},
			()=>{console.log(this.state.show)})
		}
		
    }
	
	render(){
	const classes = this.props.classes;

	var settings = {
      count: parseInt(this.state.seconds),
      border: true,
      showTitle: true,
      noPoints: true,
	   direction:"left",
	  size:17, 
	  labelSize:10,
	  color:"rgb(99, 115, 129)",

    };
	

    return(
      		<>
			{
				this.state.show==true
				?
				<Card elevation={3} className={classes.card} >
                    <CountdownTimer {...settings} 
                    className={classes.timer}
                     />
                  	
                </Card>
			
				:''
			}
			</>
						
	);}
}
export default () => {
    const classes = useStyles();
    return (
        <AccordionBlock classes={classes} />
    )
}

  
  
  
  
  