
import { Box, Button, Card, CardHeader, CircularProgress, FormControl,
InputLabel,Grid,MenuItem,TextField,Select } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";





const useStyles = theme => ({

  media: {
	'border-radius':'12px'
  },
 
 
});
 

class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
        
		loading:false,
		action:null,
		query:null,
		msg:false,
		msg_type:"success"
		
		}
    }
	
	customUpdate=(val,res)=>{
		this.setState({[val]:res},()=>{console.log(this.state[val])})
	}
	
	handleSubmit = (e) =>
	{
		e.preventDefault();
  
		if(this.state.query==null || this.state.action==null )
		{
				this.customUpdate("msg","Please specify all the fields.");
		}
		else
		{
			axios.post('/api/execute_query',
			{
				'action': this.state.action,
				'query': this.state.query
			})
			.then((response) => 
			{
				
				let data=response.data;
				console.log(data);
				
				this.customUpdate("msg",data['msg']);

				setTimeout(() => {
						this.customUpdate("msg",false);
				}, 5000);
						
			})
		}
	}

	
  	render()
  	{
		

	    

		return(
		 <Box marginTop={3}>
		 <Card>
		<CardHeader title={"Manage Query"} />
					
        <Grid container spacing={4}
         container direction="column"
                    alignItems="center"
                    justify="center"  >
        { 	
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type="success" />
					: ''	
		}	 
	
	    <Grid item xs={8} sm={6}>
				
                <Box minWidth="100vh"  marginTop={3}>
                <FormControl variant="outlined" fullWidth>
					<InputLabel>Choose action</InputLabel>
					<Select name="action"
					onChange={event=>this.setState({action:event.target.value})}
					label="Choose action"
					value={this.state.action}
                    >
					<MenuItem value={'select'}>{"select"}</MenuItem>
					<MenuItem value={'alter'}>{"alter"}</MenuItem>
					<MenuItem value={'create'}>{"create"}</MenuItem>
					<MenuItem value={'update'}>{"update"}</MenuItem>
					<MenuItem value={'insert'}>{"insert"}</MenuItem>
					<MenuItem value={'delete'}>{"delete"}</MenuItem>
					</Select>
				</FormControl>
				 </Box>
                 
			</Grid>
            
	    <Grid item xs={8} sm={6}>
				
                <Box minWidth="100vh">
                <TextField
				rows={10}
				multiline
				label="Write Query" color="primary" variant="outlined"  name="query" type="text" 
			    onChange={event=>this.setState({query:event.target.value})}
                value={this.state.query} fullWidth/>
				 </Box>
                 
			</Grid>
            
        
		
		
		
		
		
		
		
        
        
	    <Grid item xs={8} sm={6}>
								<Box display="flex" flexDirection="row-reverse"   >
								
								<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} 
								
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>Execute query</span>}
								</Button>
								
								</Box>
	    </Grid>
								
		  
		</Grid>
        </Card>
        </Box>

            
            
	    );
	
  	}
}
export default withStyles(useStyles)(CourseBlock)
