import React, { useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {IconButton, Tooltip  , Grid , Typography , Box , ButtonBase , CircularProgress} from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import QuizrWelCard from "../../quiz/QuizrWelCard";
import AlertBox from "../../AlertBox";
import axios from 'axios';
import {api,quiz_path,secondaryColor,images} from "../../Helper";
import {getCacheDetails } from "../../caching";
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';


import {FaChevronLeft} from "react-icons/fa";
let student_id=null
let status=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    //margin: 'auto',
   // maxWidth: 500,
  },
  defimage: {
    width: 62,
    height: 62,
	position:'relative',
	'border-radius': '12px',
	'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
  },
  image: {
	'object-fit':'cover',
    width: 62,
    height: 62,
	position:'relative',
	'border-radius': '12px',
     backgroundColor:'rgb(0 0 0 / 75%)',
  },
  img: {
	'object-fit':'cover',
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
	width: 'inherit',opacity:'0.5',
    height: 'inherit',borderRadius:'12px', 
  },
  details: {   overflow: "hidden",display: "-webkit-box",
	WebkitLineClamp: 1,WebkitBoxOrient: "vertical" },
		avatar2:{
		cursor:'pointer',
			backgroundColor:secondaryColor,
			color:"white",
		'&:hover':{
			backgroundColor:"#f9bb021f",
		color:secondaryColor,			
		},
	}
}));
let newquizQuestions=[];
let correctAns=[];
export default function ChaptersScreen(props) {
  const classes = useStyles();
  const [, forceUpdate] = React.useState(null);
  const [msg, setErr] = React.useState(false);
  const [quiz_data, setQuiz_data] = React.useState(false);
  const [loading, setLoader] = React.useState(true);
  const [quiz_list, setQuiz] = React.useState(window.location.pathname=="/module-assigments"?[]:props.quiz_list);
	const location = useLocation();
	const chapter_data = props.chapter_data;
	
	const verify_unit_completion=(e)=>
	{
		let chapter_id=e["chapter_id"];
		let question_count=e["question_count"];
		
		let payload={
		'chapter_id':chapter_id,
		'course_id':location.state['course_searched'],
		'student_id':student_id,
		'test':'data'}
		axios.post(api+'/get_if_marked_completed',payload)
		.then((response) => {	
                
				//alert("bh")
				//console.log(payload)
				//console.log(response.data)
				if(parseInt(response.data['is_completed'])==100)
				{
					if(question_count>0)
					{
						getQuiz(e)	
					}
					else
					{
						setErr("MCQ's are not defined.")
						this.forceUpdate();
						setTimeout(() => {setErr(false)}, 2000);						
					}
				}
				else
				{
					setErr("You must complete modules unit to access quizzes.")
					forceUpdate(n=>!n);
					setTimeout(() => {setErr(false)}, 2000);			
				}
		})	
	}
	const manage_quiz_flow=(e)=>
	{
		let question_count=e["question_count"];
		let quiz_creater_id=e["quiz_creater_id"];
		
		if(status=='student')
		{
			verify_unit_completion(e)
		}
		else
		{
			if(status=='professor' && quiz_creater_id==student_id)
			{
				if(question_count>0)
				{
					getQuiz(e)	
				}
				else
				{
					setErr("MCQ's are not defined.")
					setTimeout(() => {setErr(false)}, 2000);						
				}
			}
			else
			{
				setErr("You must complete module unit to access quizzes.")
				setTimeout(() => {setErr(false)}, 2000);
			}
		}
		
	}
	const getQuiz=(e)=>
	{
		
		let quiz_id=e["quiz_id"];

		
		if(status=='student' || status=='professor')
		{
				axios.post(api+'/addUpDeQuiz',{
				'quiz_id': quiz_id,
				'search_by':"search",
				'operation':'quiz_id',
				'start':0,'end':20})
				.then((response) => {
				//console.log(response.data.length);
				//console.log(response.data);
				
				let i=0
				
				if(newquizQuestions.length==response.data.length)
				{
							go_to_quiz(e)					
				}
				else
				{
						for (i=0;i<response.data.length;i++)
							{
								let k={'question':response.data[i]['question'],
										'answers': [
										{type: "option1",content: response.data[i]['option1']},
										{type: "option2",content: response.data[i]['option2']},
										{type: "option3",content: response.data[i]['option3']},
										{type: "option4",content: response.data[i]['option4']},
										{type: "option5",content: response.data[i]['option5']}]}
							
										newquizQuestions.push(k);
										correctAns.push(response.data[i]['answer']);
										//console.log(correctAns);
							}
							
							go_to_quiz(e)					
				}
				
			});
		}
		else
		{
			setErr("You must login as user to these access quizzes.")
			setTimeout(() => {setErr(false)}, 2000);

		}
			
	}	
	const go_to_quiz=(e)=>{
								
		
		let quiz_id=e["quiz_id"];
		let course_id=e["course_id"];
		let quiz_cat=e["quiz_category"];
	    let quiz_name=e["quiz_name"];
		let quiz_de=e["quiz_de"];
		let quiz_back=e["quiz_back"];
		let ends_on=e["ends_on"];
		let quiz_attempts=e["quiz_attempts"];
		let quiz_timer=e["quiz_timer"];
		
				let k=moment(new Date(ends_on)).format('MMMM Do YYYY')
				let param={
								course_quiz_id:course_id,
								quiz_id:quiz_id,
								quiz_name:quiz_name,
								quiz_cat:quiz_cat,
								quiz_img:quiz_back,
								ends_on:k,
								quiz_de:quiz_de,
								duration:newquizQuestions.length,
								quizQuestions:JSON.stringify(newquizQuestions),
								quizAnswers:correctAns.toString(),
								quiz_timer:quiz_timer,
								quiz_attempts:quiz_attempts,
							}
							/*history.push({
							pathname: '/quiz/start', 
							state: param
							});*/
							setQuiz_data(param)
						 	
	}
	
	const back_to_srceen = async () =>
	{
		setQuiz_data(false)
	}
	const get_default_quiz = async () =>
	{
		let start =0;		
		let end =40;		
		let op="module_level_id"
		let key=chapter_data['module_level_id']+"_"+op+"_quiz_added_"+start+"_to_"+end
		let param={
		'module_level_id':chapter_data['module_level_id'],
		'operation':op,
		'search_by':"search",
		'start':start,
		'end':end,
		'key':key}
		
		getCacheDetails(key,"getQuizAdded",param)
		.then(data => {
			//alert("jh")
			////console.log(param)
			localStorage.removeItem(key);
			//console.log(data)
			setQuiz(data)
			setLoader(false) 

		});

	}
	const addDefaultSrc=(ev)=>{
	  ev.target.src = images['sm_img']
	}

	const handleCall=()=>{
	  setQuiz_data(false)
	}

  useEffect(() => {
	 // alert(quiz_list.length)
		//get_default_quiz()
	  ////console.log(chapter_data)
	  
	  if(window.location.pathname=="/module-assigments")
	  {
		  get_default_quiz()
	  }  
  },[]);

 	const QuizList= () =>{
		return(
		<Box  >
				{
					quiz_list.map((e) => (
						<Grid container  spacing={4}>
						 
						<Grid item style={{paddingRight:'0px'}}>
							<ButtonBase className={classes.image}
							onClick={()=>{verify_unit_completion(e)}}>
							  <LazyLoadImage className={classes.img} alt="complex" 
							  src={quiz_path+e['quiz_back']} 
							  onError={addDefaultSrc} />
							</ButtonBase>
						</Grid>
						
						<Grid item xs={10}  xl={10} lg container >
						<Grid item xs container direction="column" >
						<Grid item xs>
							
							<Typography component="div" variant="subtitle1">
							<Box lineHeight={1.25} sx={{cursor:"pointer"}} color="primary.main" fontWeight="600" 
							onClick={()=>{manage_quiz_flow(e)}}>
							{e['quiz_name']}
							</Box>
							</Typography>
								
							<Typography variant="subtitle2" color="textSecondary" className={classes.details}>
							{[undefined,"null","",null].includes(e['quiz_de'])?"":e['quiz_de']}
							</Typography>
						</Grid>
						</Grid>
						
						{/*<Grid item>
							<Typography variant="caption" component="div"  color="textSecondary">
							{moment(e['added_on']).format("lll")}
							</Typography>
						</Grid>*/}
						</Grid>
					
					</Grid>
						))
					
				}
				</Box>
	)};
		
 	const Chapters= () =>{
		return(
			<Grid container spacing={2}>
				
				<Grid item xs={12} >
				<Typography variant="subtitle1" color="textPrimary">
				<Box fontWeight="600" >
				 Quizzes
				</Box>
				</Typography>
				</Grid>
			
				<Grid item xs={12} >
				{
					quiz_list.length==0 && [undefined,true].includes(loading)
					?		
						<Box display="flex" justifyContent="center" padding={2}>
						<CircularProgress color="primary" />
						</Box>
					:
						quiz_list.length>0
						?<QuizList />
						:
							<Box display="flex" justifyContent="left">
							<Typography  variant="subtitle1" color="textSecondary">
							No quizzes have been added here.
							</Typography>
							</Box>
		      }
				</Grid>
					
			</Grid>
		)
	}

  return (
  	<Box>
	{
		msg!=false 
		?<AlertBox 
		show_status={true} 
	    msg={msg}
	    msg_type={"warning"} />
		: ''
	}
	{
		quiz_data==false
		?<Chapters />
		:
			<>
			<Box display="flex" marginBottom={2} >
				<Tooltip  placement="right-start"  title="Back to quiz screen">
				<IconButton
				className={classes.avatar2}
				onClick={()=>{back_to_srceen("prev")}}>
				<FaChevronLeft   className={classes.icon2}   fontSize="large" />
				</IconButton>
				</Tooltip>
			</Box>
				
			<QuizrWelCard quiz_data={quiz_data} parentCallback={handleCall} />
			</>
	}
	</Box>
  );
}