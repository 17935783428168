
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import {
	bindHover,
	bindPopover, usePopupState
} from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import React, { Component } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import AlertBox from "../../../AlertBox";
import { deleteLocal, getCacheDetails } from "../../../caching";
import { api } from "../../../Helper";
import { useStyles } from "../../../useStyles";
import ProfileSlots from '../../slots/ProfileSlots';







let status=null;
let student_id=null;

let token=localStorage.getItem('login_token')
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}
if(token=="admin")
{
	student_id="admin"
}
let active_mentor_name=null
let active_mentor_email=null
let active_mentor_req=null
let active_mentor_id=null
class Scroll extends Component {

    constructor(props) {
    super(props);
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	loading:true,
	msg:false,msg_type:"success",
	alert_msg:false,
	apply_popop:false,start_time:"",end_time:'',
	slots:null,date:null,
	btn_loading:false,
	hover:false,active_card:null,
	profile_details:[],mentor_id:null,
    anchorEl: null,
	open_request_count:0
    }
  }

		
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(student_id+"_all_mentors_")
		    let keys_2=this.deleteLocal(student_id+"_mentors_")
		    let keys_4=this.deleteLocal(student_id+"_my_mentors_")
			let keys_3=this.deleteLocal(student_id+"_upcoming_mentorship_")		
			let keys_5=this.deleteLocal(student_id+"_all_mentors_0_to_")		
				let keys_6="user_"+student_id+"_mentor_slot_"
				let keys_7="mentor"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6,keys_7]
				return keys
	}
	
	handleParent=()=>{
		//alert("date - "+e);
		//e.preventDefault();
		//alert(new date("2022-06-27 04:51:17"))

		this.setCustomState("apply_popop",false)			
		this.setCustomState("start",0);
		this.props.handleCallback("connect");
		this.setCustomState("hasMore",true)
	}
	
	setCustomState=(name,val)=>{
		this.setState({[name]:val},()=>{console.log(this.state[name])})
	}
	
	
	
	componentDidMount()
	{
		
			
	}	
	
		is_limit_reached_for_mentee=(student_id)=>{
		
		let payload={
			'user_uid':student_id
		}
		
		axios.post(api+'/get_open_request_count_mentee',
		payload)
		.then((response) => {
				//console.log(response.data);
				this.setCustomState("open_request_count",response.data['open_request_count']);
					
		})
		
	}
	
	apply_mentorship = (id,name,email,req) => {
		active_mentor_name=name
		active_mentor_email=email
		active_mentor_req=req
		active_mentor_id=id
		
		
		if(status=='student')
		{	
			this.is_limit_reached_for_mentee(student_id);
			if(this.state.open_request_count>5)
			{
				this.setCustomState("alert_msg","Open request reached.")
				this.setCustomState("msg_type","warning")
			}
			else
			{
				this.setCustomState("apply_popop",true)
			}
		}
		else
		{
			
			this.setCustomState("alert_msg","You must login as User.")
			this.setCustomState("msg_type","warning")
			setTimeout(() => {
						this.setCustomState("alert_msg",false)
			}, 3000);
			
		}		
		//alert(mentor_id)
	};

		
	
	handleClose = () => {
		this.setCustomState("profile_popop",false)
		this.setCustomState("apply_popop",false)
		this.setCustomState("mentorship_popop",false)
	}
	
	
	


    render()
	{
			const popupState=this.props.popupState;

	const styles ={
	
		card: {position:"relative",width: '100%',height: 'auto',},
		btn:{    color: 'rgba(0, 0, 0, 0.26)','box-shadow': 'none', 'background-color': 'rgba(0, 0, 0, 0.12)'},
		img:{width: '100%',height: '100px'},
		imgF:{width: '100%',height: '250px'},
		
		box:{height:'auto',paddingRight:"12px"},
		small: {width: '64px',height: '64px',
		"&:hover": {
			transform: 'scale(1.02)',
		//'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
		'box-shadow': '0px 5px 3px -2px rgb(0 0 0 / 20%), 0px 5px 4px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

		}},
		avatar: { 'padding-right':'1rem'},
		iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px'},
	  
		   name: { cursor:"pointer",   overflow: "hidden",display: "-webkit-box",
					WebkitLineClamp: 1,WebkitBoxOrient: "vertical",    'text-align': 'left' },
				
		};

			
    const ApplyDialog = () =>
	{ 
		{
			//onBackdropClick="false"
		}
			return(
			<Dialog
			open={this.state.apply_popop}
			onClose={this.handleClose} 
			fullWidth style={styles.border}
			maxWidth="md" >
			<DialogActions >
				<IconButton 
				onClick={this.handleClose}
				style={styles.iconsbg}>
							<CloseIcon color="grey"  
							style={{fontSize:"0.85rem"}} />
				</IconButton>
			</DialogActions>
			<DialogContent >
			
			<ProfileSlots 
			mentor_data={this.props.e}
			mentor_email={this.state.mentor_email}
			mentor_req={this.state.mentor_req}
			active_mentor_name={active_mentor_name}
			active_mentor_email={active_mentor_email}
			active_mentor_id={active_mentor_id}
			parentCallback={this.handleParent} />
			
			</DialogContent>			
			
			</Dialog>
			
			)
	}			
				

	return ( 
		
	
		<React.Fragment>
		
			
			<React.Fragment>
			{
				this.props.e['slot']==0 ||  status!='student'
				?
						<Button variant="contained" size="small" {...bindHover(popupState)} style={styles.btn}>
						Connect
						</Button>
						
				:
						<Button 
						variant="contained" 
						size="small"
						color={"primary"}
						disabled={this.props.e['slot']==0?true:false}
						onClick={()=>this.apply_mentorship(this.props.e['id'],this.props.e['name'],this.props.e['email'],this.props.e['mentor_req'])}>
						Connect 
						</Button>
			

			}
			  <HoverPopover
				{...bindPopover(popupState)}
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'center',
				}}
				transformOrigin={{
				  vertical: 'top',
				  horizontal: 'center',
				}}
				classes={{paper:"pop-paper"}}
			  >
			
			<Box textAlign="center" borderRadius="12px">
			<Typography variant="subtitle1" color="primary">
			 <Box fontWeight="600">
			 {
				this.props.e['slot']!=0 &&  status!='student'
				?"Not Available"
				:"Unavailable Slots"
			}
			</Box>
			</Typography>
			
			<Typography variant="subtitle2" color="textSecondary">
			{
				this.props.e['slot']!=0 &&  status!='student'
				?" You must login as User."
				:<>
				This mentor does not have any slot.
				<br/>
				You can connect once slot available.
				</>
			}
			</Typography>
			</Box>

		  </HoverPopover>
		  <ApplyDialog />
		  </React.Fragment>
		  {
			this.state.alert_msg!=false
			? <AlertBox show_status={true}
			msg={this.state.alert_msg} 
			msg_type={this.state.msg_type} />
			:''
		 }
			
		</React.Fragment>
	);
  }
}


export default (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
	 const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })
	return (
        <Scroll  
		classes={classes} 
		location={location}  
		history={history}
		e={props.e}
		popupState={popupState}
		handleCallback={props.handleCallback}
		/>
    )
}
