import React, { Component, useContext,useEffect, useState,ChangeEvent } from 'react';
import {Snackbar,Box} from '@material-ui/core';
import {  inactivity_duration, userProfile_path , barColor,is_local,api,socket_setup} from "../Helper";
import {Alert} from '@material-ui/lab';
import { UserContext } from '../courses/UserContext';
import { io } from "socket.io-client";


export default function ConnectionStatus({user,parentCallback}) {
	
	const context_ref = useContext(UserContext);
	const [alert_view, set_alert_view] = useState(true);
	const [, forceUpdate] = useState(0);

	const handleClose = () => {
		set_alert_view(false);
	}
     useEffect(() => {
		set_alert_view(true);
		//console.log((context_ref.socket_info))
		//console.log((context_ref.socket_info))
		if((context_ref.socket_info)!=null)
		{
			if((context_ref.socket_info).connected==false)
			{
				/*let socket = io(socket_setup['end_point'], {
				transports: ["websocket"],
				autoConnect: true,
				reconnect: false,
				rejectUnauthorized:false,
				withCredentials: true,
				cors: {origin: socket_setup['origin'],methods: ["GET", "POST"]}
				});
				//context_ref.set_socket_info(socket);
				*/parentCallback();
			}
		}
		forceUpdate(n=>!n)
	}, [context_ref.socket_info,user]);
		return (
	
	<Snackbar open={alert_view}
	anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
	style={{marginTop:'2%'}} onClose={handleClose}>
	<Alert style={{width:'fit-connect'}} elevation={6} variant="filled"
	severity={(context_ref.socket_info)!=null?(context_ref.socket_info).id?"success":"warning":"warning"} 
	onClose={handleClose}>
	<Box marginLeft={2} marginRight={2}>
	{(context_ref.socket_info)!=null?(context_ref.socket_info).id? "Connected":"Connection failed":"Connection failed"} 
	</Box>
	</Alert>
	 </Snackbar>


  );
}
