import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import ReactDOM from 'react-dom';
import React from 'react';
import App from './App';

import './index.css';
import './react.css';

ReactDOM.render(
<App />,
document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
