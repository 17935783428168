import {
	Box,
	Grid, 
	List, ListItem, ListItemAvatar, ListItemText,
	ThemeProvider, Typography
} from '@material-ui/core';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import MaximizeIcon from '@material-ui/icons/Maximize';

import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
	size:{fontSize:"12px !important"}
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		let points=[{"text":"Visit and use our Website"},
		{"text":"Avail to our services"},
		{"text":"Select appropriately skilled and qualified suppliers "},
		{"text":"Build relationships with our audience, clients, partners  and other academic institutions"},
		{"text":"For research and statistical purposes"},
		{"text":"Marketing and business development purposes "},
		{"text":"Internal administrative and auditing purposes . "}
		]
		
		
		const classes = this.props.classes;	

		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  >

					
	
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 2.	Information we collect 
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" color="textSecondary"> 
					 <Box  marginTop={1.5} lineHeight={1.5}> Some sections of our site can be accessed without revealing personal information. But, for getting access to features or services related to courses, content offerings or subscriptions or events or webinars or any other or access to any features or benefits on our site. To get this access you have to submit your personal information. Here, in ‘Personal Information’ includes information that can be used to identify you as a person. It may also be referred to as personally identifiable information or personal information. Personal data/information may include your name, email address, IP address, device identifier along with any other information as and when required. You are solely responsible for ensuring that the information that you have shared with us is completely authentic and relevant. Incorrect information may affect your accessibility to our services and services.   </Box>
					 </Typography> 
					
					
					 
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 By accessing our site, you agree to the following: 
					 </Box>
					 </Typography> 
					

					<List>
					  <ListItem alignItems="flex-start" >
						<ListItemAvatar >
						<FiberManualRecordIcon fontSize="small" className={classes.size}/>
						</ListItemAvatar>
						<ListItemText primary={
							<Typography  variant="subtitle1" color="textSecondary"> 
							<Box lineHeight={1.5}>
							We need to collect your personal information because your personal information is needed for us to give you access to certain services. While using the website we are bound to agree to all the terms and conditions, along with the conditions mentioned in the Privacy Policy. By accepting them you comply with the contract to the services we provide to you, in complete accordance with our 
							policies and terms of service.
							</Box>
							</Typography>
							}
							/>
					  </ListItem>
					  
					    
						<ListItem alignItems="flex-start" >
						<ListItemAvatar >
						<FiberManualRecordIcon fontSize="small" className={classes.size}/>
						</ListItemAvatar>
						  <ListItemText primary=
						  {
							  <Typography  variant="subtitle1" color="textSecondary"> 
								<Box lineHeight={1.5}>
								We need to comply with our legal obligations for which we need your personal information
								</Box>
								</Typography>
							
							}
							/>
					  </ListItem>
					  
					  
					   <ListItem alignItems="flex-start" >
						<ListItemAvatar >
						<FiberManualRecordIcon fontSize="small" className={classes.size}/>
						</ListItemAvatar>
						<ListItemText primary={
							
							
						<>
						<Typography  variant="subtitle1" color="textSecondary"> 
								 <Box lineHeight={1.5}>
								  
								  Also, personal information is needed for legitimate interests of us and others. Our legitimate interests include: 
								  
								  </Box>
					 </Typography>	
						
						
					   <List> 
					   {
						   points.map((e, index) => (
							<ListItem alignItems="flex-start" >
							<ListItemAvatar >
							<MaximizeIcon fontSize="small" className={classes.size}/>
						
							</ListItemAvatar>
							  <ListItemText primary={
								
								<Typography  variant="subtitle1" color="textSecondary"> 
								 <Box lineHeight={1.5}>
								  
								  {e['text']}
								  
								  </Box>
								  </Typography>
								  
							} />
							</ListItem>
							))
					   }
					  </List>
					  </>
						
						
						
						} />
					  </ListItem>
					  
					    <ListItem alignItems="flex-start" >
						<ListItemAvatar >
						<FiberManualRecordIcon fontSize="small" className={classes.size}/>
						</ListItemAvatar>
						  <ListItemText primary={
							<Typography  variant="subtitle1" color="textSecondary"> 
							<Box lineHeight={1.5}>
							  By accepting our terms and conditions and Privacy policy, you give us consent to send you communications of our interests or where required to rely upon consent by local law.
								</Box>
						  </Typography>}
								/>
					  </ListItem>
					  
					  
					  
					</List>
					
					
					<Typography  variant="subtitle1" color="textSecondary"> 
					 <Box  marginTop={1.5} lineHeight={1.5}>
					Which information can be used for which legal activity will depend on personal information that you share with us. Also, in case of any legal issues, the jurisdiction shall be any court of law in India only.<br/> <br/> 
					When we collect your information, it is our duty to keep it safe. However, any misuse of any personal information is not representative of OpenGrowth Academy, or any other firm, individual or group of individuals associated with OpenGrowth Academy in any manner. <br/> <br/> 
					While collecting personal information we also undertake a balancing test that our legitimate interests are not weighed and vice versa. <br/> <br/> 

					By agreeing to our terms of service, and privacy policy, you also give your consent to use personal information for marketing purposes. However, if you do not want us to use for information, you can write to us academy_connect@opengrowth.com 
					</Box>			
					</Typography>	
					
		</Box>			
		</Grid>
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
