import { Box, Typography , Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { Component } from "react";
import Link from '@material-ui/core/Link';



const useStyles = makeStyles((theme) => ({
  grid:{padding:"16px",
  border:'1.75px solid black',
  borderRadius:'40px'}
}));

class Scroll extends Component {
constructor(props) {
    super(props);

	this.state={webinar_list:[],speaker_list:[]}}

	
    render()
	{
		const classes = this.props.classes;
		const styles={
			card:{paddingLeft:'0px',paddingRight:'0px',backgroundColor:'#FBFBFB'},
			trans:{'box-shadow': 'rgb(140 152 164 / 25%) 2px -3px 6px 0px',
		'border-bottom-left-radius':'0px','border-bottom-right-radius':'0px'}
			}
		let path="/assets/images/homepage/images/events/"
        const webinar = [
        {'img':path+'blog1.jpg','name':'Prachi Shevgaonkar: Helping Us Measure Greenhouse Gas Emissions','title':'Prachi Shevgaonkar is the founder of “Cool The Globe”, an app to help citizens reduce their greenhouse gas emissions to a target. Her dream is to integrate climate action into the lives of ordinary citizens.',href:'https://www.opengrowth.com/article/prachi-shevgaonkar-helping-us-measure-greenhouse-gas-emissions'},
		
        {'img':path+'blog2.jpg','name':'Tips to Women Entrepreneurs before the Entrepreneurial Journey Starts','title':'Nothing changes in a day or two, and when it comes to your business, it will take years of hard work and dedication to grow it. Take these tips as an opportunity to show the world you are no less than any other in the industry.',href:"https://www.opengrowth.com/article/tips-to-women-entrepreneur-before-the-entrepreneurial-journey-starts"},
		
        {'img':path+'blog3.jpg','name':'Inspiring Tales of Women and their Successful Self-Funded Businesses','title':'Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.',href:'https://www.opengrowth.com/article/inspiring-tales-of-women-and-their-successful-self-funded-businesses'},
		
        {'img':path+'blog4.jpg','name':'9 Women-Owned Businesses to Look Out for in 2022','title':"Even though the socio-cultural ecosystem is biased, women are leaving no stone unturned to break stereotypes. As women entrepreneurs are booming worldwide, it is now time to take women-owned businesses seriously.",href:"https://www.opengrowth.com/article/women-owned-businesses-to-look-out-for"},
        ];
		
	const ProgressCard = () => {
		
		return (
		<Grid item xs={12} container direction="row"
                    alignItems="center"
                    justify="center" 
					style={{backgroundColor:'white'}}>
		
		
		{
			webinar.map((e, index) => (
										 
				<Grid item sm={12}  lg={6} style={{padding:"16px"}}>		
				
							
					<Grid item xs={12} container className={classes.grid}>
					<Grid item  xs={3}  >
					<Box display="flex" margin={1} 
					  justifyContent="center"
					  alignItems="center" 
					   height="122px" width="122px">
					  <img src={e['img']} height="100%"  width="100%" alt="" style={{borderRadius:'12px',backgroundSize:'cover'}} />
					</Box>
					</Grid>
					 
					<Grid item  xs={8} >
					<Typography variant="subtitle1" 
					color="textSecondary" >
                    <Box lineHeight={1.25} paddingLeft={2} margin={1}>
					
                      {e['title']}
                      
					  <Box display="inline">
                      <Link target="_blank" href={e['href']}>
					  {" Read More"}
					  </Link>
					  </Box> 
					 
					 </Box> 
					
                     </Typography>
					</Grid>
					</Grid>
					
				</Grid>
			))
			
			
		}
		</Grid>
	)}
	
		return ( 
		<Box marginTop={4} marginBottom={8}>
		<ProgressCard/>
		</Box>
    );
  }
}

export default () => {
    const classes = useStyles();
    return (
        <Scroll classes={classes} />
    )
}
