import cookie from 'react-cookies';
import { Box,Card, Grid , Slide} from '@material-ui/core';
//import { Slide } from '@material-ui/transition';
import React, { Suspense } from 'react';
import CountDown from './CountDown';
import { Timed } from "react-time-sync";

const Speakers = React.lazy(() => import('./Speakers'));
const Popup = React.lazy(() => import('./Popup'));
const Hosts = React.lazy(() => import('./Hosts'));
const WebinarScroll = React.lazy(() => import('./WebinarScroll'));



//let user_data=JSON.parse(cookie.load('webinar_timer_de'))
////console.log(user_data['img'])
const Login=()=>
{
	let d=new Date()
	let day =d.toUTCString();
	let hour = d.getUTCHours();

     return (

        <Card>
		<Popup/>
            <Grid container 
			style={{backgroundColor:"white"}}
                    direction="row"
                    alignItems="center"
                    justify="center" spacing={0}>
        
                   
                   
                   {/* <Grid item xs={10} >
						{day}
					 <Timed>
					  {({ currentTime }) => <div>
					  {`The  jjj  current time is: ${currentTime} ${new Date(currentTime)}`}
					  </div>}
					</Timed>
				   </Grid>	*/}

                   
                   
                   
                    <Grid item xs={10} >
                            <CountDown /> 
                    </Grid>	

                   
                   <Grid item xs={10} spacing={0} container direction="column" alignItems="center" justify="center">
                   <Grid item xs={12} lg={8}>
                    <Suspense fallback={<div> </div>}>
                            <Speakers /> 
                    </Suspense>
                    </Grid>	

                   <Grid item xs={12} lg={8}>
                    <Suspense fallback={<div> </div>}>
                            <Hosts /> 
                    </Suspense>
                    </Grid>	
                    </Grid>	
                

                   <Grid item xs={10} >
                    <Box marginTop={1} marginBottom={1}>
                                    
                     {/*<Box style={{position:'relative'}}>
                    <img src={"/assets/images/webinar/"+user_data['img']} 
                    style={{borderRadius:'25px'}}
                    height='100%' width="100%" />
                    </Box>                 <CarouselCard />*/}
                    <Suspense fallback={<div> </div>}>
                            <WebinarScroll />
                    </Suspense>
                    
                    </Box>	
                </Grid>	
                
        
              </Grid>
           
             </Card>
        )
}
 
export default Login;

  