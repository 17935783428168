import {
  Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography
} from '@material-ui/core';
import axios from 'axios';
import * as React from 'react';




export default function BasicModal(props) {
  const [img, setImg] = React.useState(false);
  const [msg, setMsg] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  
  const styles={border:{'border-radius':'12px'},height:{'line-height':'1.5rem'},left:{marginLeft:'16px'}}
  
  const updateUserImage = () => 
    {
        if(img==false)
        {
            setMsg("Please specify image name!")     
        } 
        else
        {
                   
            axios.post('/api/update_user_image',
             {
                 'email':props.email, 
                 'image':img,
                 
             })
             .then((response) => {
                if(response.data=="updated")
                {setMsg("Image updated successfully!")}
                else {setMsg("Try again later!")}
                            
             });
        }
    }
	
  return (
    <div>
        <Dialog
			open={open}
			onClose={handleClose} 
			fullWidth style={styles.border}
			maxWidth="xs">
			
			<DialogTitle >
			<Typography variant="h6" color="textPrimary">Update user image</Typography>
			</DialogTitle>
			
			<DialogContent >
			  <DialogContentText >
				<Grid container spacing={2}>
				
                                
								<Grid item xs={12} >
									<TextField label="Email" color="primary" variant="outlined"  name="email" type="text"  value={props.email} InputProps={{
                                        readOnly: true,
                                      }}fullWidth/>
								</Grid>
								
                    
								<Grid item xs={12} >
									<TextField label="Image" color="primary" variant="outlined"  name="image" type="text"  onChange={event=>{setImg(event.target.value) }} fullWidth/>
								</Grid>
								
              
								<Grid item xs={12} >
                                
                                <Box>
                                 <Typography variant="subtitle2" color="textSecondary">
                                 {msg}
                                  </Typography>
                                </Box>
                                
								<Box display="flex" flexDirection="row-reverse" >
								<Button variant="contained" size="medium" 
								style={{padding:'11px'}}
								color="primary"  onClick={updateUserImage} 
								fullWidth>
                                Update image
								</Button>
								
								</Box>
                                
                                
								</Grid>
								
								
							
							</Grid>
				
			  </DialogContentText>
			</DialogContent>
			</Dialog>
			
    </div>
  );
}