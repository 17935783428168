import React, { Component, Suspense } from 'react';
import { Card, CardContent} from "@material-ui/core";
import { CalenderCard, CourseAccordian , 
	  CourseAddStepper, CourseEnrolled, CoursesBookmarked, 
	  CourseSort, CourseTagsSearch,
	  MyCourses, PopularCourse, CourseComponents , 
	  ProfessorCourseBlock, CourseViewNewPage,
	  ViewedRecently , Build_Course_Structure 
} from '../Routes';
import {
	Calender_Skeleton, Horizontal_Course_Skeleton,
	Vertical_Course_Skeleton , Course_Structure_Skeleton,
	CourseAccordian_Skeleton, UnitSkeleton
} from '../Skeleton';



const location = window.location.pathname;



class Courses extends Component {

    constructor(props) {
    super(props);
    }
  
    render()
    {
    return (
        
    <>
	
	
	
	{ (window.location.pathname).startsWith("/courses-view")==true?
	<Suspense fallback={<div> </div>}>
	<CourseViewNewPage />
	</Suspense>
	:''}
	
	
	{ (window.location.pathname).startsWith("/findcourse")==true?
	<Suspense fallback={<div><Horizontal_Course_Skeleton /></div>}>
	<CourseSort />
	</Suspense>
	:''}
	
	
	
	{ (window.location.pathname).startsWith("/searchcourse")==true?
	<Suspense fallback={<div><Vertical_Course_Skeleton /></div>}>
	<CourseTagsSearch />
	</Suspense>
	:''}
	
	
	
	
	{  
		(window.location.pathname)=="/course/view"
		?
			<Suspense fallback={<Horizontal_Course_Skeleton />}><ProfessorCourseBlock /></Suspense>
		:''
			
	}
	
		
	{  
			(window.location.pathname).startsWith("/course/update")==true  
			?
				<Suspense fallback={<CourseAccordian_Skeleton />}><CourseAccordian /></Suspense>
			:
				(window.location.pathname)=="/course/add"
				?<Suspense fallback={<div></div>}><CourseAddStepper /></Suspense>
				:''
	}
	
	
	{ (window.location.pathname)=="/course-modules"?
	<Suspense fallback={<UnitSkeleton />}>
	<CourseComponents />
	</Suspense>
	:''}
	
	{ (window.location.pathname)=="/build-course-structure"?
	<Suspense fallback={<div><Course_Structure_Skeleton/></div>}>
	<Build_Course_Structure />
	</Suspense>
	:''}
	
	{ (window.location.pathname).startsWith("/bookmarked")==true?
	<Suspense fallback={<div><Vertical_Course_Skeleton /></div>}>
	<CoursesBookmarked />
	</Suspense>
	:''}
	
	{ (window.location.pathname).startsWith("/viewedcourse")==true?
	<Suspense fallback={<div><Vertical_Course_Skeleton /></div>}>
	<ViewedRecently />
	</Suspense>
	:''}
	
	{ (window.location.pathname).startsWith("/popularcourses")==true?
	<Suspense fallback={<div><Vertical_Course_Skeleton /></div>}>
	<PopularCourse />
	</Suspense>
	:''}
	
	
	{ (window.location.pathname).startsWith("/mycourses")==true?
	<Suspense fallback={<div></div>}>
	<MyCourses />
	</Suspense>
	:''}
	
	{ (window.location.pathname).startsWith("/enrolledcourses")==true?
	<Suspense fallback={<div></div>}>
	<CourseEnrolled />
	</Suspense>
	:''}
	
	{ (window.location.pathname).startsWith("/timeline")==true?
	<Suspense fallback={<div><Calender_Skeleton/></div>}>
	<CalenderCard />
	</Suspense>
	:''}
	
    </> 

      );
    }
}
export default Courses;

  