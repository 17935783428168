
import { Box, Grid, ThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import { Academytheme } from "../../functions/useStyles";

const LoginCard = React.lazy(() => import('./LoginCard_EmailOnly'));
const CarouselCard = React.lazy(() => import('./CarouselCard'));

const Login=()=>
{
  return (
    <ThemeProvider theme={Academytheme}>
	
	<Grid container 
			  direction="row"
			  alignItems="center"
			  justify="center"
			  spacing={0}>
  
        <Grid item xs={12} sm={12} md={8}>
                <Suspense fallback={<div> </div>}>
						<CarouselCard /> 
			</Suspense>   
        </Grid>
		
		
        <Grid item xs={12} sm={12} md={4}>
            <Suspense fallback={<div> </div>}>
						<LoginCard /> 
			</Suspense>
        </Grid>
        
        
     </Grid>
    
	</ThemeProvider>)
}
 
export default Login;