import { Avatar, Box, Button,Card, CardContent, CardHeader, CircularProgress, Grid,
	 IconButton,
	 Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { api, successColor } from "../../functions/Helper";







const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);




class Logs extends Component {

    constructor(props) {
	super(props);
	this.state={
		    msg:false,msg_type:false,
            user_show:[],start:0,end:10,
			hasMore: true,record_len:0,
			webinar_id:null,
			search_for:'All',search:null,}
    }
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
			axios.post('/api/scheduleWebinar',
			{'start': this.state.start,
			'end': this.state.end,
			'search':'select'})
			.then((response) => 
			{
				this.setState({ user_show: [...this.state.user_show,...response.data]})
				if(response.data.length<this.state.end)
				{
					this.state.hasMore=false;
					this.setState({hasMore:false})
				}
			})		
			}, 1500);
		}
	};	
	
	
	deleteWebinar = (id) => {
    
		axios.post(api+'/deleteWebinar',{'id':id})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        this.getWebinarSchedule()
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}
	
	
	updateWebinar = (id) => {
    
		
		this.setState({webinar_id:id})
		this.props.parentCallback(id);
		
	}
	
	
	componentDidMount()
	{
		
		this.getWebinarSchedule()	
	}
	getWebinarSchedule=()=>
	{
		axios.post('/api/scheduleWebinar',
		{'start': this.state.start,
		'end': this.state.end,
		'search':'select'})
		.then((response) => 
		{
			this.setState({user_show:response.data})
			//console.log(response.data)
			if(response.data.length<this.state.end)
			{
					this.state.hasMore=false;
					this.setState({hasMore:false})
			}
			//alert(response.data[0]['startTime'])
		})
	}
  	render()
  	{
	
		const classes = this.props.classes;
		const NewCard = () => {
		return (
			<InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.user_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
									   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					
					}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Webinar id</StyledTableCell>
						<StyledTableCell >Title</StyledTableCell>
						<StyledTableCell >Start On</StyledTableCell>
						<StyledTableCell >Ends On</StyledTableCell>
						<StyledTableCell >Status</StyledTableCell>
						<StyledTableCell ></StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
		)}
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
		
           <StyledTableCell  >
			 <Box display="flex" flexDirection="row" p={1} m={1}>
				<Box p={1} >
				 <Avatar src={'assets/images/webinar/'+e['img']}  />
				</Box>
				<Box p={1} >
                    {e['webinar_id']}
				</Box>
			  </Box> 
			</StyledTableCell>
			  <StyledTableCell  >{e['title']}</StyledTableCell>
			  <StyledTableCell  >{e['startTime']}</StyledTableCell>
			  <StyledTableCell  >{e['endTime']}</StyledTableCell>
			  <StyledTableCell  >
              {e['active']==0?'inactive':
              <Typography style={{color:successColor,fontWeight:'bold'}}>active</Typography>}
              </StyledTableCell>
			
			<StyledTableCell  >
               <IconButton  onClick={()=>{this.updateWebinar(e['webinar_id'])}}>
						<EditIcon color="grey"   />
				  </IconButton> 

				  <IconButton  onClick={()=>{this.deleteWebinar(e['webinar_id'])}}>
						<CloseIcon color="grey"   />
				  </IconButton>
             </StyledTableCell>
			
			 
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		<Box marginTop={2}>	
		<Card style={{height:'fit-content'}} elevation={3}>
		
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		
		
		<CardHeader title={"Webinars"}/>
		<CardContent>
		<Grid container direction="row" spacing={2}>
				    
		<Grid  xs={12} spacing={2}>
			<NewCard />
		</Grid>    
		
		</Grid>
		</CardContent>
		</Card>
		</Box>
		
		  );
  	}
}

export default Logs;