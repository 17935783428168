import React, { Component, useContext } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { Academytheme } from "../useStyles";
const ButtonSection = React.lazy(() => import('./ButtonSection'));
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  }
}));
class StudentDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { msg: false };
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={Academytheme}>
        <div className={classes.root}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <ButtonSection />
            </Grid>
            <Grid item xs={12}>
              <Box 
			  padding="8px 16px" 
			  border="1px solid #d6dce1" 
			  borderRadius="25px" 
			  fontWeight="bold">
			  <Typography variant="h6" color="textPrimary">
			  <Box fontWeight="bold">
			  My Hub Space
			  </Box>
			  </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Card>
              <CardContent>
			  
			  <Typography variant="h6" color="textPrimary">
			  <Box fontWeight="bold" marginBottom={2}>
			  OpenGrowth Hub
			  </Box>
			  </Typography>
			  <img 
			   height="auto"
			   width="70%" 
			   src="https://www.opengrowth.com/assets/og/images/hub/best-project-management-tool.png"/>
              </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    );
  }
}


const StudentDashboardWrapper = (props) => {
 	    const classes = useStyles();
 return <StudentDashboard classes={classes} />;
};

export default StudentDashboardWrapper;
