import {
	Box, Button, Card, CardContent, CardHeader, CircularProgress, IconButton,

	Grid, Typography
} from '@material-ui/core';
import axios from 'axios';
import React, { Component, Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import InfiniteScroll from "react-infinite-scroll-component";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import AlertBox from "../../AlertBox";
import { deleteLocal, getCacheDetails } from "../../caching";
import { api, findCourse, get_name_slug,generateProfileUrl, translateText } from "../../Helper";
import { ViewMentors_Skeleton, Account_Skeleton } from '../../Skeleton';
import { useStyles } from "../../useStyles";
import RenderCard from './RenderCard';
import CloseIcon from '@material-ui/icons/Close';

const MentorsForDashboard = React.lazy(() => import('../dashboard/MentorsForDashboard'));


const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

let student_id=null;
let email=null;
let fname=null;
if(["admin","webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
}

class Scroll extends Component {

    constructor(props) {
    super(props);
	this.findCourse = findCourse.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
	this.translateText = translateText.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.get_name_slug = get_name_slug.bind(this);
	this.state={ 
	course_list:[],mentors_list:[],
	progress_com:0,progress_left:9,loading:true,
	start:0,end:10,msg:false,alert_msg:false,
	profile_popop:false,
	hover:false,
	active_card:null,
	profile_details:[]}
    }
	
	
	get_full_profile = (mentor) => {	
	let name=this.get_name_slug(mentor['name']);
    let mail_url=generateProfileUrl(mentor['email']);
	this.props.history.push({
	  pathname: '/profile/'+mail_url,
	  state: {
			profile_mail:mentor['email'],
			profile_type:'professor',
			profile_id:mentor['professor_id'],
			course_author_id:mentor['professor_id']
	  },
	})
	}
	get_new_mentors = (nextSlide) => {
		
		let len=0
		if(isMobile)
		{
			len=nextSlide
		}
		else
		{
			len=nextSlide+2
			//alert(nextSlide)
		}	
		if(len==this.state.mentors_list.length-1)
		{
			setTimeout(() => {
			this.state.start=this.state.start+this.state.end
			this.getCachePlay(this.state.start,this.state.end,true)	
			}, 1500);
		}
		
	}
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		setTimeout(() => {
		this.state.start=this.state.start+this.state.end
		this.getCachePlay(this.state.start,this.state.end,true)	
		}, 1500);
	}}
	componentDidMount()
	{
		//this.getCachePlay(this.state.start,this.state.end,false)	
		setTimeout(() => {
		//this.setState({ mentors_list: k })
		this.getCachePlay(this.state.start,this.state.end,false)	
		}, 1500);
		
	}
	
	handleCacheCallback = () => {
		this.getCachePlay(0,5,false)	
	}
	
	getCachePlay = (start,end,fetch) => {
		
		//alert(start)
		//alert(end)
		//alert(fetch)
		this.getCacheDetails(
		student_id+"_my_mentors_"+start+"_to_"+end,
		"get_mentees_mentors",
		[student_id,start,end])
		.then(data => {	
		
				this.setState({loading:false},()=>{console.log(this.state.loading)})
				if(data.length<end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					
					this.setState({ mentors_list: data })
					//let h=data[0]
					//alert(h['query'])
				}	
				else
				{
					if (data.length>0)
					{
						this.setState({
						 mentors_list: [...this.state.mentors_list,...data]
						})
					}
					else
					{
						this.setState({ msg: "You have seen it all!" })
						setTimeout(() => {
						this.setState({msg:false})	
						}, 1500);
					}

				}
				//console.log(this.state.mentors_list)
				//alert(this.state.mentors_list.length)
		});
		
	}
	
	getInstructorProfile = (mentor) => {
		//alert(mentor['id'])
		this.setState({ profile_popop: true },()=>{console.log(this.state.profile_popop)});
		this.setState({ profile_details: mentor },()=>{console.log(this.state.profile_details)});

	};
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(student_id+"_all_mentors_")
		    let keys_2=this.deleteLocal(student_id+"_mentors_")
		    let keys_3=this.deleteLocal(student_id+"_my_mentors_")
		    let keys_4=this.deleteLocal(student_id+"_upcoming_mentorship_")
			let keys=[keys_1,keys_2,keys_3,keys_4]
			return keys.toString()
	}
	manage_mentorship = (mentor_id,mentor_email,mentor_name,time_slot_id) => {
		
		this.setState({loading:true},()=>{console.log(this.state.loading)});

		
		
		let payload={
			'mentor_id':mentor_id,
			'user_uid':student_id,
			
			'mentee_email':email,
			'mentee_name':fname,
			'mentor_email':mentor_email,
			'mentor_name':mentor_name,
			'time_slot_id':time_slot_id,
			
			'status':2,
			'reject_reason':"by mentee",
			'keys':this.deleteKeys()
		}
		//console.log(payload)
		axios.post(api+'/og_manage_mentor_request',
		payload)
		.then((response) => {
			
				//console.log("this is response");
				//console.log(response.data[0]);
			
				//alert(response.data[0]['msg'])
				if(response.data[0]['msg']=="success")
				{
					this.setState({alert_msg:"Mentorship upated"},()=>{console.log(this.state.alert_msg)});
					this.setState({msg_type:"success"},()=>{console.log(this.state.msg_type)});
				}
				else
				{
					this.setState({alert_msg:"Please try again later."},()=>{console.log(this.state.alert_msg)});
					this.setState({msg_type:"warning"},()=>{console.log(this.state.msg_type)});
				}
				
				this.setState({hasMore:true},()=>{console.log(this.state.hasMore)});
				this.setState({start:0},()=>{console.log(this.state.start)});
	
				//alert(this.state.msg)
				setTimeout(() => {
					this.setState({loading:false},()=>{console.log(this.state.loading)});

					this.setState({alert_msg:false},()=>{console.log(this.state.alert_msg)});
					
					this.getCachePlay(this.state.start,this.state.end,false)
				
				}, 2500);
				
		});

	};
	
	handleClose = () => {
		this.setState({ profile_popop: false },()=>{console.log(this.state.profile_popop)});
	}
	
	
    render()
	{
	const styles ={
	
		card: {position:"relative",width: '100%',height: 'auto',},
		
		img:{width: '100%',height: '180px'},
		imgF:{width: '100%',height: '222px'},
		
		box:{height:'auto',paddingRight:"12px"},
				
		iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px'}

		
				
		};
		const classes = this.props.classes;
		let i=0;

		const MinProfile = () => {
		let arr=this.state.mentors_list;
		
		return (
				<Carousel responsive={responsive}  beforeChange={nextSlide => this.get_new_mentors(nextSlide)} >
				{
		
				arr.map((e, index) => (
				<Box marginBottom={1} marginRight={2}>
				<RenderCard user={e} scroll={"carousel"} parentCacheCallback={this.handleCacheCallback} />

				</Box>
							
			))}
			</Carousel>
		)
		}

	
	const MentorsProfile = () => {
		let arr=this.state.mentors_list;
		arr=window.location.pathname=="/mentors-dashboard"?arr.slice(0,2):arr
		
		return (
			<InfiniteScroll style={{overflow:'hidden'}}
			dataLength={this.state.mentors_list.length}
			hasMore={this.state.hasMore}
			loader={ 
			
						this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
			
			
			}
			endMessage={
				<>
				{window.location.pathname=="/mentors-dashboard"?'':
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>}
				</>
			}>
				<Box >
				<Grid container spacing={2}>
				{
		
				arr.map((e, index) => (
				<Grid item xs={12}  >
				<RenderCard user={e} scroll={"infinite"} />
				</Grid>
							
			))}
			</Grid>
			</Box>
		</InfiniteScroll>		
		)
	}
	{/*style={{'box-shadow': 'rgb(140 152 164 / 25%) 2px -3px 6px 0px',
		'border-bottom-left-radius':'0px','border-bottom-right-radius':'0px'}}
	*/}
			
    		
    const DashboardMentor = () =>
	{ 
		return(
		<>
		{
			this.state.mentors_list==0 && this.state.loading==false
			?	
				<Box
				sx={{
				  display: 'flex',
				  flexDirection: 'row'
				 
				}}
			    >
				
				<Box sx={{ flexGrow: 1 }}>
				 <Typography variant="subtitle1" color="textSecondary" > 
				Start your mentorship journey with Opengrowth
				</Typography>
				</Box>
				
				<Box>
				    <Button
					size="small"  
					color="primary" 
					onClick={()=>{this.props.history.push("/mentors-and-experts")}} 
					>
					Explore Mentors
					</Button>
				</Box>
				</Box>
				
			:
				<>
			
				<MinProfile />
				<Box  m={0}
				sx={{
				display: 'flex',
				justifyContent: 'flex-end'}}>
					<Button
					size="small"  
					color="primary" 
					onClick={()=>{this.props.history.push("/my-mentors")}} 
					>
					View Experts
					</Button>
				</Box>
				</>
			}
		</>	
		)
	}
	const CustomTitle=({name,closebtn})=>{
		return(
			<Box display="flex" justifyContent="space-between">
					<Box lineHeight={1.2} fontSize="20px" fontWeight="600">{name}</Box>
				<Box padding={0}  display={closebtn} >
					<IconButton style={{backgroundColor:'rgb(187 174 174 / 12%)',padding:'8px',marginBottom:'8px'}}>
					<CloseIcon color="grey"  style={{fontSize:"0.85rem"}} />
					</IconButton>
				</Box>
			</Box>
			
			)
	}	
	return ( 
		
	
		<Box>
		
		{
			this.state.msg!=false
			? <AlertBox show_status={true} msg={this.state.msg} msg_type="success" />
			:'',
			this.state.alert_msg!=false
			? <AlertBox show_status={true} msg={this.state.alert_msg} msg_type="success" />
			:''
		}
		
		
		{
			["/dashboard","/dashboard-mentorship"].includes(window.location.pathname)
			?
				<Card>
				<CardHeader title={<CustomTitle closebtn="none" name="My Experts" />} />
				<CardContent>
				{
					this.state.loading==true
					?<Box marginBottom={1}><ViewMentors_Skeleton/></Box>
					:<DashboardMentor />
				}
				</CardContent>
				</Card>
				
			:
			
				<Grid container direction="row" spacing={3}>
		 
							<Grid item  xs={12} sm={8}>
								<Card>
								<CardHeader title={"My Experts"} />
								<CardContent>
								{	
									this.state.loading==true
									?<Box marginBottom={1}><ViewMentors_Skeleton/></Box>
									:<MentorsProfile />
								}
								</CardContent>
								</Card>
							</Grid>
							
							<Grid item  xs={12} sm={4}>
								<Suspense fallback={<div> <Account_Skeleton/></div>}>
								<MentorsForDashboard action="connect" />
								</Suspense>
							</Grid>
				</Grid>			
		}
	</Box>
				
	);
  }
}


export default (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
	return (
        <Scroll  
		isMen={props.isMen}
		classes={classes} 
		location={location}  
		history={history} />
    )
}
